import React from 'react';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'
import ConfigConstants from "assets/Constants/config-constant";

const MaterialReturnToStore = React.forwardRef(({ invoiceContent }, ref) => {


    function formatDDMMYYYY(dateStr) {
        if (!dateStr) { return }
        const [year, month, day] = dateStr.split("-");
        return `${day}-${month}-${year}`;
    }



    const data = invoiceContent.items
    const invoiceFooter = (
        <div className="invoice-footer">
            <div className='row'>

            </div>
            <div className='row mt-4'>
                <div className='row mt-0'>
                    <div className='col text-center'>
                        <dt className="erp-invoice-print-label-md-lg"></dt>
                    </div>

                    <div className='col text-center'>
                        <dt className="erp-invoice-print-label-md-lg ps-4"></dt>
                    </div>

                </div>
            </div>

            <div className="row mt-0">
                <div className="col text-center me-2 d-flex flex-column align-items-center">
                    <dl className="mb-0">
                        <dd className="erp-invoice-print-label-md-lg" style={{ fontSize: '9px', marginBottom: '2px' }}>
                            {invoiceContent.header.return_by_name}
                        </dd>
                        <dt className="erp-invoice-print-label-md-lg">Returned By</dt>
                    </dl>
                </div>

                <div className="col text-center d-flex flex-column align-items-center">
                    <dl className="mb-0">
                        <dd className="erp-invoice-print-label-md-lg" style={{ fontSize: '9px', marginBottom: '2px' }}>
                            {invoiceContent.header.received_by}
                        </dd>
                        <dt className="erp-invoice-print-label-md-lg">Received By</dt>
                    </dl>
                </div>
            </div>


        </div>
    );

    return (
        <>

            <div className="row">

                <div className="col-12" >
                    <div className="container-invoice">
                        <div id="content">
                            <div className="invoice p-0">

                                {/* <!--BILL HEADER--> */}
                                <div className="row" style={{ padding: '0px 15px 0px' }}>
                                    <div className='bordered border-bottom-0 px-0'>
                                        <div className='row mb-0 p-1'>
                                            <div className="col-sm-2">
                                                <img src={DakshabhiLogo} alt="master card" width="170" height="auto" mt={1} />
                                            </div>
                                            <div className="col-sm-10">
                                                <div className='row'>
                                                    <div className="col-sm-9">

                                                        <div className='erp-invoice-print-label text-center'>
                                                            <span className='erp-invoice-print-label-lg'>{invoiceContent.company.company}</span><br />
                                                        </div>

                                                        <div className='erp-invoice-print-label-lg text-center'>
                                                            Material Return To Store ({invoiceContent.header.material_type === "UU" ? "Unused" : "Used/Replace"})
                                                        </div>

                                                    </div>
                                                    <div className="col-sm-3">
                                                        <span className='erp-invoice-print-label-md ps-4'>Stores Copy</span><br />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row border-top border-dark p-0 m-0'>
                                            <div className='col-sm-6'>
                                                <dt className='erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}> Material Return No : {invoiceContent.materialDetails.issue_return_no}</dt>
                                            </div>

                                            <div className='col-sm-6'>
                                                <dt className='erp-invoice-print-label-md-lg text-end' style={{ fontSize: '10px' }}> Return Date : {formatDDMMYYYY(invoiceContent.materialDetails.issue_return_date)}</dt>
                                            </div>
                                        </div>
                                        <div className='row border-top border-dark p-0 m-0'>
                                            <div className="row p-0 m-0">
                                                <div className="col-sm-12">
                                                    <dl className="row mb-0 pb-0">
                                                        <dl className="col-sm-6 mb-0">
                                                            <dl className="row mb-0 pb-0">
                                                                <dt className='col-sm-4  erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Material Type : </dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}> {invoiceContent.materialDetails.product_type_name}</dd>

                                                                <dt className='col-sm-4 erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Category 1 : </dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}> {invoiceContent.header.product_category1}</dd>

                                                                {/* <dt className='col-sm-4 erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Category 2 : </dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}> {invoiceContent.header.product_category2}</dd> */}

                                                                <dt className='col-sm-4  erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Issue No. : </dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>{invoiceContent.header.issue_no}</dd>

                                                                <dt className='col-sm-4  erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Issue Date : </dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>{formatDDMMYYYY(invoiceContent.header.issue_date)}</dd>

                                                            </dl>
                                                        </dl>
                                                        <dl className="col-sm-6 mb-0">
                                                            <dl className="row mb-0 pb-0">


                                                                <dt className='col-sm-4  erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Department : </dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>{invoiceContent.header.department_name}</dd>

                                                                <dt className='col-sm-4  erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Sub-Department : </dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>{invoiceContent.header.sub_department_name}</dd>

                                                                <dt className='col-sm-4  erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Received Date : </dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>{formatDDMMYYYY(invoiceContent.header.received_date)}</dd>

                                                                <dt className='col-sm-4  erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Remark : </dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>{invoiceContent.header.return_remark}</dd>

                                                            </dl>
                                                        </dl>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!--PARCEL DETAILS--> */}
                                <div className="row" style={{ padding: '0px 15px 15px' }}>
                                    <div className="col-12 ">
                                        <div className="row">
                                            <div className="col-12 px-0" >
                                                <div className="row p-0">
                                                    <div className="table">
                                                        <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='invoiceTable' >
                                                            <thead className='erp_table_border'>
                                                                <tr>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2} >Sr No.</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2} >Material Code</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Material Name</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Return Qty</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Received Qty</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Material Unit</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Godown (Location)</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Material Rate</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Return Status</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Remark</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id='detailstableId'>
                                                                {
                                                                    data.map((item, index) =>
                                                                        <>
                                                                            <tr className={`tblBodyTrHt-${index}`}>

                                                                                <td className="erp_invoice_table_td text-center">{item.sr_no}</td>
                                                                                <td className="erp_invoice_table_td text-center">{item.product_material_id}</td>
                                                                                <td className="erp_invoice_table_td" style={{ width: '40%' }}>{item.material_name}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.product_material_issue_return_quantity}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.product_material_receipt_quantity}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.product_unit_id}</td>
                                                                                <td className="erp_invoice_table_td">{item.godown_section_beans_name}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.rate}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.issue_return_item_status === "P" ? "Pending" : "Returned"}</td>
                                                                                <td className="erp_invoice_table_td">{item.issue_return_remark}</td>
                                                                            </tr>

                                                                        </>
                                                                    )
                                                                }
                                                                <tr>
                                                                    <td colSpan={12}>
                                                                        {invoiceFooter}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >

            <div className="row">

                <div className="col-12" >
                    <div className="container-invoice">
                        <div id="content">
                            <div className="invoice p-0">

                                {/* <!--BILL HEADER--> */}
                                <div className="row" style={{ padding: '0px 15px 0px' }}>
                                    <div className='bordered border-bottom-0 px-0'>
                                        <div className='row mb-0 p-1'>
                                            <div className="col-sm-2">
                                                <img src={DakshabhiLogo} alt="master card" width="170" height="auto" mt={1} />
                                            </div>
                                            <div className="col-sm-10">
                                                <div className='row'>
                                                    <div className="col-sm-9">

                                                        <div className='erp-invoice-print-label text-center'>
                                                            <span className='erp-invoice-print-label-lg'>{invoiceContent.company.company}</span><br />
                                                        </div>

                                                        <div className='erp-invoice-print-label-lg text-center'>
                                                            Material Return To Store ({invoiceContent.header.material_type === "UU" ? "Unused" : "Used/Replace"})
                                                        </div>

                                                    </div>
                                                    <div className="col-sm-3">
                                                        <span className='erp-invoice-print-label-md ps-4'>Department Copy</span><br />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row border-top border-dark p-0 m-0'>
                                            <div className='col-sm-6'>
                                                <dt className='erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}> Material Return No : {invoiceContent.materialDetails.issue_return_no}</dt>
                                            </div>

                                            <div className='col-sm-6'>
                                                <dt className='erp-invoice-print-label-md-lg text-end' style={{ fontSize: '10px' }}> Return Date : {formatDDMMYYYY(invoiceContent.materialDetails.issue_return_date)}</dt>
                                            </div>
                                        </div>
                                        <div className='row border-top border-dark p-0 m-0'>
                                            <div className="row p-0 m-0">
                                                <div className="col-sm-12">
                                                    <dl className="row mb-0 pb-0">
                                                        <dl className="col-sm-6 mb-0">
                                                            <dl className="row mb-0 pb-0">
                                                                <dt className='col-sm-4  erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Material Type : </dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}> {invoiceContent.materialDetails.product_type_name}</dd>

                                                                <dt className='col-sm-4 erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Category 1 : </dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}> {invoiceContent.header.product_category1}</dd>

                                                                {/* <dt className='col-sm-4 erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Category 2 : </dt>
                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}> {invoiceContent.header.product_category2}</dd> */}

                                                                <dt className='col-sm-4  erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Issue No. : </dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>{invoiceContent.header.issue_no}</dd>

                                                                <dt className='col-sm-4  erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Issue Date : </dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>{formatDDMMYYYY(invoiceContent.header.issue_date)}</dd>

                                                            </dl>
                                                        </dl>
                                                        <dl className="col-sm-6 mb-0">
                                                            <dl className="row mb-0 pb-0">


                                                                <dt className='col-sm-4  erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Department : </dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>{invoiceContent.header.department_name}</dd>

                                                                <dt className='col-sm-4  erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Sub-Department : </dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>{invoiceContent.header.sub_department_name}</dd>

                                                                <dt className='col-sm-4  erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Received Date : </dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>{formatDDMMYYYY(invoiceContent.header.received_date)}</dd>

                                                                <dt className='col-sm-4  erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Remark : </dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>{invoiceContent.header.return_remark}</dd>

                                                            </dl>
                                                        </dl>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!--PARCEL DETAILS--> */}
                                <div className="row" style={{ padding: '0px 15px 15px' }}>
                                    <div className="col-12 ">
                                        <div className="row">
                                            <div className="col-12 px-0" >
                                                <div className="row p-0">
                                                    <div className="table">
                                                        <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='invoiceTable' >
                                                            <thead className='erp_table_border'>
                                                                <tr>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2} >Sr No.</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2} >Material Code</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Material Name</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Return Qty</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Received Qty</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Material Unit</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Godown (Location)</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Material Rate</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Return Status</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Remark</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id='detailstableId'>
                                                                {
                                                                    data.map((item, index) =>
                                                                        <>
                                                                            <tr className={`tblBodyTrHt-${index}`}>
                                                                                <td className="erp_invoice_table_td text-center">{item.sr_no}</td>
                                                                                <td className="erp_invoice_table_td text-center">{item.product_material_id}</td>
                                                                                <td className="erp_invoice_table_td" style={{ width: '40%' }}>{item.material_name}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.product_material_issue_return_quantity}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.product_material_receipt_quantity}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.product_unit_id}</td>
                                                                                <td className="erp_invoice_table_td">{item.godown_section_beans_name}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.rate}</td>
                                                                                <td className="erp_invoice_table_td">{item.issue_return_item_status === "P" ? "Pending" : "Returned"}</td>
                                                                                <td className="erp_invoice_table_td">{item.issue_return_remark}</td>
                                                                            </tr>

                                                                        </>
                                                                    )
                                                                }
                                                                <tr>
                                                                    <td colSpan={12}>
                                                                        {invoiceFooter}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >


            <style>
                {`
                .erp-invoice-print-label-md-lg,
.erp_invoice_table_td,
.erp-invoice-print-label {
    line-height: 1.5;  
}
                `}
            </style>
        </>
    );
})


export default MaterialReturnToStore;

