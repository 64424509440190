import React from 'react'
import { useState, useEffect, useRef } from "react";
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import { useLocation, useNavigate } from "react-router-dom";

// Imports React bootstrap
import Form from 'react-bootstrap/Form';

// Imports React bootstrap
import Modal from 'react-bootstrap/Modal';

// File Imports
import ComboBox from "Features/ComboBox";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import FrmValidations from "FrmGeneric/FrmValidations";
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import DepartmentEntry from 'Masters/MDepartment/FrmDepartmentEntry';
import ConfigConstants from "assets/Constants/config-constant";
import FrmProductionSectionEntry from 'Masters/MProductionSection/FrmProductionSectionEntry';
import FrmProductionSubSectionEntry from 'Masters/MProductionSubSection/FrmProductionSubSectionEntry';


function MFrmProductionStoppageReasonsEntry() {
  const configConstants = ConfigConstants();
  const { COMPANY_ID, COMPANY_BRANCH_ID, UserName } = configConstants;

  const { state } = useLocation();
  const { production_stoppage_reasonsId = 0, keyForViewUpdate, compType = 'Masters' } = state || {}
  var activeValue = '';

  // For navigate
  const navigate = useNavigate();
  const comboDataAPiCall = useRef();
  const validate = useRef();

  //MODEL
  const [showAddRecModal, setShowAddRecModal] = useState(false);
  const [modalHeaderName, setHeaderName] = useState('')

  const [Chk_production_stoppage_reasons_type, setChk_production_stoppage_reasons_type] = useState('Both');
  const [txt_production_stoppage_reasons_name, settxt_production_stoppage_reasons_name] = useState('');
  const [txt_std_stoppage_loss_per_hour, settxt_std_stoppage_loss_per_hour] = useState('');
  const [txt_std_stoppage_minutes, settxt_std_stoppage_minutes] = useState('');
  const [cmb_production_department_Id, setcmb_production_department_Id] = useState('');
  const [cmb_section_id, setProdSection] = useState();
  const [cmb_sub_section_id, setProdSubSection] = useState();
  const [cmb_loss_type, setcmb_loss_type] = useState();


  ///Combo Boxes
  const [productiondepartmentOption, setproductiondepartmentOption] = useState([]);
  const [prodsectionOptions, setProdSectionOptions] = useState([]);
  const [prodsubsectionOptions, setProdSubSectionOptions] = useState([]);
  // const [productionSubdepartmentOption, setproductionSubdepartmentOption] = useState([]);
  // const [cmb_production_sub_department_id, setcmb_production_sub_department_id] = useState('')

  const [stoppageReasonTypeOpt, setStoppageReasonTypeOpt] = useState([])
  const [cmb_stoppage_reason_type, setStoppageReasonType] = useState('');


  const [actionType, setActionType] = useState('')

  const ActionType = async () => {
    switch (keyForViewUpdate) {
      case 'update':
        setActionType('(Modification)');

        break;
      case 'view':
        setActionType('(View)');

        break;
      default:
        setActionType('(Creation)');
        break;
    }

  };

  // Error Msg HANDLING
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const validateNumberDateInput = useRef();

  // Success Msg HANDLING
  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
    if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
      navigate(`/Masters/MProductionStoppageReasons/MFrmProductionStoppageReasonsListing`);
    }
  }


  // Show ADd record Modal
  const handleCloseRecModal = async () => {
    switch (modalHeaderName) {

      case 'Department':
        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.table = "cmv_department"
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        globalQuery.conditions.push({ field: "department_group", operator: "=", value: "Production" });
        const getmaxcountproductionParentProcessApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
        setproductiondepartmentOption(getmaxcountproductionParentProcessApiCall);
        break;

      // case 'Sub Department':
      //   var departmentIdVal = document.getElementById('cmb_production_department_Id').value;
      //   resetGlobalQuery();
      //   globalQuery.columns.push("field_id");
      //   globalQuery.columns.push("field_name");
      //   globalQuery.table = "cmv_department"
      //   globalQuery.conditions.push({ field: "parent_department_id", operator: "=", value: departmentIdVal });
      //   globalQuery.conditions.push({ field: "department_group", operator: "=", value: "Production" });
      //   globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      //   globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      //   const subProductionIdApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
      //   setproductionSubdepartmentOption(subProductionIdApiCall)
      //   break;

      default:
        break;
    }
    setShowAddRecModal(false);
    sessionStorage.removeItem('dataAddedByCombo')
    setTimeout(() => {
      $(".erp_top_Form").css({ "padding-top": "110px" });
    }, 200)

  }



  useEffect(() => {
    const functionCall = async () => {
      await ActionType();
      await fillComobos();
      if (production_stoppage_reasonsId !== 0) {
        await FnCheckUpdateResponce();
      }
    }
    functionCall()
  }, [])



  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');


  const FnCheckUpdateResponce = async () => {
    debugger
    try {

      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XmProductionStoppageReasons/FnShowParticularRecordForUpdate/${production_stoppage_reasonsId}/${COMPANY_ID}`)
      const updateRes = await apiCall.json();

      let resp = (updateRes.data)
      setcmb_production_department_Id(resp.production_department_id);
      await comboOnChange('departmentId')
      // setcmb_production_sub_department_id(resp.production_sub_department_id);
      setProdSection(resp.section_id);
      await comboOnChange('cmb_section_id');
      setProdSubSection(resp.sub_section_id);
      setChk_production_stoppage_reasons_type(resp.production_stoppage_reasons_type);
      settxt_production_stoppage_reasons_name(resp.production_stoppage_reasons_name);
      settxt_std_stoppage_loss_per_hour(resp.std_stoppage_loss_per_hour);
      settxt_std_stoppage_minutes(resp.std_stoppage_minutes);
      setStoppageReasonType(resp.loss_type);

      $('#saveBtn').show();

      switch (resp.is_active) {
        case true:
          document.querySelector('input[name="isactive"][value="1"]').checked = true;
          break;
        case false:
          document.querySelector('input[name="isactive"][value="0"]').checked = true;
          break;
        default: break;
      }
      // var keyForViewUpdate = sessionStorage.getItem('keyForViewUpdate');

      switch (keyForViewUpdate) {
        case 'update':
          $('#cmb_production_department_Id').attr('disabled', true);
          // $('#cmb_production_sub_department_id').attr('disabled', true);
          $('#txt_production_stoppage_reasons_name').attr('disabled', true);
          $('#saveBtn').attr('disabled', false);
          break;
        case 'view':
          $("input[type=radio]").attr('disabled', true);
          $('#saveBtn').attr('disabled', true);
          addReadOnlyAttr()
          break;
        default: break;
      }


    } catch (error) {
      console.log("error", error)
      navigate('/Error')
    }
  }

  const fillComobos = async () => {
    try {
      resetGlobalQuery();
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("field_name");
      globalQuery.table = "cmv_department"
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "department_group", operator: "=", value: "Production" });
      comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getmaxcountproductionParentProcessApiCall) => {
        setproductiondepartmentOption(getmaxcountproductionParentProcessApiCall);
      })


      ////Production section
      comboDataAPiCall.current.fillMasterData("xmv_production_section", "", "").then((getProdSectionApiCall) => {
        setProdSectionOptions(getProdSectionApiCall);
      })


      resetGlobalQuery();
      globalQuery.columns = ['property_id', 'property_name', 'property_master_id', 'property_group'];
      globalQuery.table = "am_properties"
      globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'MachineStoppageReasons' });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      let getStoppageReasonAPICall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
      setStoppageReasonTypeOpt(getStoppageReasonAPICall);


    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }

  const comboOnChange = async (key) => {
    debugger
    switch (key) {
      case 'departmentId':
        var departmentIdVal = document.getElementById('cmb_production_department_Id').value;
        setcmb_production_department_Id(departmentIdVal)
        if (departmentIdVal === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Department')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(1).css("padding-top", "0px");
          }, 200)
        }
        // if (departmentIdVal !== null || departmentIdVal !== "") {
        //   resetGlobalQuery();
        //   globalQuery.columns.push("field_id");
        //   globalQuery.columns.push("field_name");
        //   globalQuery.table = "cmv_department"
        //   globalQuery.conditions.push({ field: "parent_department_id", operator: "=", value: departmentIdVal });
        //   globalQuery.conditions.push({ field: "department_group", operator: "=", value: "Production" });
        //   globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        //   globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        //   const subProductionIdApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
        // setproductionSubdepartmentOption(subProductionIdApiCall)

        // setcmb_production_sub_department_id('');

        // }
        $('#error_cmb_production_sub_department_Id').hide();
        break;


      // case 'subdepartmentId':
      //   var subdepartmentIdVal = document.getElementById('cmb_production_sub_department_id').value;
      //   setcmb_production_sub_department_id(subdepartmentIdVal)
      //   if (subdepartmentIdVal === '0') {
      //     sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
      //     setHeaderName('Sub Department')
      //     setShowAddRecModal(true)
      //     setTimeout(() => {
      //       $(".erp_top_Form").eq(1).css("padding-top", "0px");
      //     }, 200)
      //   }
      //   break;

      case 'cmb_section_id':
        const prod_section_Id = $('#cmb_section_id').val();
        if (prod_section_Id === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Production Section')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(1).css("padding-top", "0px");

          }, 100)
        }
        const getProdSubSectionApiCall = await comboDataAPiCall.current.fillMasterData("xmv_production_sub_section", "production_section_id", prod_section_Id);
        setProdSubSectionOptions(getProdSubSectionApiCall);
        break;

      case 'cmb_sub_section_id':
        const prod_sub_section_Id = $('#cmb_sub_section_id').val();
        if (prod_sub_section_Id === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Production Sub Section')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(1).css("padding-top", "0px");

          }, 100)
        }
        break;

    }
  }


  const displayRecordComponent = () => {
    switch (modalHeaderName) {

      case 'Department':
        return <DepartmentEntry btn_disabled={true} />;

      // case 'Sub Department':
      //   return <DepartmentEntry department={cmb_production_department_Id} btn_disabled={true} />;

      case 'Production Section':
        return <FrmProductionSectionEntry btn_disabled={true} />;

      case 'Production Sub Section':
        return <FrmProductionSubSectionEntry btn_disabled={true} />

      default:
        return null;
    }
  }


  const handleSubmit = async () => {
    debugger
    try {
      const checkIsValidate = await validate.current.validateForm("ProductionProcessId");

      if (checkIsValidate === true) {
        // var production_stoppage_reasons_id = sessionStorage.getItem("production_stoppage_reasons_id");
        // if (production_stoppage_reasons_id === '' || production_stoppage_reasons_id === null) { production_stoppage_reasons_id = 0 }
        var active;

        activeValue = document.querySelector('input[name=isactive]:checked').value

        switch (activeValue) {
          case '1': active = true; break;
          case '0': active = false; break;
          default: break;
        }
        const data = {
          company_id: COMPANY_ID,

          company_branch_id: COMPANY_BRANCH_ID,
          production_stoppage_reasons_id: production_stoppage_reasonsId,
          created_by: UserName,
          modified_by: production_stoppage_reasonsId === 0 ? null : UserName,
          production_department_id: cmb_production_department_Id,
          production_stoppage_reasons_name: txt_production_stoppage_reasons_name,
          production_sub_department_id: 1,
          section_id: cmb_section_id,
          sub_section_id: cmb_sub_section_id,
          production_stoppage_reasons_type: cmb_stoppage_reason_type,
          std_stoppage_loss_per_hour: txt_std_stoppage_loss_per_hour,
          std_stoppage_minutes: txt_std_stoppage_minutes,
          is_active: active,
          loss_type: Chk_production_stoppage_reasons_type
        };
        console.log(data);
        const requestOptions = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        };
        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XmProductionStoppageReasons/FnAddUpdateRecord`, requestOptions)
        const responce = await apicall.json()
        console.log("response error: ", responce);
        if (responce.success === '0') {
          console.log("response error: ", responce.error);
          setErrMsg(responce.error)
          setShowErrorMsgModal(true)

        } else {
          const evitCache = comboDataAPiCall.current.evitCache();
          console.log(evitCache);
          sessionStorage.setItem("productionprocessId", responce.data.production_stoppage_reasons_id)
          setSuccMsg(responce.message)
          setShowSuccessMsgModal(true);
          clearFormFields();

        }

      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }

  };

  const clearFormFields = () => {
    settxt_production_stoppage_reasons_name('');
  }

  const validateFields = () => {
    validate.current.validateFieldsOnChange('ProductionProcessId')

    // var formObj = $('#ProductionProcessId');
    // var inputObj;

    // for (var i = 0; i <= formObj.get(0).length - 1; i++) {
    //   inputObj = formObj.get(0)[i];
    //   if (inputObj.type === 'text' && inputObj.value !== '') {
    //     $("#error_" + inputObj.id).hide();
    //   } else if (inputObj.type === 'select-one' && inputObj.value !== '') {
    //     $("#error_" + inputObj.id).hide();
    //   } else if (inputObj.type === 'textarea' && inputObj.value !== '') {
    //     $("#error_" + inputObj.id).hide();
    //   } else if (inputObj.type === 'date' && inputObj.value !== '') {
    //     $("#error_" + inputObj.id).hide();
    //   }

    // }
  }
  const addReadOnlyAttr = () => {
    var formObj = $('#ProductionProcessId');
    var inputObj;
    for (var i = 0; i <= formObj.get(0).length - 1; i++) {
      inputObj = formObj.get(0)[i];
      if (inputObj.type === 'text') {
        $('#' + inputObj.id).attr('readonly', true);
      } else if (inputObj.type === 'select-one') {
        $('#' + inputObj.id).attr('disabled', true);
      } else if (inputObj.type === 'file') {
        $('#' + inputObj.id).attr('disabled', true);
      } else if (inputObj.type === 'textarea') {
        $('#' + inputObj.id).attr('readonly', true);
      } else if (inputObj.type === 'date') {
        $('#' + inputObj.id).attr('readonly', true);
      } else if (inputObj.type === 'email') {
        $('#' + inputObj.id).attr('readonly', true);
      } else if (inputObj.type === 'number') {
        $('#' + inputObj.id).attr('readonly', true);
      }


    }
  }

  return (
    <>
      <FrmValidations ref={validate} />
      <ComboBox ref={comboDataAPiCall} />
      <ValidateNumberDateInput ref={validateNumberDateInput} />
      <div className='erp_top_Form'>
        <div className='card p-1'>

          <div className='card-header text-center py-0'>
            <label className='erp-form-label-lg main_heding'>Production Stoppage Reasons {actionType}  </label>
          </div>
          <form id="ProductionProcessId">
            <div className="row erp_transporter_div">

              {/* first row */}
              <div className="col-sm-6 erp_filter_group-by-result">
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Production Department <span className="required">*</span></Form.Label>
                  </div>
                  <div className="col">
                    <select id="cmb_production_department_Id" className="form-select form-select-sm" value={cmb_production_department_Id} onChange={(e) => { setcmb_production_department_Id(e.target.value); comboOnChange('departmentId'); validateFields(); }}  >
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {productiondepartmentOption?.map(DepartmentOption => (
                        <option value={DepartmentOption.field_id}>{DepartmentOption.field_name}</option>
                      ))}

                    </select>
                    <MDTypography variant="button" id="error_cmb_production_department_Id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>



                {/* <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Production Sub Department<span className="required">*</span></Form.Label>
                  </div>
                  <div className="col">
                    <select id="cmb_production_sub_department_id" className="form-select form-select-sm" value={cmb_production_sub_department_id} onChange={(e) => { setcmb_production_sub_department_id(e.target.value); comboOnChange('subdepartmentId'); validateFields(); }}  >
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {productionSubdepartmentOption?.map(SubDepartment => (
                        <option value={SubDepartment.field_id}>{SubDepartment.field_name}</option>
                      ))}
                    </select>
                    <MDTypography variant="button" id="error_cmb_production_sub_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div> */}

                <div className='row'>
                  <div className='col-sm-4'>
                    <Form.Label className="erp-form-label">Production Section <span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col">
                    <select id="cmb_section_id" className="form-select form-select-sm" value={cmb_section_id} onChange={(e) => { comboOnChange('cmb_section_id'); setProdSection(e.target.value); }}>
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {prodsectionOptions.length !== 0 ? (
                        <>
                          {prodsectionOptions?.map(production => (
                            <option value={production.field_id}>{production.field_name}</option>
                          ))}
                        </>
                      ) : null

                      }
                    </select>
                    <MDTypography variant="button" id="error_cmb_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-4'>
                    <Form.Label className="erp-form-label">Prod. Sub Section <span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col">
                    <select id="cmb_sub_section_id" className="form-select form-select-sm" value={cmb_sub_section_id} onChange={(e) => { comboOnChange('cmb_sub_section_id'); setProdSubSection(e.target.value); }}>
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {prodsubsectionOptions.length !== 0 ? (
                        <>
                          {prodsubsectionOptions?.map(subproduction => (
                            <option value={subproduction.field_id}>{subproduction.field_name}</option>
                          ))}
                        </>
                      ) : null

                      }
                    </select>
                    <MDTypography variant="button" id="error_cmb_sub_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>


                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">  Type<span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col">
                    <div className="erp_form_radio">
                      <div className="sCheck"> <Form.Check className="erp_radio_button" label="Preventive " type="radio" lbl="Preventive" value="Preventive" name="Chk_production_stoppage_reasons_type" checked={Chk_production_stoppage_reasons_type === "Preventive"} onClick={() => setChk_production_stoppage_reasons_type("Preventive")} /> </div>
                      <div className="sCheck"> <Form.Check className="erp_radio_button" label="Breakdown" type="radio" lbl="Breakdown" value="Breakdown" name="Chk_production_stoppage_reasons_type" checked={Chk_production_stoppage_reasons_type === "Breakdown"} onClick={() => setChk_production_stoppage_reasons_type("Breakdown")} /> </div>
                      <div className="fCheck ms-2"> <Form.Check className="erp_radio_button" label="Cleaning" type="radio" lbl="Cleaning" value="Cleaning" name="Chk_production_stoppage_reasons_type" checked={Chk_production_stoppage_reasons_type === "Cleaning"} onClick={() => setChk_production_stoppage_reasons_type("Cleaning")} /> </div>

                    </div>

                  </div>

                </div>


                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Loss Type<span className="required">*</span></Form.Label>
                  </div>
                  <div className="col">

                    <div className='col'>
                      <Form.Control type="text" id="txt_production_stoppage_reasons_name" className="erp_input_field" value={txt_production_stoppage_reasons_name} onChange={e => { settxt_production_stoppage_reasons_name(e.target.value); validateFields(); }} maxLength="500" />
                      <MDTypography variant="button" id="error_txt_production_stoppage_reasons_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>

                  </div>
                </div>
              </div>

              {/* second row */}

              <div className="col-sm-6 erp_filter_group-by-result">
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Std Stoppage Loss Per Hour</Form.Label>
                  </div>
                  <div className="col">

                    <div className='col'>
                      <Form.Control type="text" id="txt_std_stoppage_loss_per_hour" className="erp_input_field text-end" value={txt_std_stoppage_loss_per_hour} onChange={e => { settxt_std_stoppage_loss_per_hour(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); validateFields(); }} maxLength="10 " optional='optional' />
                      <MDTypography variant="button" id="error_txt_std_stoppage_loss_per_hour" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>

                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Std Stoppage Minutes </Form.Label>
                  </div>
                  <div className="col">

                    <div className='col'>
                      <Form.Control type="text" id="txt_std_stoppage_minutes" className="erp_input_field text-end" value={txt_std_stoppage_minutes} onChange={e => { settxt_std_stoppage_minutes(validateNumberDateInput.current.decimalNumber(e.target.value, 2)); validateFields(); }} maxLength="5" optional='optional' />
                      <MDTypography variant="button" id="error_txt_std_stoppage_minutes" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>

                  </div>
                </div>


                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Is Active</Form.Label>
                  </div>
                  <div className="col">
                    <div className="erp_form_radio">
                      <div className="fCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="Yes"
                          type="radio"
                          value="1"
                          name="isactive"
                          defaultChecked
                        />
                      </div>
                      <div className="sCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="No"
                          value="0"
                          type="radio"
                          name="isactive"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Reason Type</Form.Label>
                  </div>
                  <div className="col">
                    <select id="cmb_stoppage_reason_type" className="form-select form-select-sm"  value={cmb_stoppage_reason_type} onChange={(e) => { setStoppageReasonType(e.target.value);  validateFields(); }} >
                      <option value="">Select</option>
                      {/* <option value="0">Add New Record+</option> */}
                      {stoppageReasonTypeOpt.length !== 0 ? (
                        <>
                          {stoppageReasonTypeOpt.map(dispatchType => (
                            <option value={dispatchType.property_name} > {dispatchType.property_name} </option>

                            // <option key={scheduleType.field_id} value={scheduleType.field_id} shortname={scheduleType.product_type_short_name} > {scheduleType.field_name} </option>
                          ))}
                        </>
                      ) : null}

                    </select>
                    <MDTypography variant="button" id="error_cmb_stoppage_reason_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                    {/* <select id="cmb_loss_type" className="form-select form-select-sm" value={cmb_loss_type} onChange={(e) => { setcmb_loss_type(e.target.value); validateFields(); }}  >
                      <option value="">Select</option>
                      <option value="Utilization">Utilization</option>
                      <option value="Efficiency">Efficiency</option>
                      <option value="Others">Others</option>
                    </select>
                    <MDTypography variant="button" id="error_cmb_loss_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography> */}
                  </div>
                </div>

              </div>

            </div>

          </form>
          <div className="card-footer py-0 text-center">
            <MDButton type="button" className="erp-gb-button"

              onClick={() => {
                const path = compType === 'Register' ? '/Masters/MProductionStoppageReasons/MFrmProductionStoppageReasonsListing/reg' : '/Masters/MProductionStoppageReasons/MFrmProductionStoppageReasonsListing';
                navigate(path);
              }}

              variant="button"
              fontWeight="regular">Back</MDButton>

            {keyForViewUpdate !== 'view' ? (
              <MDButton type="submit" onClick={handleSubmit} id="saveBtn" className="erp-gb-button erp_MLeft_btn " variant="button"
                fontWeight="regular">save</MDButton>
            ) : null}

          </div>

        </div>

        {/* Success Msg Popup */}
        <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
        {/* Error Msg Popup */}
        <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

        {/* 
        <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
          <Modal.Header>
            <Modal.Title className='erp_modal_title'>{modalHeaderName}</Modal.Title>
            <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></span>
          </Modal.Header>
          <Modal.Body className='erp_city_modal_body'>
            {displayRecordComponent()}
          </Modal.Body>
          <Modal.Footer>
            <MDButton type="button" onClick={handleCloseRecModal} className="btn erp-gb-button" variant="button"
              fontWeight="regular">Close</MDButton>

          </Modal.Footer>
        </Modal > */}


        {/* Add new Record Popup */}
        <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >

          <Modal.Body className='erp_city_modal_body'>
            <div className='row'>
              <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></div>
            </div>
            {displayRecordComponent()}
          </Modal.Body>

        </Modal >



      </div>

    </>
  )
}

export default MFrmProductionStoppageReasonsEntry




