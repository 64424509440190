import React, { useState, useReducer } from 'react'
import { useEffect, useRef, useMemo } from 'react';
import { RxCheckCircled } from "react-icons/rx";

import { useLocation, useNavigate } from "react-router-dom";
import ReactToPrint, { useReactToPrint } from 'react-to-print';


import { Modal, Spinner, Table } from "react-bootstrap";
import { Button } from "react-bootstrap"
import { FiPrinter } from "react-icons/fi";
import Select from 'react-select';
import ReactSelect, { components } from "react-select";
import { Tooltip } from "@material-ui/core";

import { MdDelete, MdRefresh } from "react-icons/md";
import { IoAddCircleOutline, IoRemoveCircleOutline } from 'react-icons/io5';

import { Form } from 'react-bootstrap';
import MDButton from "components/MDButton";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import $ from 'jquery';
import MDTypography from 'components/MDTypography';
import ComboBox from 'Features/ComboBox';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import FrmValidations from 'FrmGeneric/FrmValidations';
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import { CircularProgress } from "@material-ui/core";
import WarpingOrderPlan from '../../../FrmGeneric/Invoice/WarpingOrderPlan';
import GenerateMaterialId from 'FrmGeneric/GenerateMaterialId/GenerateMaterialId';
import SearchItemFilter from "FrmGeneric/SearchItemFilter";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";

// React Icons
import { FaMoneyBillTransfer } from "react-icons/fa6";
import DirectWarpingPlan from '../../../FrmGeneric/Invoice/DirectWarpingPlan';
import { RxCrossCircled } from 'react-icons/rx';



function FrmWarpingProdPlanEntry() {

    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const configConstants = ConfigConstants();
    // debugger

    const { COMPANY_ID, COMPANY_NAME, COMPANY_BRANCH_ID, SHORT_COMPANY, UserName, UserId, FINANCIAL_SHORT_NAME } = configConstants;
    const { state } = useLocation();
    const { idList = 0, keyForViewUpdate } = state || {}


    ///Hooks for data
    const [cmb_product_type_id, setProduct_type_id] = useState(0);
    const [warping_production_order_id, setWarpingProdOrderId] = useState(idList)
    const [txt_sort_no, setMaterialSortNo] = useState('');
    const [txt_warping_order_no, setWarpingOrderNo] = useState();
    const [cmb_product_material_id, setProductMaterialId] = useState(parseInt(cmb_product_type_id) === 9 ? '' : []);
    const [cmb_product_material_style, setProductMaterialStyle] = useState('');
    const [txt_product_material_name, setMaterialName] = useState('');
    const [productionSectionId, setProductionSectionId] = useState();
    const [txt_schedule_quantity, setScheduleQty] = useState();
    const [txt_prev_schedule_quantity, setPrevScheduleQty] = useState(0);
    const [dt_schedule_date, setScheduleDate] = useState(today);
    const [txt_set_length, setLength] = useState();
    const [txt_creels, setCreeels] = useState();
    const [txt_set_no, setSetNo] = useState(1001);
    const [cmb_customer_id, setCustomerId] = useState('');
    const cmb_customer_id_ref = useRef(null);
    const sizedYarnCustomerOrderRef = useRef(null);
    const sizedYarnMaterialRef = useRef(null);
    const [cmb_customer_order, setCustomerOrder] = useState(parseInt(cmb_product_type_id) === 9 ? '' : []);
    const [order_quantity, setOrderQuantity] = useState();
    const [dt_warping_plan_date, setWarpingPlanDate] = useState(today);
    const [dt_sales_order_date, setSalesOrderDate] = useState();
    const [txt_sales_order_no, setSalesOrderNo] = useState();
    const [bottomValue, setBottomValue] = useState();
    const [productionConstant, setProductionConstant] = useState();
    const [cmb_approved_by_id, setApprovedById] = useState(parseInt(UserId));
    console.log(parseInt(UserId));
    // const cmb_approved_by_id_ref = useRef();
    const [dt_approved_date, setApproveDate] = useState(today);
    const [cmb_warping_order_status, setWarpingOrderStatus] = useState(keyForViewUpdate === 'approve' ? 'A' : 'P');
    const [actionType, setActionType] = useState('')
    // const [txt_glm, setGLM] = useState();
    const [txt_t_ends, setTEnds] = useState();

    const [job_type, setJobType] = useState();

    const [productTypeOptions, setProductTypeOptions] = useState([]);
    let productTypeComboRef = useRef(null);
    let error_txt_set_no = useRef();



    ///Array Hooks
    const [setNoOptions, setSetNoOptions] = useState([]);
    const [salesOrderData, setSalesOrderData] = useState([]);
    const [HeaderStyleAbbData, setHeaderStyleAbbData] = useState({});
    const [headerData, setHeaderData] = useState([]);
    const [warpingData, setWarpingdata] = useState([]);
    const [individualStockDetails, setIndividualStockDetails] = useState([]);
    const [finalStockDetailsArr, setFinalStockDetailsArr] = useState([]);

    const [materialRequisitionDetails, setMaterialRequisitionDetails] = useState([]);
    // const [materialRequisitionAgainstOther, setMaterialRequisitionAgainstOther] = useState([]);
    const [creelData, setCreelData] = useState([]);
    const [actionLabel, setActionLabel] = useState('Save');
    const [prodCountOptions, setProductionCountOptons] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [customerOrderList, setCustomerOrderList] = useState([]);
    const [materialList, setMaterialList] = useState([]);
    const [materialStyleList, setMaterialStyleList] = useState([]);
    const [productBasedPropertiesData, setProductBasedPropertiesData] = useState([]);
    const [productBasedPropertiesColumns, setProductBasedPropertiesColumns] = useState([]);
    const [approveByOpts, setApproveByOpts] = useState([]);
    // const [material_constant, setMaterialConstant] = useState();
    const [indentIssueDetails, setIndentIssueDetails] = useState([]);

    const [actualProductionCounts, setActualProductionCounts] = useState([]);


    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    // Success Msg HANDLING
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
            navigate(`/Masters/MWarpingProductionPlan/FrmWarpingProductionPlanListing`)
        }
    }

    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [showWRFilterForm, setShowWRFilterForm] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [warpingContentPDF, setWarpingContentPDF] = useState({});
    const [isPrinting, setIsPrinting] = useState(false);
    const printRef = useRef(null);
    const promiseResolveRef = useRef(null);
    const handleCloses = () => setShowModal(false);

    const handleShow = () => { setShowModal(true); };

    const FnCloseStockModal = async () => {
        setShowWRFilterForm(false)
        sessionStorage.removeItem('filteredMaterialData')
        sessionStorage.removeItem('requiredCols')
        // setMaterialRequisitionDetails([]);
        if (keyForViewUpdate === "add") {
            setMaterialRequisitionDetails([]);
        }
    }

    // const FnConsumeStockSingleQty = () => {
    //     try {
    //         const getConsumptionDetails = [...materialRequisitionDetails];


    //         // Filter records with warping_quantity > 0
    //         const filteredDetails = getConsumptionDetails.filter(item => item.warping_quantity > 0);
    //         setIndentIssueDetails(filteredDetails);
    //         // Extract unique cone_per_wt values from filtered records
    //         const uniqueConePerWt = new Set(filteredDetails.map(item => item.cone_per_wt));
    //         const uniqueBatchNO = new Set(filteredDetails.map(item => item.issue_batch_no));

    //         // Check for consistency in cone_per_wt values
    //         // if (uniqueConePerWt.size > 1 || uniqueBatchNO.size>1) {
    //         //     setErrMsg("Inconsistent Weight Per Cone values found.");
    //         //     setShowErrorMsgModal(true);
    //         //     return false;
    //         // }
    //         if (uniqueConePerWt.size > 1 || uniqueBatchNO.size > 1) {
    //             setErrMsg("Inconsistent Weight Per Cone or batch numbers found.");
    //             setShowErrorMsgModal(true);
    //             return false;
    //         }



    //         // Extract unique production_actual_count values from filtered records
    //         const uniqueActualCount = new Set(filteredDetails.map(item => item.production_actual_count));

    //         console.log('uniqueActualCount:', uniqueActualCount);

    //         // Check for consistency in production_actual_count values
    //         if (uniqueActualCount.size > 1) {
    //             setErrMsg("Inconsistent production actual count values found.");
    //             setShowErrorMsgModal(true);
    //             return false;
    //         }



    //         const totalWarpingQuantity = getConsumptionDetails.reduce((sum, item) => {
    //             return sum + parseFloat(item.warping_quantity || 0);
    //         }, 0);

    //         const totalNoOfCones = getConsumptionDetails.reduce((sum, item) => {
    //             return sum + parseFloat(item.no_of_cones || 0);
    //         }, 0);

    //         // Find the object where transfer_qty is greater than 0
    //         const relevantObject = getConsumptionDetails.find(item => parseFloat(item.warping_quantity) > 0);

    //         const { product_material_code, product_material_id, product_material_name, actual_count, cone_per_wt, product_material_unit_id,
    //             //  product_id, 
    //             production_actual_count } = relevantObject;
    //         const object = {
    //             product_material_code: product_material_code,
    //             product_material_id: product_material_id,
    //             product_material_name: product_material_name,
    //             production_count_name: actual_count,
    //             product_material_quantity: totalWarpingQuantity,
    //             no_of_cones: totalNoOfCones,
    //             cone_per_wt: cone_per_wt,
    //             product_material_unit_id: product_material_unit_id,
    //             // product_id: product_id,
    //             production_actual_count: actual_count
    //         };

    //         // Push object into warpingData state
    //         setWarpingdata([object]);
    //         setShowWRFilterForm(false)

    //         // Add crreel details function
    //         FnAddMaterialCountWiseCreels([object]);

    //     } catch (error) {
    //         console.log('error', error);
    //     }
    // }


    const FnConsumeStock = () => {
        // debugger
        try {
            if (keyForViewUpdate === 'Add') {
                const getConsumptionDetails = [...materialRequisitionDetails];

                // const totalWarpingQuantity = getConsumptionDetails.reduce((sum, item) => {
                //     return sum + parseFloat(item.warping_quantity || 0);
                // }, 0);

                // const totalNoOfCones = getConsumptionDetails.reduce((sum, item) => {
                //     return sum + parseFloat(item.no_of_cones || 0);
                // }, 0);

                // Find the objects where warping_quantity is greater than 0
                let warpingMaterials = getConsumptionDetails.filter(item => parseFloat(item.warping_quantity) > 0);
                let counts = [];

                // Transform the data
                warpingMaterials = warpingMaterials.map((relevantObject) => {
                    let emptyObj = {};

                    // Creating the transformed object
                    let transformedObject = {
                        product_material_code: relevantObject.product_material_code,
                        product_material_id: relevantObject.product_material_id,
                        product_material_name: relevantObject.product_material_name,
                        production_count_name: relevantObject.product_material_name,
                        product_material_quantity: relevantObject.warping_quantity,
                        no_of_cones: relevantObject.no_of_cones,
                        cone_per_wt: relevantObject.cone_per_wt,
                        product_material_unit_id: relevantObject.product_material_unit_id,
                        production_count: relevantObject.actual_count
                    };

                    // Assign additional properties to emptyObj if needed
                    emptyObj['product_material_name'] = relevantObject.product_material_name;
                    emptyObj['product_material_id'] = relevantObject.product_material_id;
                    emptyObj['actual_count'] = relevantObject.actual_count;
                    counts.push(emptyObj)

                    // Return the transformed object (emptyObj is unused in this case)
                    return transformedObject;
                });

                setProductionCountOptons(counts);
                setWarpingdata(warpingMaterials);
                // Add creel details function
                FnAddMaterialCountWiseCreels(warpingMaterials);

            }
            setShowWRFilterForm(false);

        } catch (error) {
            console.log('error', error);
        }
    };


    //useRef Hooks
    const comboDataAPiCall = useRef();
    const validateNumberDateInput = useRef();
    const validate = useRef();
    const generateAutoNoAPiCall = useRef();
    const navigate = useNavigate();

    const memoizedColumns = useMemo(() => productBasedPropertiesColumns, [productBasedPropertiesColumns]);
    const memoizedData = useMemo(() => productBasedPropertiesData, [productBasedPropertiesData]);


    useEffect(async () => {
        setIsLoading(true);
        await FnFillCombos();
        ActionType();
        removeSessions('onload');

        if (warping_production_order_id !== 0) {
            await FnCheckUpdateResponse();
        } else {
            FnGenerateWarpingPlanId()
            FngetSetNo()
        }
        // cmb_approved_by_id_ref?.current.value = UserId

        setIsLoading(false)
    }, [])

    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const removeSessions = (reloadData) => {
        sessionStorage.removeItem(`comboFilterData`)
        sessionStorage.removeItem('isComboFilterExist')
        sessionStorage.removeItem('smv_product_type');
        sessionStorage.removeItem('smv_product_material_type');
        sessionStorage.removeItem('smv_product_material_grade');
        sessionStorage.removeItem('smv_product_material_shape');
        if (reloadData === 'onload') {
            sessionStorage.removeItem('filteredMaterialData')
        }
        for (let removeItemIndex = 1; removeItemIndex <= 5; removeItemIndex++) {
            sessionStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex + 'Name');
        }
        localStorage.removeItem('smv_product_material_type');
        localStorage.removeItem('smv_product_material_grade');
        localStorage.removeItem('smv_product_material_shape');
        localStorage.removeItem('smv_product_type');
        localStorage.removeItem('smv_product_material_typeName');
        localStorage.removeItem('smv_product_material_gradeName');
        localStorage.removeItem('smv_product_material_shapeName');
        localStorage.removeItem('smv_product_typeName');
    }
    const creelsRef = useRef();

    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'update':
                // setIsApprove(false);
                setActionType('(Modification)');
                setActionLabel('Update')
                break;
            case 'view':
                // setIsApprove(false);
                setActionType('(View)');
                await validate.current.readOnly("warpingOrderFormId");
                $("input[type=radio]").attr('disabled', true);
                break;
            case 'approve':
                setActionType('(Approve)');
                setActionLabel('Approve')
                $("form").find("input,textarea,select").attr("disabled", "disabled");
                $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                $('table .approveField').removeAttr("disabled");
                $("input[type=radio]").attr('disabled', true);
                // setIsApprove(true)
                break;
            default:
                setActionType('(Creation)');
                break;
        }
    };


    /// function for generate warping production
    const FnGenerateWarpingPlanId = async (dt_schedule_date_on_change = dt_schedule_date) => {
        try {
            const [year, month, day] = dt_schedule_date_on_change.split('-');
            const formatted_date = `${day}${month}${year.slice(2)}`;
            const warpingProductionCode = await generateAutoNoAPiCall.current.generateProductionCode({
                "entity_name": "xt_warping_production_order",
                // "sub_section": 22,
                "sub_section_short_name": 'WVWP',
                "field_name": "warping_order_no",
                "trans_no_length": "4",
                "transaction_date": formatted_date,
                "company_id": COMPANY_ID
            });
            setWarpingOrderNo(warpingProductionCode);

        } catch (error) {
            console.log('error: ', error);
            navigate('/Error');
        }
    };

    // Generate the Set no
    const FngetSetNo = async () => {
        try {
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionOrder/GetLastRecordSetNoWeavingProductionOrder`)
            const response = await apiCall.json();
            if (response.success === 1) {
                const data = response.data

                if (data?.set_no) {
                    const setno = parseInt(data?.set_no);
                    setSetNo(setno + 1);
                }

            }

        } catch (error) {
            console.log("error: ", error)
        }
    }

    // printOut related changes  

    const firstPageRef = useRef();
    const secondPageRef = useRef();

    const [showPrintModal, setShowPrintModal] = useState(false);



    const handlePrintFirstPage = useReactToPrint({

        content: () => firstPageRef.current,
        onAfterPrint: () => {
            setShowPrintModal(true); // Show confirmation modal after first page print
        },
        documentTitle: 'Weaving Production Order Print'
    });

    const handlePrintSecondPage = useReactToPrint({

        content: () => secondPageRef.current,
        documentTitle: 'Direct Warping Order plan Print'
    });

    const handleClosePrintModal = () => setShowPrintModal(false);
    const handleConfirmPrintSecondPage = () => {
        // setShowModal(true);
        setShowPrintModal(false); // Close confirmation modal
        handlePrintSecondPage(); // Print the second page
    };

    const handleCancelPrintSecondPage = () => {
        setShowPrintModal(false); // Close confirmation modal
    };
    // const printInvoice = useReactToPrint({
    //     content: () => printRef.current,
    //     onBeforeGetContent: () => {
    //         return new Promise((resolve) => {
    //             promiseResolveRef.current = resolve;
    //             setIsPrinting(true);
    //         });
    //     },
    //     onAfterPrint: () => {
    //         promiseResolveRef.current = null;
    //         setIsPrinting(false);
    //         // buttonsRef.current.focus();
    //     },
    //     documentTitle: 'Weaving Production Order Print'
    // });

    const FnGetPreviousPlannedProduction = async (customer_order_no, product_material_style, product_material_id) => {
        resetGlobalQuery();
        globalQuery.columns = ['customer_order_no', 'schedule_quantity'];
        globalQuery.table = "xt_warping_production_order";
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        globalQuery.conditions.push({ field: "warping_order_status", operator: "=", value: 'A' });
        globalQuery.conditions.push({ field: "customer_order_no", operator: "=", value: customer_order_no });
        if (parseInt(cmb_product_type_id) !== 13) {
            globalQuery.conditions.push({ field: "product_material_style", operator: "=", value: product_material_style });
        }
        globalQuery.conditions.push({ field: "product_material_id", operator: "=", value: product_material_id });
        let getPreviousCustomerOrderData = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
        if (getPreviousCustomerOrderData.length !== 0) {
            let totalScheduledQty = getPreviousCustomerOrderData.reduce((acc, set_details) => {
                return acc + set_details['schedule_quantity'];
            }, 0);
            setPrevScheduleQty(totalScheduledQty)
        }
    }


    const FnCheckUpdateResponse = async () => {
        // debugger
        try {
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWarpingProductionOrder/FnShowParticularRecordForUpdate/${idList}/${COMPANY_ID}`)
            const updateRes = await apiCall.json();

            const WarpingTransData = updateRes.warpingProductionOrderRecord;
            const WarpingDetailsData = updateRes.warpingProductionOrderDetailsRecord;
            const WarpingStockDetails = updateRes.warpingProductionOrderStockDetailsRecord;
            const WarpingCreelDetails = updateRes.warpingProductionOrderCreelsRecords;
            setIndentIssueDetails(WarpingStockDetails);
            setProduct_type_id(WarpingTransData.production_order_type_id)
            productTypeComboRef.current.value = parseInt(WarpingTransData.production_order_type_id);
            setWarpingProdOrderId(WarpingTransData.warping_production_order_id)
            setSetNo(WarpingTransData.set_no);
            setWarpingOrderNo(WarpingTransData.warping_order_no);
            setBottomValue(WarpingTransData.bottom_value);
            setMaterialSortNo(WarpingTransData.sort_no);
            setTEnds(WarpingTransData.t_ends);
            // setProductionSectionId(WarpingTransData.production_section_id);
            setScheduleQty(WarpingTransData.schedule_quantity);
            setLength(WarpingTransData.set_length);
            setCreeels(WarpingTransData.no_of_creels);


            setMaterialName(WarpingTransData.product_material_name);
            if (WarpingTransData.warping_order_status !== 'P') {
                setApprovedById(WarpingTransData.approved_by_id);
            }

            // if (cmb_customer_id_ref.current) {
            //     cmb_customer_id_ref.current.value = WarpingTransData.customer_id;
            // }
            setCustomerId(WarpingTransData.customer_id)
            // cmb_customer_id_ref.current.value = WarpingTransData.customer_id;
            if (parseInt(WarpingTransData.production_order_type_id) === 13) {
                debugger
                cmb_customer_id_ref.current.value = WarpingTransData.customer_id;
                let materialData = await FnComboBoxesOnChange("Customer");
                let customerArr = WarpingTransData.customer_order_no.toString().split(",");
                setCustomerOrder((prevData) => [...prevData, ...customerArr]);
                sizedYarnCustomerOrderRef.current.selectedOpts = customerArr;
                let materialList = await handleReactCheckBoxesForSizedYarn('CustomerOrder');
                // await handleReactCheckBoxesForSizedYarn('materialList');
                let materialArr = WarpingTransData.product_material_id.toString().split(",");
                setProductMaterialId((prevData) => [...prevData, ...materialArr]);
                sizedYarnMaterialRef.current.selectedOpts = materialArr;

                const getSalesOrderDetails = materialData.filter(item =>
                    customerArr.includes(item.customer_order_no)
                );

                if (getSalesOrderDetails.length > 0) {  // Ensure there's data
                    const salesOrderNos = getSalesOrderDetails.map(so => so.sales_order_no).join(", ");
                    setSalesOrderNo(salesOrderNos);
                }

                let qty = 0;

                // Filter materials that exist in materialArr
                let materialNames = materialList.filter(material =>
                    materialArr.includes(material['product_material_id'])
                );

                // Map over filtered materials to accumulate quantity and extract names
                let counts = materialNames.map(material => {
                    let quantity = parseFloat(material['material_quantity']) || 0; 
                    qty += quantity; 
                    return material['product_material_name']; 
                });

                console.log("Total Quantity:", qty);
                console.log("Material Names:", counts);


                setOrderQuantity(qty)
                setActualProductionCounts(counts);
            } else {
                setCustomerOrder(WarpingTransData.customer_order_no);
                setProductMaterialId(WarpingTransData.product_material_id);

                setSalesOrderNo(WarpingTransData.sales_order_no)
            }

            setSalesOrderDate(WarpingTransData.sales_order_date)
            // await FnComboBoxesOnChange('CustomerOrder')
            setJobType(WarpingTransData.job_type);

            if (WarpingTransData.production_order_type_id !== 13) {
                FnGetProductBasedProperties(WarpingTransData.product_material_id);
            }
            // await FnComboBoxesOnChange('ProductMaterial')
            setProductMaterialStyle(WarpingTransData.product_material_style)
            // await FnComboBoxesOnChange('ProductMaterialStyle')
            await FnGetPreviousPlannedProduction(WarpingTransData.customer_order_no, WarpingTransData.product_material_style, WarpingTransData.product_material_id)

            setOrderQuantity(WarpingTransData.order_quantity)


            if (keyForViewUpdate !== 'approve')
                setWarpingOrderStatus(WarpingTransData.warping_order_status)

            ////////////////////Setting Stock Details Json///////////////

            setMaterialRequisitionDetails(WarpingStockDetails);


            /////////////////////Setting Warping Details Data////////////////
            let warpingDetailsData = []
            let counts = []
            WarpingDetailsData.map((detail) => {
                let emptyJson = {};
                // if (detail.warping_quantity > 0) {
                emptyJson.company_id = COMPANY_ID;
                emptyJson.company_branch_id = COMPANY_BRANCH_ID;
                emptyJson.warping_production_order_id = warping_production_order_id;
                emptyJson.product_material_id = detail.product_material_id;
                // emptyJson.closing_balance_quantity = detail.closing_balance_quantity;
                // emptyJson.closing_balance_weight = detail.closing_balance_weight;
                emptyJson.production_count = detail.production_count_name;
                emptyJson.no_of_cones = detail.no_of_cones;
                emptyJson.supplier_id = detail.supplier_id;
                emptyJson.product_material_quantity = detail.warping_quantity;
                emptyJson.no_of_cones = detail.no_of_cones;
                emptyJson.product_material_name = detail.product_material_name;
                emptyJson.cone_per_wt = detail.cone_per_wt;
                emptyJson.product_material_code = detail.product_material_code
                warpingDetailsData.push(emptyJson);

                let emptyObj = {};
                // Assign additional properties to emptyObj if needed
                emptyObj['product_material_name'] = detail.product_material_name;
                emptyObj['product_material_id'] = detail.product_material_id;
                emptyObj['actual_count'] = detail.production_count_name;
                counts.push(emptyObj)
                // }
            });
            setProductionCountOptons(counts);
            setWarpingdata((prevDetails) => {
                const newDetails = warpingDetailsData;
                return newDetails;
            });


            setCreelData(WarpingCreelDetails);

        } catch (error) {
            console.log(error);
        }

    }

    const FnUpdateComboBoxData = () => {
        if (cmb_customer_id_ref.current) {
            cmb_customer_id_ref.current = null;
        }
        // Get customer details
        resetGlobalQuery();
        globalQuery.columns = ['field_id', 'field_name'];
        globalQuery.table = "cmv_customer"
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        comboDataAPiCall.current?.fillFiltersCombo(globalQuery).then((getCustomerDetails) => {
            if (getCustomerDetails.length !== 0) {
                const customers = [
                    { value: '', label: 'Select' },
                    { value: '0', label: 'Add New Record +' },
                    ...getCustomerDetails.map((customer) => ({ ...customer, value: customer.field_id, label: customer.field_name })),
                ];
                setCustomerList(customers);
                // setCustomerList(getCustomerDetails);
            }
        });
    }


    const FnFillCombos = async () => {
        // debugger
        try {
            resetGlobalQuery();
            globalQuery.columns = ['set_no', 'no_of_ends', 'product_material_id', 'sort_no', 'product_unit_id', 'production_section_id'];
            globalQuery.table = "xt_weaving_production_order"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "weaving_order_status", operator: "=", value: 'A' });
            globalQuery.orderBy = ["set_no DESC"]
            let getSetNosApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            if (getSetNosApiCall.length) {
                setSetNoOptions(getSetNosApiCall);
                setProductionSectionId(getSetNosApiCall[0].production_section_id);
            }


            // //Ring Frame Production Shift Details
            // resetGlobalQuery();
            // globalQuery.columns = ['actual_count', 'product_rm_name AS field_name', 'product_rm_id AS product_material_id'];
            // globalQuery.table = "sm_product_rm"
            // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: 12 });
            // comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getRawMaterialDetails) => {
            //     setProductionCountOptons(getRawMaterialDetails);
            // })

            //Constant 
            resetGlobalQuery();
            globalQuery.columns = ['approx_warping_production_bottom', 'warping_plan_product_constant'];
            globalQuery.table = "xt_production_settings"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            let getAPICallForProdSettings = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setBottomValue(getAPICallForProdSettings[0].approx_warping_production_bottom);
            setProductionConstant(getAPICallForProdSettings[0].warping_plan_product_constant)

            if (keyForViewUpdate === 'approve') {
                setActionLabel('Approve');
            }

            resetGlobalQuery();
            globalQuery.columns.push("field_id", 'field_name');
            globalQuery.table = "cmv_employee"
            globalQuery.conditions.push({ field: "is_active", operator: "=", value: 1 });
            globalQuery.conditions.push({ field: "employee_type", operator: "=", value: 'Staff' });
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getEmployeeApiCall) => {
                const approvedByList = [
                    { value: '', label: 'Select' },
                    ...getEmployeeApiCall.map((reporting) => ({ ...reporting, value: reporting.field_id, label: reporting.field_name, })),
                ];
                setApproveByOpts(getEmployeeApiCall);
            })


            // Get customer details
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name'];
            globalQuery.table = "cmv_customer"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current?.fillFiltersCombo(globalQuery).then((getCustomerDetails) => {
                if (getCustomerDetails.length !== 0) {
                    const customers = [
                        { value: '', label: 'Select' },
                        { value: '0', label: 'Add New Record +' },
                        ...getCustomerDetails.map((customer) => ({ ...customer, value: customer.field_id, label: customer.field_name })),
                    ];
                    setCustomerList(customers);
                    // setCustomerList(getCustomerDetails);
                }
            });

            resetGlobalQuery();
            globalQuery.columns = ['product_type_id', 'product_type_name'];
            globalQuery.table = "sm_product_type";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const productTypeList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            const productTypeOpts = [
                ...productTypeList
                    .filter(({ product_type_id }) => [9, 13].includes(product_type_id))
                    .map(({ product_type_id, product_type_name }) => ({
                        value: product_type_id,
                        label: product_type_name,
                    }))];
            setProductTypeOptions(productTypeOpts);

            // FnUpdateComboBoxData();
        } catch (error) {
            console.log(error);
        }
    }


    const FnCalculateSetLength = (creel_Details) => {

        // let set_length = 0;
        // let totalcreellength = creel_Details.reduce((acc, creel_Detail) => {
        //     return acc + parseFloat(creel_Detail['set_length']);
        // }, 0);

        // set_length = totalcreellength - bottomValue;
        if (creel_Details.length > 0) {
            setLength(validateNumberDateInput.current?.decimalNumber((creel_Details.reduce((acc, data) => acc + parseFloat(data['set_length']), 0)) / creel_Details.length, 4));
        }
    }


    const calculateWarpingDetailTblRow = (event, rowData) => {
        try {

            setIsLoading(true)

            let getApiCallStockDetails = finalStockDetailsArr.filter((detail) => {
                return detail.product_rm_id === rowData.product_material_id;
            });

            let clickedColName = event.target.getAttribute('Headers');
            let eventId = document.getElementById(event.target.id);
            let enteredValue = validateNumberDateInput.current.decimalNumber(event.target.value) || 0;
            const arrayIndex = parseInt(event.target.parentElement.parentElement.parentElement.getAttribute('rowindex'))


            if (isNaN(enteredValue)) {
                enteredValue = 0;
            }
            else if (enteredValue !== 0 || enteredValue != "") {
                event.target.parentElement.removeAttribute('data-tip');
            }

            // if (enteredValue === 0) {
            //     rowData['product_material_quantity'] = 0;
            //     rowData['no_of_cones'] = 0;
            // } else {
            switch (clickedColName) {
                case 'cone_per_wt':
                    rowData[clickedColName] = enteredValue;
                    break;
                case 'no_of_cones':
                    let productMaterialQty = (validateNumberDateInput.current.decimalNumber(enteredValue * rowData.cone_per_wt, 2));
                    if (!isNaN(productMaterialQty)
                        //  && productMaterialQty <= rowData['closing_balance_weight']
                    ) {
                        rowData[clickedColName] = enteredValue;
                        rowData.product_material_quantity = productMaterialQty
                        // delete event.parentElement.dataset.tip;
                    } else {
                        event.target.parentElement.setAttribute('data-tip', 'No. of Cones should be less than or equal to stock weight');
                    }

                    break;
                case 'product_material_quantity':
                    if (enteredValue <= rowData['closing_balance_weight']) {
                        rowData[clickedColName] = enteredValue;
                        if (rowData['cone_per_wt'] !== 0 && rowData['cone_per_wt'] !== null) {
                            let NoOfCones = (Math.ceil(rowData.product_material_quantity / rowData.cone_per_wt));
                            if (!isNaN(NoOfCones)) {
                                rowData['no_of_cones'] = NoOfCones;
                            }
                        }
                        event.target.parentElement.removeAttribute('data-tip');
                        delete event.parentElement.dataset.tip;
                    } else {
                        event.target.parentElement.setAttribute('data-tip', 'Warping quantity should be less than or equal to stock weight');
                    }

                    break;
                // }
            }

            let warpingQuantity = rowData.product_material_quantity;

            if (getApiCallStockDetails.length !== 0) {
                // Allocate final stock to each stock fill without changing the order 
                for (let i = 0; i < getApiCallStockDetails.length; i++) {
                    if (warpingQuantity === 0) {
                        getApiCallStockDetails[i].warping_quantity = 0;
                        continue;
                    }
                    let allocateAmount = Math.min(getApiCallStockDetails[i].closing_balance_weight, warpingQuantity);
                    getApiCallStockDetails[i].warping_quantity = allocateAmount;
                    warpingQuantity -= allocateAmount;
                }
                setIndividualStockDetails(getApiCallStockDetails);
                // finalStockDetailsObj[rowData['product_material_id']] = getApiCallStockDetails;
                // setFinalStockDetailsJson(finalStockDetailsObj);
            }

            const newDetails = [...warpingData];
            newDetails[arrayIndex] = rowData;

            FnAddMaterialCountWiseCreels(newDetails)
            setWarpingdata(newDetails);

        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    }
    const AddMaterial = async () => {
        setShowWRFilterForm(true)
    }
    // product filter columns
    let requiredCols = ['product_id', 'product_name', 'product_material_code', 'actual_count', 'product_tech_spect', 'product_std_weight', 'product_unit_name', 'product_material_indent_quantity', 'product_material_indent_weight',
        "product_material_approved_quantity", "product_material_approved_weight", "product_material_rejected_quantity", "product_material_rejected_weight",
        "approval_remark", "issue_item_status", 'product_material_grade_name', 'product_make_name', 'stock_quantity', 'stock_weight', "product_material_reserve_quantity",
        "product_material_reserve_weight", "product_material_receipt_quantity", "product_material_reserve_weight", "product_material_receipt_weight", "product_type_id",
        "product_material_return_quantity", "product_material_return_weight", 'product_unit_id', 'product_lead_time', 'godown_id', 'godown_section_id', 'godown_section_beans_id', 'godown_name', 'godown_section_name', 'godown_section_beans_name'
    ];

    const FnGetStockDetails = async () => {
        // debugger
        const getData = JSON.parse(sessionStorage.getItem('filteredMaterialData')) || [];

        try {
            setIsLoading(true)
            if (keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'update') {


                resetGlobalQuery();
                globalQuery.columns = [
                    'stock.stock_transaction_id',
                    'sm.product_rm_name AS product_material_name',
                    // 'ptv.indent_no',
                    'stock.goods_receipt_no',
                    'stock.customer_goods_receipt_no',
                    // 'stock.closing_balance_quantity',
                    // 'stock.closing_balance_weight',
                    'SUM(stock.closing_balance_quantity) AS closing_balance_quantity',
                    'SUM(stock.closing_balance_weight) AS closing_balance_weight',
                    'stock.supplier_name',
                    'stock.supplier_id',
                    'stock.customer_name',
                    'stock.customer_id',
                    'sm.product_rm_code AS product_material_code',
                    'stock.product_rm_id AS product_material_id',
                    'stock.product_packing_name',
                    'stock.weight_per_box_item As cone_per_wt',
                    'stock.product_material_unit_id',
                    'sm.actual_count',
                    'stock.batch_no',
                    'ptv.remark',
                    // 'mtrm.count_type'
                ];

                globalQuery.table = "smv_product_rm_stock_details stock";
                globalQuery.joins = [
                    {
                        table: "pt_goods_receipt_details ptv",
                        type: "left",
                        on: [
                            { left: "ptv.product_material_id", right: "stock.product_rm_id" },
                            { left: "ptv.goods_receipt_no", right: "stock.goods_receipt_no" },
                            {
                                "left": "ptv.is_delete",
                                "right": "0"
                            }
                        ]
                    },


                    {
                        "table": `sm_product_rm sm`,
                        "type": "LEFT",
                        "on": [
                            {
                                "left": "sm.product_rm_id",
                                "right": "stock.product_rm_id",
                            },

                            {
                                "left": "sm.is_delete",
                                "right": "0"
                            }
                        ]
                    },

                ];


                globalQuery.conditions = [
                    { field: "stock.day_closed", operator: "=", value: 0 },
                    { field: "stock.product_rm_id", operator: "=", value: getData[0].product_id },
                    { field: "stock.godown_id", operator: "=", value: "2" },
                ];
                globalQuery.groupBy = [
                    'stock.batch_no',
                    'stock.product_rm_id',
                    'stock.weight_per_box_item',
                    'stock.supplier_id',
                ];

                // Make the API call to fill filters combo
                let getStockDetails = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);

                // Create a Set of stock_transaction_id from materialRequisitionDetails
                const existingTransactionIds = new Set(materialRequisitionDetails.map(item => item.stock_transaction_id));

                const getExistingRequisitionDetails = getStockDetails.filter(item =>
                    !existingTransactionIds.has(item.stock_transaction_id) &&
                    item.closing_balance_quantity > 0
                );

                // Optionally, if you want to combine both existing and new data into materialRequisitionDetails:
                const combinedData = [...materialRequisitionDetails, ...getExistingRequisitionDetails];

                console.log('combinedData:- ', combinedData);


                // Update the state with the combined data
                setMaterialRequisitionDetails(combinedData);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    };

    // const FnGetMaterialDetails = () => {
    //     const getData = JSON.parse(sessionStorage.getItem('filteredMaterialData')) || [];

    //     // Define a mapping for the key replacements
    //     const keyMapping = {
    //         product_id: "product_material_id",
    //         product_name: "product_material_name",
    //         actual_count: "production_actual_count",
    //         product_material_code: 'product_material_code'
    //     };

    //     // Transform the data and add default values for missing fields
    //     const transformedData = getData.map(item => {
    //         const newItem = {};

    //         // Replace keys based on the mapping
    //         for (const [oldKey, newKey] of Object.entries(keyMapping)) {
    //             newItem[newKey] = item[oldKey] || null; // Set null if the key is missing in the item
    //         }

    //         // Add additional fields with default values
    //         newItem.warping_quantity = 0;
    //         newItem.no_of_cones = 0;
    //         newItem.cone_per_wt = 1;

    //         return newItem;
    //     });

    //     // Update the state with the transformed data
    //     setWarpingdata(transformedData);

    //     console.log('Transformed Data: ', transformedData);
    // };

    const renderStockDetailsTable = useMemo(() => {
        return (
            <Table id="stockDetailsTblId" className="erp_table erp_table_scroll mt-2" style={{
                overflow: "auto", height: '225px'
                // height: individualStockDetails.length <= 5 ? 'auto' : '225px',
            }} bordered striped hover>
                <thead className="erp_table_head">
                    <tr>
                        {/* <th className="erp_table_th">Action</th> */}
                        <th className="erp_table_th" style={{ width: "100px" }}>Material Code</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Material Name</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Count</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Customer Name</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Supplier Name</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Lot No</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Remark</th>
                        {keyForViewUpdate === 'Add' && (
                            <>
                                <th className="erp_table_th" style={{ width: "100px" }}>Stock Wt.</th>
                                <th className="erp_table_th" style={{ width: "100px" }}>Stock Qty.</th>
                            </>
                        )}

                        <th className="erp_table_th" style={{ width: "100px" }}>Weight Per Cone</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>No. Of Cones</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Warping Quantity (Kg.)</th>

                        {/* <th className="erp_table_th" style={{ width: "100px" }}>Goods Receipt No</th> */}
                        {/* <th className="erp_table_th" style={{ width: "100px" }}>Customer Receipt No</th> */}
                        {/* <th className="erp_table_th" style={{ width: "100px" }}>Indent No</th> */}
                        {/* <th className="erp_table_th" style={{ width: "100px" }}>Packing Name</th> */}
                        {/* <th className="erp_table_th" style={{ width: "100px" }}>Count Type</th> */}


                    </tr>
                </thead>

                <tbody>
                    {materialRequisitionDetails.length > 0 ? (
                        materialRequisitionDetails.map((stockDetail, indexOfItem) => (
                            <tr rowIndex={indexOfItem}>
                                {/* <td className="erp_table_td">
                                    <input type="checkbox" name="selectStockDetails" className="form-check-input selectStockDetails"
                                        id={'selectStockDetails_' + indexOfItem} defaultChecked={stockDetail.warping_quantity > 0}
                                        disabled={keyForViewUpdate === 'view'}
                                    />
                                </td> */}
                                <td className="erp_table_td" id={`product_material_code_${indexOfItem}`}>{stockDetail.product_material_code} </td>
                                <td className="erp_table_td" id={`product_material_name_${indexOfItem}`}>{stockDetail.product_material_name} </td>
                                <td className="erp_table_td" id={`production_count_name_${indexOfItem}`}>{stockDetail.actual_count} </td>
                                <td className="erp_table_td" id={`customer_name_${indexOfItem}`}>{stockDetail.customer_name} </td>
                                <td className="erp_table_td" id={`supplier_name_${indexOfItem}`}>{stockDetail.supplier_name} </td>
                                <td className="erp_table_td" id={`batch_no_${indexOfItem}`}>
                                    {stockDetail.batch_no}
                                </td>
                                <td className="erp_table_td" id={`remark_${indexOfItem}`}>
                                    {stockDetail.remark}
                                </td>
                                {/* <td className="erp_table_td" id={`production_order_no_${indexOfItem}`}>
                                    {stockDetail.closing_balance_weight}
                                </td>

                                <td className="erp_table_td" id={`product_material_quantity_${indexOfItem}`}>
                                    {stockDetail.closing_balance_quantity}
                                </td> */}
                                {keyForViewUpdate === 'Add' && (
                                    <>
                                        <td className="erp_table_td" id={`production_order_no_${indexOfItem}`}>
                                            {stockDetail.closing_balance_weight}
                                        </td>
                                        <td className="erp_table_td" id={`product_material_quantity_${indexOfItem}`}>
                                            {stockDetail.closing_balance_quantity}
                                        </td>
                                    </>
                                )}

                                <td className="erp_table_td" id={`cone_per_wt_${indexOfItem}`}>
                                    {stockDetail.cone_per_wt}
                                </td>
                                <td className="erp_table_td" id={`no_of_cones_${indexOfItem}`}>
                                    <input
                                        type="text"
                                        style={{ width: '78px', marginRight: '3px', backgroundColor: '#AFE1AF' }}
                                        className="erp_input_field_table_txt text-end mb-1"
                                        id={`no_of_cones_${indexOfItem}`}
                                        value={stockDetail.no_of_cones === undefined ? stockDetail.no_of_cones = 0 : stockDetail.no_of_cones}
                                        onChange={(e) => FnCalculateStockDetailTblRow(e, stockDetail)}
                                        onBlur={(e) => FnCalculateStockDetailTblRow(e, stockDetail)}
                                        Headers='no_of_cones'
                                        disabled={keyForViewUpdate === 'view' && keyForViewUpdate === 'update'}
                                    />
                                </td>
                                <td className="erp_table_td">
                                    <input
                                        type="text" style={{ width: '100px', backgroundColor: '#AFE1AF' }}
                                        className="erp_input_field_table_txt mb-0 text-end "
                                        disabled={keyForViewUpdate !== 'Add'}
                                        id={`warping_quantity_${indexOfItem}`}
                                        onChange={(e) => FnCalculateStockDetailTblRow(e, stockDetail)}
                                        onBlur={(e) => FnCalculateStockDetailTblRow(e, stockDetail)}
                                        value={stockDetail.warping_quantity}
                                        Headers='warping_quantity'
                                    />
                                </td>
                                {/* <td className="erp_table_td" id={`goods_receipt_no_${indexOfItem}`}>
                                    {stockDetail.goods_receipt_no}
                                </td> */}
                                {/* <td className="erp_table_td" id={`customer_goods_receipt_no_${indexOfItem}`}>
                                    {stockDetail.customer_goods_receipt_no}
                                </td> */}
                                {/* <td className="erp_table_td" id={`indent_no_${indexOfItem}`}>
                                    {stockDetail.indent_no}
                                </td> */}
                                {/* <td className="erp_table_td" id={`product_material_quantity_${indexOfItem}`}>
                                    {stockDetail.product_packing_name}
                                </td> */}
                                {/* <td className="erp_table_td" id={`count_type_${indexOfItem}`}>
                                    {stockDetail.count_type}
                                </td> */}

                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td className="erp_table_td text-center" colSpan={17} >No Records Found..!</td>
                        </tr>
                    )}
                </tbody>
            </Table>

        );
    }, [materialRequisitionDetails, warpingData]);


    const FnCalculateStockDetailTblRow = (event, rowData) => {
        try {
            if (keyForViewUpdate === 'Add') {
                setIsLoading(true)

                let clickedColName = event.target.getAttribute('Headers');
                let eventId = document.getElementById(event.target.id);
                let enteredValue = parseFloat(event.target.value);
                const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))

                // const stockDetailCheckbox = document.getElementById(`selectStockDetails_${arrayIndex}`);
                // if (!stockDetailCheckbox.checked) {
                //     stockDetailCheckbox.parentElement.dataset.tip = 'Please check this checkbox';
                //     setTimeout(() => {
                //         delete stockDetailCheckbox.parentElement.dataset.tip;
                //     }, 5000);
                //     return;
                // }

                if (isNaN(enteredValue)) {
                    enteredValue = 0;
                }
                else if (enteredValue != 0 || enteredValue != "") {
                    delete eventId.parentElement.dataset.tip;
                }

                if (clickedColName === 'warping_quantity') {
                    // Update the specific entry in StockDetails
                    if (enteredValue <= rowData.closing_balance_weight) {
                        rowData[clickedColName] = enteredValue;
                        if (rowData['cone_per_wt'] !== 0 && rowData['cone_per_wt'] !== null) {
                            let NoOfCones = (Math.ceil(rowData.warping_quantity / rowData.cone_per_wt));
                            if (!isNaN(NoOfCones)) {
                                rowData['no_of_cones'] = NoOfCones;
                            }
                        }
                        delete eventId.parentElement.dataset.tip;
                    } else {
                        eventId.parentElement.dataset.tip = 'Enter Qty Less than (or) Equal to Stock Wt.';
                        return;
                    }

                } else if (clickedColName === 'no_of_cones') {
                    let productMaterialQty = (validateNumberDateInput.current.decimalNumber(enteredValue * rowData.cone_per_wt, 2));
                    if (!isNaN(productMaterialQty) && productMaterialQty <= rowData['closing_balance_weight']) {
                        rowData[clickedColName] = enteredValue;
                        rowData.warping_quantity = productMaterialQty
                        delete eventId.parentElement.dataset.tip;
                        event.target.parentElement.removeAttribute('data-tip');

                    } else {
                        event.target.parentElement.setAttribute('data-tip', 'Warping quantity should be less than or equal to stock weight');
                        return;
                    }

                }

                setMaterialRequisitionDetails((prevData) => {
                    const newData = [...prevData];
                    newData[arrayIndex] = rowData;
                    return newData;
                });
            }
        } catch (error) {
            console.log('error: ', error);
        } finally {
            setIsLoading(false);
        }
    }

    const validateFields = async () => {
        await validate.current.validateFieldsOnChange('warpingOrderFormId');
    }

    const validatingBeamDetails = () => {
        if (creelData.length === parseInt(txt_creels)) {
            $('#error_txt_creels').hide();
            return true;
        } else {
            $('#error_txt_creels').text('Enter Proper Beam Deatils..!').show();
            return false;
        }
    }

    const validatingWarpingCreelDetails = () => {
        debugger
        let warpingCreelsIsValid = true;

        if (warpingData.length !== 0) {
            creelData.length > 0 && creelData?.forEach((data, index) => {
                const keysToCheck = [
                    'product_material_id', 'per_creel_ends', 'creel_bottom',
                    'no_of_cones', 'creel_length', 'no_of_beams',
                    'creel_weight'
                ];

                keysToCheck.forEach((dataKey) => {
                    if (data.hasOwnProperty(dataKey)) {  // Ensure key exists
                        let keyId = $("#" + dataKey + `_${index}`);

                        if (keyId.length > 0) {  // Check if element exists
                            if ([0, '', '0'].includes(keyId.val())) {
                                keyId.parent().attr("data-tip", "Please Enter proper data...!");
                                warpingCreelsIsValid = false;
                                return false;
                            }
                        } else {
                            console.warn(`Element not found: #${dataKey}_${index}`);
                        }
                    }
                });
            });
        } else {
            setErrMsg("Please create material requisition...!");
            setShowErrorMsgModal(true);
            warpingCreelsIsValid = false;
        }

        if (warpingCreelsIsValid === true) {
            setshowModalForConesConsumption(true);
        }
        return warpingCreelsIsValid;
    };


    const [txt_material_issue_no, setMaterialIssueNo] = useState(0);
    const generateAutoNoAPiCalls = useRef();

    const generateIndentIssueNo = async () => {
        const autoNoApiCall = await generateAutoNoAPiCalls.current.generateTAutoNo("st_indent_material_issue_master", "issue_no", "PRM", "MI", "5", "indent_issue_type_id", 12);
        setMaterialIssueNo(autoNoApiCall);
        return autoNoApiCall;
    };

    const addIndentIssue = async () => {
        // debugger
        try {
            setIsLoading(true)
            let lastIndentissueNo = txt_material_issue_no;
            lastIndentissueNo = await generateIndentIssueNo();

            let json = { 'TransHeaderData': {}, 'TransDetailData': [], 'commonIds': {} }
            // TransHeaderData
            const indentMasterFormData = {
                issue_master_transaction_id: 0,
                company_branch_id: COMPANY_BRANCH_ID,
                financial_year: FINANCIAL_SHORT_NAME,
                issue_no: lastIndentissueNo,
                issue_date: dt_approved_date,
                issue_version: 1,
                issue_source: "I",
                customer_id: cmb_customer_id,
                customer_order_no: cmb_customer_order,
                customer_order_date: dt_sales_order_date,
                department_id: "23",
                sub_department_id: "93",
                requisition_by_id: UserId,
                requisition_date: dt_approved_date,
                approved_by_id: cmb_approved_by_id,
                approved_date: dt_approved_date,
                accepted_by_id: null,
                accepted_date: null,
                cost_center_id: "83",
                expected_schedule_date: dt_approved_date,
                issue_status: "P",
                issued_by_id: null,
                remark: "",
                is_active: true,
                created_by: UserName,
                modified_by: null,
                company_id: COMPANY_ID,
                indent_issue_type_id: "12",
                indent_issue_type: "Raw Material",
                issue_group_type: "Consumables",
                set_no: txt_set_no,
                sales_type: job_type,
                godown_issuer_id: null
            }
            json.TransHeaderData = indentMasterFormData;

            for (let creelIndex = 0; creelIndex < creelData.length; creelIndex++) {
                const creelRow = creelData[creelIndex];

                const IndentIssueRow = {};
                IndentIssueRow['company_id'] = COMPANY_ID;
                IndentIssueRow['company_branch_id'] = COMPANY_BRANCH_ID;
                IndentIssueRow['financial_year'] = FINANCIAL_SHORT_NAME;
                IndentIssueRow['issue_details_transaction_id'] = 0;
                IndentIssueRow['issue_no'] = lastIndentissueNo;
                IndentIssueRow['issue_date'] = dt_approved_date;
                IndentIssueRow['issue_version'] = "1";
                IndentIssueRow['indent_no'] = null;
                IndentIssueRow['indent_date'] = null;
                IndentIssueRow['indent_version'] = "1";

                // Use the first entry of indentIssueDetails (assuming single entry as per your description)
                // const indentIssueDetailsRow = indentIssueDetails[0];
                const indentIssueDetailsRow = indentIssueDetails.find((material) =>
                    material.product_material_id === creelRow.product_material_id
                );

                IndentIssueRow['creel_no'] = `${creelRow.creel_no}(${txt_set_no})`;

                IndentIssueRow['product_material_id'] = indentIssueDetailsRow.product_material_id;
                IndentIssueRow['product_material_unit_id'] = indentIssueDetailsRow.product_material_unit_id;
                IndentIssueRow['product_material_indent_quantity'] = creelRow.no_of_cones; // Use creelData
                IndentIssueRow['product_material_indent_weight'] = creelRow.actual_net_wt; // Use creelData
                IndentIssueRow['product_material_approved_quantity'] = indentIssueDetailsRow.product_material_approved_quantity;
                IndentIssueRow['product_material_approved_weight'] = indentIssueDetailsRow.product_material_approved_weight;
                IndentIssueRow['product_material_rejected_quantity'] = indentIssueDetailsRow.product_material_rejected_quantity;
                IndentIssueRow['product_material_rejected_weight'] = indentIssueDetailsRow.product_material_rejected_weight;
                IndentIssueRow['product_material_issue_quantity'] = 0;
                IndentIssueRow['product_material_issue_weight'] = 0;
                IndentIssueRow['product_material_receipt_quantity'] = 0;
                IndentIssueRow['product_material_receipt_weight'] = 0;
                IndentIssueRow['product_material_issue_return_quantity'] = 0;
                IndentIssueRow['product_material_issue_return_weight'] = 0;
                IndentIssueRow['product_std_weight'] = indentIssueDetailsRow.cone_per_wt;
                IndentIssueRow['issue_item_status'] = null;
                IndentIssueRow['indented_by_id'] = null;
                IndentIssueRow['issue_remark'] = null;
                IndentIssueRow['issue_master_transaction_id'] = 0;
                IndentIssueRow['indent_details_id'] = null;
                IndentIssueRow['customer_order_no'] = cmb_customer_order;
                IndentIssueRow['issue_batch_no'] = indentIssueDetailsRow.batch_no;
                IndentIssueRow['created_by'] = UserName;
                IndentIssueRow['modified_by'] = null;
                IndentIssueRow['prev_issue_quantity'] = indentIssueDetailsRow.prev_issue_quantity;
                IndentIssueRow['prev_issue_weight'] = indentIssueDetailsRow.prev_issue_weight;
                IndentIssueRow['godown_id'] = indentIssueDetailsRow.godown_id ?? 2;
                IndentIssueRow['godown_section_id'] = indentIssueDetailsRow.godown_section_id ?? 1;
                IndentIssueRow['godown_section_beans_id'] = indentIssueDetailsRow.godown_section_beans_id ?? 1;
                IndentIssueRow['cost_center_id'] = "83";
                IndentIssueRow['profit_center_id'] = "9";
                IndentIssueRow['routing_code'] = "M07-PD WVG";
                IndentIssueRow['issue_requisition_type'] = "A"
                IndentIssueRow['set_no'] = txt_set_no
                IndentIssueRow['cone_per_wt'] = indentIssueDetailsRow.cone_per_wt;
                IndentIssueRow['product_material_issue_boxes'] = indentIssueDetailsRow.product_material_issue_boxes ?? 0;
                IndentIssueRow['goods_receipt_no'] = indentIssueDetailsRow.goods_receipt_no;

                json.TransDetailData.push(IndentIssueRow);
            }

            console.log(json.TransDetailData);

            // Common Ids
            json.commonIds.company_id = COMPANY_ID
            json.commonIds.issue_no = lastIndentissueNo
            json.commonIds.financial_year = FINANCIAL_SHORT_NAME
            console.log(json);
            const formData = new FormData();
            formData.append(`StIndentMaterialIssueData`, JSON.stringify(json))
            const requestOptions = {
                method: 'POST',
                body: formData
            };
            const acceptFlag = "addOrUpdate";

            const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StIndentIssueMaster/FnAddUpdateRecord/${acceptFlag}`, requestOptions)
            const responce = await apicall.json();
            setIsLoading(false)
        } catch (error) {
            console.log("error: ", error)
        } finally {
            setIsLoading(false)
        }
    }
    const addWarpingProductionOrderPlan = async () => {
        debugger

        try {
            setIsLoading(true)
            setshowModalForConesConsumption(false);

            let validation = await validate.current.validateForm('warpingOrderFormId');

            if (keyForViewUpdate === 'approve' && (cmb_approved_by_id === '' || cmb_approved_by_id === '0')) {
                $("#error_cmb_approved_by_id").text("Please fill this field...!");
                $("#error_cmb_approved_by_id").show();
                $("#cmb_approved_by_id").focus();
                return false;
            }

            if (validation === true && validatingBeamDetails() === true && await FnCheckSetNoExists() === false) {
                const json = { 'TransHeaderData': {}, 'TransDetailData': [], 'TransStockDetailData': [], 'TransBeamDetailData': [], 'commonIds': { 'company_id': COMPANY_ID, 'warping_production_order_id': warping_production_order_id, 'financial_year': FINANCIAL_SHORT_NAME, 'keyForViewUpdate': keyForViewUpdate } }

                let transHeaderJson = {
                    warping_production_order_id: warping_production_order_id,
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    // production_section_id: productionSectionId,
                    warping_order_no: txt_warping_order_no,
                    production_order_type_id: cmb_product_type_id,
                    customer_id: cmb_customer_id,
                    customer_order_no: cmb_customer_order.toString(),
                    product_material_id: cmb_product_material_id.toString(),
                    product_material_style: cmb_product_material_style,
                    set_no: txt_set_no,
                    order_quantity: order_quantity,
                    schedule_quantity: txt_schedule_quantity > 0 ? txt_schedule_quantity : 0,
                    sort_no: txt_sort_no,
                    warping_schedule_date: dt_schedule_date,
                    set_length: txt_set_length,
                    no_of_creels: txt_creels,
                    t_ends: txt_t_ends,
                    bottom_value: bottomValue,
                    warping_plan_date: dt_warping_plan_date,
                    warping_order_status: cmb_warping_order_status,
                    approved_by_id: keyForViewUpdate === 'approve' ? cmb_approved_by_id : 0,
                    approved_date: keyForViewUpdate === 'approve' ? dt_approved_date : '',
                    // status_remark
                    // other_terms_conditions
                    // remark
                    created_by: UserName,
                    modified_by: UserName,
                    modified_on: new Date().toISOString()
                }
                json.TransHeaderData = transHeaderJson;

                let totalCones = 0; let totalWeight = 0;

                // let valiadteCones_CreelWeight = true;
                if (keyForViewUpdate === 'Add' || keyForViewUpdate === 'update') {
                    /////Beam Details
                    creelData.map((detail) => {
                        totalCones += parseFloat(detail['no_of_cones']);
                        totalWeight += parseFloat(detail['creel_weight']);
                        let emptyJson = {};
                        emptyJson.company_id = COMPANY_ID;
                        emptyJson.creel_no = detail.creel_no
                        emptyJson.company_branch_id = COMPANY_BRANCH_ID;
                        emptyJson.warping_production_order_creels_id = detail?.warping_production_order_creels_id > 0
                            ? detail?.warping_production_order_creels_id
                            : 0;
                        emptyJson.set_no = txt_set_no;
                        emptyJson.product_material_id = detail.product_material_id
                        emptyJson.production_count = detail?.production_actual_count || detail?.production_count;
                        emptyJson.no_of_cones = detail.no_of_cones;
                        emptyJson.per_creel_ends = detail.per_creel_ends;
                        emptyJson.creel_bottom = detail.creel_bottom;
                        emptyJson.creel_length = detail.creel_length;
                        emptyJson.creel_weight = detail.creel_weight;
                        emptyJson.set_length = detail.set_length;
                        emptyJson.no_of_beams = detail.no_of_beams;
                        emptyJson.cone_per_wt = detail.cone_per_wt;
                        emptyJson.actual_net_wt = detail.actual_net_wt;
                        emptyJson.calculated_bottom = detail.calculated_bottom;
                        emptyJson.created_by = UserName;
                        emptyJson.modified_by = UserName;
                        modified_on: new Date().toISOString();
                        json.TransBeamDetailData.push(emptyJson);
                    });

                    if (keyForViewUpdate === 'Add') {
                        //Stock Details - pop up
                        materialRequisitionDetails.map((detail) => {

                            let emptyJson = {};
                            if (detail.warping_quantity > 0) {
                                emptyJson.company_id = COMPANY_ID;
                                emptyJson.company_branch_id = COMPANY_BRANCH_ID;
                                emptyJson.warping_production_order_id = warping_production_order_id;
                                emptyJson.set_no = txt_set_no;
                                emptyJson.product_material_id = detail.product_material_id;
                                emptyJson.goods_receipt_no = detail.goods_receipt_no;
                                emptyJson.indent_no = detail.indent_no
                                emptyJson.no_of_cones = detail.no_of_cones;
                                emptyJson.issue_requisition_type = "A"
                                emptyJson.batch_no = detail.batch_no;
                                emptyJson.supplier_id = detail.supplier_id === '' || detail.supplier_id === null ? 0 : detail.supplier_id;
                                emptyJson.warping_quantity = detail.warping_quantity;
                                emptyJson.cone_per_wt = detail.cone_per_wt;
                                emptyJson.product_material_unit_id = detail.product_material_unit_id;
                                emptyJson.remark = detail.remark;
                                emptyJson.created_by = UserName;
                                json.TransStockDetailData.push(emptyJson);
                            }
                        });

                        // const getProductMaterialUnitId = (product_material_id) => {
                        //     let matchedDetails = materialRequisitionDetails.find(detail => detail.product_rm_id === product_material_id);
                        //     return matchedDetails['product_material_unit_id']
                        // }


                        //Warping Details Data
                        warpingData.map((detail) => {
                            // if (totalCones > detail['no_of_cones']) {
                            //     $('.cones')
                            //         .attr('data-tip', 'Cone/Creel must be less than or equal to No Of Cones')
                            //         .focus(); // Set focus
                            //     valiadteCones_CreelWeight = false;
                            // } else {
                            //     $('.cones').removeAttr('data-tip');
                            // }

                            // if (validateNumberDateInput.current.decimalNumber(totalWeight, 2) > detail['product_material_quantity']) {
                            //     $('.creelweight')
                            //         .attr('data-tip', 'Creel Weight must be less than or equal to Warping Quantity')
                            //         .focus(); // Set focus
                            //     valiadteCones_CreelWeight = false;
                            // } else {
                            //     $('.creelweight').removeAttr('data-tip');
                            // }
                            let emptyJson = {};
                            emptyJson.company_id = COMPANY_ID;
                            emptyJson.company_branch_id = COMPANY_BRANCH_ID;
                            emptyJson.warping_production_order_id = warping_production_order_id;
                            emptyJson.product_material_id = detail.product_material_id;
                            // emptyJson.product_material_unit_id = getProductMaterialUnitId(detail.product_material_id);
                            emptyJson.product_material_unit_id = 0;

                            emptyJson.no_of_cones = detail.no_of_cones;
                            emptyJson.warping_quantity = detail.product_material_quantity;
                            emptyJson.supplier_id = detail.supplier_id === '' || detail.supplier_id === null ? 0 : detail.supplier_id;
                            emptyJson.cone_per_wt = detail.cone_per_wt;
                            emptyJson.created_by = UserName;
                            json.TransDetailData.push(emptyJson);
                        });
                    }
                }

                // if (valiadteCones_CreelWeight === true) {
                const formData = new FormData()
                formData.append('warpingProductionOrderRequest', JSON.stringify(json))
                const forwardData = {
                    method: 'POST',
                    body: formData,
                }
                const WPOApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWarpingProductionOrder/FnAddUpdateRecord`, forwardData)
                const responce = await WPOApiCall.json()
                if (responce.success === 0) {
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)
                } else {
                    if (keyForViewUpdate === "approve") {
                        await addIndentIssue();
                    }
                    setSuccMsg(responce.message)
                    setShowSuccessMsgModal(true);
                }
                // }

            }
        } catch (error) {
            console.log('error:- ', error);
        } finally {
            setIsLoading(false)

        }
    }

    // Modal for showing Cones Distribution
    const [showModalForConesConsumption, setshowModalForConesConsumption] = useState(false);

    // const [isFullyConsumed, setIsFullyConsumed] = useState(true);

    const openModalForConesConsumption = () => {
        let tempIsFullyConsumed = true; // Temporary variable to check consumption status

        return (
            <Modal show={showModalForConesConsumption} backdrop="static" keyboard={false} centered>
                <Modal.Header> <button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={() => setshowModalForConesConsumption(false)}></button> </Modal.Header>

                <Modal.Body className="erp_modal_body">
                    <div style={{ textAlign: "center", marginBottom: "10px", color: '#831657' }}>
                        <h6 style={{ marginTop: "10px" }}>You are Authorized To use these material cones as shown below...!</h6>
                        <h6 style={{ marginTop: "10px" }}>
                            {actualProductionCounts.map((count, idx) => (
                                <span key={idx} style={{ margin: "0 5px" }}>
                                    {count}{idx !== actualProductionCounts.length - 1 ? ", " : ""}
                                </span>
                            ))}
                        </h6>
                    </div>

                    <div style={{ textAlign: "center", marginBottom: "10px" }}>
                        <h6 style={{ marginTop: "10px" }}>Cone's Distribution Against Each Material...!!</h6>
                    </div>

                    <div style={{ maxWidth: "100%", overflowX: "auto", marginBottom: "3px" }}>
                        <Table id="conesConsumptionTblId" className="erp_table" bordered striped hover>
                            <thead className="erp_table_head">
                                <tr>
                                    <th className="erp_table_th" style={{ width: "120px" }}>Material Code</th>
                                    <th className="erp_table_th" style={{ width: "200px" }}>Material Name</th>
                                    <th className="erp_table_th" style={{ width: "180px" }}>No Of Cones Required</th>
                                    <th className="erp_table_th" style={{ width: "180px" }}>No Of Cones Distributed</th>
                                </tr>
                            </thead>
                            <tbody>
                                {warpingData.length > 0 &&
                                    warpingData.map((data, index) => {
                                        const conesDistributed = creelData.reduce(
                                            (acc, creel) =>
                                                creel.product_material_id === data.product_material_id &&
                                                    parseFloat(creel.cone_per_wt) === parseFloat(data.cone_per_wt)
                                                    ? acc + creel.no_of_cones
                                                    : acc,
                                            0
                                        );

                                        // If any row is not fully consumed, update tempIsFullyConsumed
                                        if (conesDistributed !== data.no_of_cones || !actualProductionCounts.includes(data.product_material_name)) {
                                            tempIsFullyConsumed = false;
                                        }

                                        return (
                                            <tr key={index}>
                                                <td className="erp_table_td" style={{ backgroundColor: !actualProductionCounts.includes(data.product_material_name) ? 'lightcoral' : 'transparent' }}>{data.product_material_code}</td>
                                                <td
                                                    className="erp_table_td"
                                                    style={{ backgroundColor: !actualProductionCounts.includes(data.product_material_name) ? 'lightcoral' : 'transparent' }}
                                                >
                                                    {data.product_material_name}
                                                </td>
                                                <td className="erp_table_td" style={{ backgroundColor: !actualProductionCounts.includes(data.product_material_name) ? 'lightcoral' : 'transparent' }}>{data.no_of_cones}</td>
                                                <td className="erp_table_td" style={{ backgroundColor: !actualProductionCounts.includes(data.product_material_name) ? 'lightcoral' : 'transparent' }}>{conesDistributed}</td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>
                    </div>

                    {tempIsFullyConsumed ? (
                        <>
                            <h6 style={{ textAlign: "center", marginTop: "15px" }}>YOU CAN SAVE THE DATA ...!</h6>
                            <span className="erp_modal_success_icon"><RxCheckCircled /></span>
                        </>
                    ) : (
                        <>
                            <h6 style={{ textAlign: "center", marginTop: "15px" }}>PLEASE DISTRIBUTE CONES PROPERLY ...!</h6>
                            <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                        </>
                    )}
                </Modal.Body>

                <Modal.Footer className="erp-modal-footer" style={{ justifyContent: "center", padding: "15px" }}>
                    <Button
                        variant="success" className="erp-gb-button" disabled={!tempIsFullyConsumed} onClick={() => addWarpingProductionOrderPlan()}>Proceed to Save</Button>
                    <Button variant="success" className="erp-gb-button ms-2" onClick={() => setshowModalForConesConsumption(false)}>Distribute Again</Button>
                </Modal.Footer>
            </Modal>
        );
    };




    /////Print Functionality
    //GRN printout Start
    const warpingOrderContent = {
        company: {
            company: '',
            company_branch: '',
            company_name: ''
        },
        warpingTransData: {
            date: '',
            setNo: '',
            sortNo: '',
            txt_t_ends: '',
            orderNo: '',
            scheduleQuantity: '',
            productMaterialId: '',
            productMaterialName: '',
            creels: '',
            setLength: '',
        },
        warpingMaterialsPropertiesData: [],
        warpingMaterialsPropertiesColumns: [],
        warpingDetailsData: [],
        warpingStockDetails: [],
        warpingBeamDetails: []
    };

    const printWarpingProdOrderPlan = () => {
        try {
            ///Inserting Data into the warpingOrderContent Json

            ////////Company Details////////
            warpingOrderContent.company['company'] = COMPANY_ID;
            warpingOrderContent.company['company_branch'] = COMPANY_BRANCH_ID;
            warpingOrderContent.company['company_name'] = COMPANY_NAME;

            //////Warping Trans Data///////
            warpingOrderContent.warpingTransData['date'] = $('#dt_schedule_date').val();
            warpingOrderContent.warpingTransData['setNo'] = txt_set_no;
            warpingOrderContent.warpingTransData['sortNo'] = $('#txt_sort_no').val();
            warpingOrderContent.warpingTransData['txt_t_ends'] = txt_t_ends;
            warpingOrderContent.warpingTransData['orderNo'] = $('#txt_warping_order_no').val();
            warpingOrderContent.warpingTransData['scheduleQuantity'] = $('#txt_schedule_quantity').val();
            warpingOrderContent.warpingTransData['setLength'] = $('#txt_set_length').val();
            warpingOrderContent.warpingTransData['creels'] = $('#txt_creels').val();
            warpingOrderContent.warpingTransData['productMaterialId'] = $('#cmb_product_material_id').val();
            warpingOrderContent.warpingTransData['productMaterialName'] = txt_product_material_name;
            warpingOrderContent.warpingTransData['productMaterialName'] = txt_product_material_name;
            warpingOrderContent.warpingTransData['customer_order_no'] = cmb_customer_order;
            warpingOrderContent.warpingTransData['sales_order_no'] = txt_sales_order_no;
            warpingOrderContent.warpingTransData['sales_order_date'] = dt_sales_order_date;
            warpingOrderContent.warpingTransData['product_material_style'] = cmb_product_material_style;
            warpingOrderContent.warpingTransData['order_quantity'] = order_quantity;


            ///////Warping Sales Order Data//////
            // warpingOrderContent.warpingSalesData['HeaderData'] = headerData;
            // warpingOrderContent.warpingSalesData['HeaderStyleAbbData'] = HeaderStyleAbbData;
            // warpingOrderContent.warpingSalesData['SalesOrderData'] = salesOrderData;
            warpingOrderContent.warpingMaterialsPropertiesData = memoizedData;
            warpingOrderContent.warpingMaterialsPropertiesColumns = memoizedColumns;

            //////Warping Details Data//////
            warpingOrderContent.warpingDetailsData = warpingData;

            /////Warping Order Beam Details
            let creelDetails = [];
            creelDetails = creelData.map((detail, indexOfItem) => {
                detail['production_count_name'] = $('#product_material_id_' + indexOfItem + ' option:selected').text();
                return detail;
            });

            warpingOrderContent.warpingBeamDetails = creelDetails;

            /////Warping Stock Details//////
            warpingOrderContent.warpingStockDetails = materialRequisitionDetails;
            setWarpingContentPDF(warpingOrderContent);
            // navigate('/Invoice', { state: { invoiceData: warpingOrderContent, navigationLink: "/Masters/MWarpingProductionPlan/FrmWarpingProductionPlanEntry", invoiceType: 'WARP', title: 'Warping Production Order Plan', idList: idList, keyForViewUpdate: keyForViewUpdate } });
            handleShow();
            console.log(warpingOrderContent);
        } catch (error) {

        }
    }

    const FnCreelsOnChange = (no_of_creels) => {
        if (validateNumberDateInput.current.isInteger(no_of_creels)) {
            FnAddMaterialCountWiseCreels();
        }
    }


    const FnAddMaterialCountWiseCreels = async (getWarpingMaterials = warpingData) => {
        // debugger
        $('.creelweight').removeAttr('data-tip');
        $('.cones').removeAttr('data-tip');
        let creel_Details = [];
        if (getWarpingMaterials.length === 1) {
            const no_of_creels = parseInt(document.getElementById('txt_creels').value);
            creelsRef.current.disabled = false;
            let product_material_quantity =
                getWarpingMaterials?.[0]?.product_material_quantity ??
                warpingData?.[0]?.product_material_quantity ??
                0;

            for (const element of getWarpingMaterials) {
                const { product_material_id, no_of_cones: total_cones, cone_per_wt, production_count } = element;
                const creels = no_of_creels;

                // Distribute no_of_cones among creels
                const base_cones = Math.floor(total_cones / creels);
                const remainder = total_cones % creels;
                let cones_distribution;
                if (creels > 0) {
                    cones_distribution = Array(creels).fill(base_cones);
                }

                for (let i = 0; i < remainder; i++) {
                    cones_distribution[i]++;
                }

                const conePerWt = parseFloat(cone_per_wt);
                const productionActualCount = parseFloat(element['production_count']);

                cones_distribution?.forEach((no_of_cones, index) => {
                    const creel_length = validateNumberDateInput.current.decimalNumber(
                        conePerWt * productionActualCount * productionConstant
                    ) || 0;
                    const creel_weight = validateNumberDateInput.current.decimalNumber(
                        // product_material_quantity / creels   //Change by Yogesh Sir
                        no_of_cones * conePerWt
                    ) || 0;
                    const actual_net_wt = validateNumberDateInput.current.decimalNumber(
                        (creel_length / productionConstant / productionActualCount) * no_of_cones
                    ) || 0;
                    const calculated_bottom = validateNumberDateInput.current.decimalNumber(
                        creel_weight - actual_net_wt
                    ) || 0;

                    creel_Details.push({
                        creel_no: `Creel ${index + 1}`,
                        per_creel_ends: 0,
                        creel_bottom: bottomValue,
                        product_material_id,
                        no_of_cones,
                        creel_length,
                        no_of_beams: 0,
                        set_length: 0,
                        cone_per_wt: conePerWt,
                        creel_weight,
                        production_count: productionActualCount,
                        actual_net_wt,
                        calculated_bottom,
                    });
                });
            }

        } else {
            creelsRef.current.disabled = true;
            getWarpingMaterials?.forEach((element, index) => {
                const conePerWt = parseFloat(element.cone_per_wt);
                const productionActualCount = parseFloat(element['production_count']);

                const creel_length = validateNumberDateInput.current.decimalNumber(
                    conePerWt * productionActualCount * productionConstant
                ) || 0;
                const creel_weight = validateNumberDateInput.current.decimalNumber(
                    // product_material_quantity / creels   //Change by Yogesh Sir
                    element['no_of_cones'] * conePerWt
                ) || 0;
                const actual_net_wt = validateNumberDateInput.current.decimalNumber(
                    (creel_length / productionConstant / productionActualCount) * element['no_of_cones']
                ) || 0;
                const calculated_bottom = validateNumberDateInput.current.decimalNumber(
                    creel_weight - actual_net_wt
                ) || 0;

                creel_Details.push({
                    creel_no: `Creel ${index + 1}`,
                    product_material_id: element['product_material_id'],
                    no_of_cones: element['no_of_cones'],
                    creel_length,
                    per_creel_ends: 0,
                    creel_bottom: bottomValue,
                    no_of_beams: 0,
                    set_length: 0,
                    cone_per_wt: conePerWt,
                    creel_weight,
                    production_count: productionActualCount,
                    actual_net_wt,
                    calculated_bottom,
                });
            });
        }

        setCreeels(creel_Details.length)
        setCreelData(creel_Details);
    };







    const FnComboBoxesOnChange = async (key) => {
        try {
            debugger
            switch (key) {
                case 'Customer':
                    const getCustomerId = cmb_customer_id_ref.current.value || ''
                    const product_type_id = productTypeComboRef.current.value;
                    // const getCustomerId = document.getElementById('cmb_customer_id').value;

                    // Onchange of customer set the lists to empty
                    setCustomerOrderList([])
                    setMaterialList([])
                    setMaterialStyleList([])
                    setCustomerOrder('')
                    setSalesOrderNo('')
                    setSalesOrderDate('')
                    setScheduleQty('')
                    setOrderQuantity('')
                    setProductMaterialId('')
                    setProductBasedPropertiesData([])
                    setProductBasedPropertiesColumns([])
                    setMaterialSortNo('')
                    setProductMaterialStyle('')

                    if (getCustomerId !== '' && getCustomerId !== '0') {
                        $('#error_cmb_customer_id').hide();

                        // Get customer order list
                        resetGlobalQuery();
                        globalQuery.columns = ['sales_order_no', 'sales_order_date', 'customer_order_no', 'customer_order_Date', 'job_type']
                        globalQuery.table = "mtv_sales_order_master_trading_summary"
                        globalQuery.conditions.push({ field: "customer_id", operator: "=", value: getCustomerId });
                        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                        globalQuery.conditions.push({ field: "sales_order_status", operator: "=", value: 'A' });
                        globalQuery.conditions.push({ field: "sales_order_type_id", operator: "=", value: product_type_id });

                        const customerOrderList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                        let customerOrderArr = (customerOrderList || []).map((order) => ({
                            ...order,
                            value: order.customer_order_no,
                            label: order.customer_order_no
                        }));
                        setCustomerOrderList(parseInt(product_type_id) === 13 ? customerOrderArr : customerOrderList);
                        if(keyForViewUpdate === 'update' || keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'){
                            return customerOrderArr;
                        }
                        
                    } else if (getCustomerId === '0') {
                        localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                        localStorage.setItem('customerIDs', JSON.stringify({ customerID: 0, keyForViewUpdate: 'Add', compType: 'Master' }))
                        const newTab = window.open('/Masters/Customer', '_blank');
                        if (newTab) { newTab.focus(); }
                    }

                    if (getCustomerId !== '0') {
                        setCustomerId(getCustomerId)
                    }
                    break;

                case 'ProductType':
                    const getProductTypeId = productTypeComboRef.current.value || ''

                    setCustomerOrderList([])
                    setMaterialList([])
                    setMaterialStyleList([])
                    setCustomerOrder('')
                    setSalesOrderNo('')
                    setSalesOrderDate('')
                    setScheduleQty('')
                    setOrderQuantity('')
                    setProductMaterialId('')
                    setProductBasedPropertiesData([])
                    setProductBasedPropertiesColumns([])
                    setMaterialSortNo('')
                    setProductMaterialStyle('')

                    // if (getProductTypeId !== '' && getProductTypeId !== '0') {
                    //     // Get customer order list
                    //     resetGlobalQuery();
                    //     globalQuery.columns = ['sales_order_no', 'sales_order_date', 'customer_order_no', 'customer_order_Date']
                    //     globalQuery.table = "mtv_sales_order_master_trading_summary"
                    //     globalQuery.conditions.push({ field: "customer_id", operator: "=", value: cmb_customer_id });
                    //     globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    //     globalQuery.conditions.push({ field: "sales_order_status", operator: "=", value: 'A' });
                    //     globalQuery.conditions.push({ field: "sales_order_type_id", operator: "=", value: getProductTypeId });

                    //     const customerOrderList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    //     setCustomerOrderList(customerOrderList);
                    // }
                    break;

                case 'CustomerOrder':
                    const getCustomerOrderId = document.getElementById('cmb_customer_order').value;

                    // Onchange of CustomerOrder set the dependent combo list to empty
                    setMaterialList([])
                    setMaterialStyleList([])
                    setProductMaterialId('')
                    setProductBasedPropertiesData([])
                    setProductBasedPropertiesColumns([])

                    setScheduleQty('')
                    setOrderQuantity('')
                    setMaterialSortNo('')
                    setProductMaterialStyle('')

                    if (getCustomerOrderId !== '') {
                        // Set the sales order no & date
                        const getSalesOrderDetails = customerOrderList.find(item => item.customer_order_no === getCustomerOrderId);
                        if (getSalesOrderDetails) {
                            setSalesOrderNo(getSalesOrderDetails?.sales_order_no)
                        }
                        const getProductTypeId = productTypeComboRef.current.value || ''
                        if (getProductTypeId !== '' && getProductTypeId !== '0') {
                            // Get customer order material list
                            resetGlobalQuery();
                            globalQuery.table = "mt_sales_order_details_trading mt"
                            if (getProductTypeId === 9) {
                                globalQuery.columns = ['sm.product_fg_name as product_material_name', 'sm.product_fg_code as product_material_code',
                                    'sm.product_fg_id as product_material_id', 'mt.material_quantity', 'mt.material_weight', 'mt.material_style',
                                    'mt.material_style_value', 'mt.material_style_abbrevation'
                                ]
                                globalQuery.joins = [

                                    {
                                        "table": `sm_product_fg sm`,
                                        "type": "LEFT",
                                        "on": [
                                            {
                                                "left": "sm.product_fg_id",
                                                "right": "mt.product_material_id",
                                            },
                                            {
                                                "left": "sm.is_delete",
                                                "right": '0'
                                            }
                                        ]
                                    },
                                ];
                            } else {
                                globalQuery.columns = ['sm.product_rm_name as product_material_name', 'sm.product_rm_code as product_material_code',
                                    'sm.product_rm_id as product_material_id', 'mt.material_quantity', 'mt.material_weight', 'mt.material_style',
                                    'mt.material_style_value', 'mt.material_style_abbrevation'
                                ]
                                globalQuery.joins = [
                                    {
                                        "table": `sm_product_rm sm`,
                                        "type": "LEFT",
                                        "on": [
                                            {
                                                "left": "sm.product_rm_id",
                                                "right": "mt.product_material_id",
                                            },
                                            {
                                                "left": "sm.is_delete",
                                                "right": '0'
                                            }
                                        ]
                                    },
                                ];
                            }
                            globalQuery.conditions.push({ field: "mt.is_delete", operator: "=", value: parseInt('0') });
                            globalQuery.conditions.push({ field: "mt.customer_order_no", operator: "=", value: getCustomerOrderId });
                            globalQuery.conditions.push({ field: "mt.company_id", operator: "=", value: COMPANY_ID });

                            const customerMaterialList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                            setMaterialList(customerMaterialList)

                        }



                        // globalQuery.columns = ['sm.product_material_name', 'sm.product_material_code',
                        //     'sm.product_material_id', 'mt.material_quantity', 'mt.material_weight', 'mt.material_style',
                        //     'mt.material_style_value', 'mt.material_style_abbrevation'
                        // ]
                        // globalQuery.table = "mt_sales_order_details_trading mt"
                        // globalQuery.joins = [

                        //     {
                        //         "table": `smv_product_rm_fg_sr sm`,
                        //         "type": "LEFT",
                        //         "on": [
                        //             {
                        //                 "left": "sm.product_material_id",
                        //                 "right": "mt.product_material_id",
                        //             },
                        //             {
                        //                 "left": "sm.is_delete",
                        //                 "right": '0'
                        //             }
                        //         ]
                        //     },



                        // ];
                        // // globalQuery.conditions.push({ field: "mt.is_delete", operator: "=", value: 0 });
                        // globalQuery.conditions.push({ field: "mt.customer_order_no", operator: "=", value: getCustomerOrderId });
                        // globalQuery.conditions.push({ field: "mt.company_id", operator: "=", value: COMPANY_ID });

                        // const customerMaterialList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                        // setMaterialList(customerMaterialList)
                    }

                    break;

                case 'ProductMaterial':
                    const getProductMaterialId = document.getElementById('cmb_product_material_id').value;
                    ;
                    // Onchange of ProductMaterial set the dependent combo list to empty
                    setMaterialStyleList([])
                    setProductMaterialStyle('')

                    if (getProductMaterialId !== '' && parseInt(cmb_product_type_id) !== 13) {
                        // Set styles of material
                        const getMaterialList = materialList.filter(item => item.product_material_id === getProductMaterialId)
                        setMaterialStyleList(getMaterialList);

                        if (getMaterialList.length !== 0) {
                            setMaterialSortNo(getMaterialList[0]?.product_material_code)
                        }


                        await FnGetProductBasedProperties(getMaterialList[0]?.product_material_id);

                    }
                    //  else if (getProductMaterialId !== '' && parseInt(cmb_product_type_id) === 13) {
                    //     const getMaterialOrder = materialList.find(item => item.product_material_id === getProductMaterialId)
                    //     setOrderQuantity(getMaterialOrder.material_quantity)
                    //     await FnGetPreviousPlannedProduction(cmb_customer_order, getMaterialStyle, cmb_product_material_id)
                    // }


                    break;

                case 'ProductMaterialStyle':
                    const getMaterialStyle = document.getElementById('cmb_product_material_style').value;

                    const getMaterialOrder = materialList.find(item => item.product_material_id === cmb_product_material_id
                        && item.material_style_abbrevation === getMaterialStyle)

                    setOrderQuantity(getMaterialOrder.material_quantity)

                    await FnGetPreviousPlannedProduction(cmb_customer_order, getMaterialStyle, cmb_product_material_id)

                    break;
            }
        } catch (error) {
            console.log('error:- ', error);
        }
    }

    const FnGetProductBasedProperties = async (product_material_id) => {
        // debugger
        // Get Product Based properties
        const FnGetProductBasedProperties = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWarpingProductionOrder/FnGetProductBasedProperties/${product_material_id}`)
        const response = await FnGetProductBasedProperties.json()

        if (response.productDynamicPropsList.length !== 0) {
            setProductBasedPropertiesData(response.productDynamicPropsList)
            setProductBasedPropertiesColumns(response.columns)
        }
    }

    const handleReactCheckBoxesForSizedYarn = async (comboType) => {
        debugger
        try {
            switch (comboType) {
                case 'CustomerOrder':
                    // Onchange of CustomerOrder set the dependent combo list to empty
                    setIsLoading(true)
                    setMaterialList([])
                    setMaterialStyleList([])
                    setProductMaterialId('')
                    setProductBasedPropertiesData([])
                    setProductBasedPropertiesColumns([])

                    setScheduleQty('')
                    setOrderQuantity('')
                    setMaterialSortNo('')
                    setProductMaterialStyle('')
                    let customer_order_nos = sizedYarnCustomerOrderRef.current.selectedOpts;
                    if (customer_order_nos.length !== 0) {
                        // Set the sales order no & date
                        const getSalesOrderDetails = customerOrderList.filter(item =>
                            customer_order_nos.includes(item.customer_order_no)
                        );

                        if (getSalesOrderDetails.length > 0) {  // Ensure there's data
                            const salesOrderNos = getSalesOrderDetails.map(so => so.sales_order_no).join(", ");
                            setSalesOrderNo(salesOrderNos);
                        }

                        const getProductTypeId = productTypeComboRef.current.value || ''
                        if (getProductTypeId !== '' && getProductTypeId !== '0') {
                            // Get customer order material list
                            resetGlobalQuery();
                            globalQuery.table = "mt_sales_order_details_trading mt"

                            globalQuery.columns = ['sm.product_rm_name as product_material_name', 'sm.product_rm_code as product_material_code',
                                'sm.product_rm_id as product_material_id', 'mt.material_quantity', 'mt.material_weight', 'mt.material_style',
                                'mt.material_style_value', 'mt.material_style_abbrevation'
                            ]
                            globalQuery.joins = [
                                {
                                    "table": `sm_product_rm sm`,
                                    "type": "LEFT",
                                    "on": [
                                        {
                                            "left": "sm.product_rm_id",
                                            "right": "mt.product_material_id",
                                        },
                                        {
                                            "left": "sm.is_delete",
                                            "right": '0'
                                        }
                                    ]
                                },
                            ];

                            globalQuery.conditions.push({ field: "mt.is_delete", operator: "=", value: parseInt('0') });
                            globalQuery.conditions.push({ field: "mt.customer_order_no", operator: "IN", values: customer_order_nos });
                            globalQuery.conditions.push({ field: "mt.company_id", operator: "=", value: COMPANY_ID });

                            const customerMaterialList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                            if (customerMaterialList.length > 0) {


                                let materiallistArr = (customerMaterialList || []).map((order) => ({
                                    ...order,
                                    value: order.product_material_id,
                                    label: order.product_material_name
                                }));

                                setMaterialList(materiallistArr)
                                return materiallistArr;
                            }
                        }
                    }


                    break;
                case 'materialList':
                    let materialSelected = sizedYarnMaterialRef.current.selectedOpts;
                    let qty = 0;
                    let counts = materialList
                        .filter(material => materialSelected.includes(material['product_material_id']))
                        .map(material => {
                            qty += parseFloat(material['material_quantity']); // Correct way to accumulate the quantity
                            return material['product_material_name']; // Return names
                        });
                    setOrderQuantity(qty)
                    setActualProductionCounts(counts);

                    break;
            }
        } catch (error) {
            console.log('error: ', error);
        } finally {

            setIsLoading(false);
        }
    }

    useEffect(async () => {
        // debugger
        if ((keyForViewUpdate === 'Add' || keyForViewUpdate === 'update' || keyForViewUpdate === 'approve') && parseInt(cmb_product_type_id) === 9) {
            resetGlobalQuery();
            globalQuery.columns = ['sm.product_rm_name AS product_material_name']
            globalQuery.table = "sm_product_dynamic_parameters spdp"
            globalQuery.joins = [
                {
                    "table": `sm_product_rm sm`,
                    "type": "LEFT",
                    "on": [
                        {
                            "left": "sm.product_rm_id",
                            "right": "spdp.product_parameter_value",
                        },
                        {
                            "left": "sm.is_delete",
                            "right": '0'
                        }
                    ]
                },
            ];
            let options = [];
            globalQuery.conditions.push({ field: "spdp.product_id", operator: "=", value: cmb_product_material_id });
            globalQuery.conditions.push({ field: "spdp.product_parameter_name", operator: "IN", values: ['WARP COUNT 1 [WC1]	', 'WARP COUNT 2 [WC2]', 'WARP COUNT 3 [WC3]', 'WARP COUNT 4 [WC4]'] });
            globalQuery.conditions.push({ field: "spdp.is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "spdp.company_id", operator: "=", value: COMPANY_ID });
            let prodCountsAPICall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            if (prodCountsAPICall.length > 0) {

                prodCountsAPICall?.map((count) => options.push(count['product_material_name']))
                setActualProductionCounts(options);
            }
        }


    }, [cmb_product_material_id]);

    // const FnTransferStock = ({ product_type_id, customer_order_no, product_material_id, product_material_name, product_material_code }) => {
    //     // const customerId = cmb_customer_id_ref.current.value;
    //     const data = encodeURIComponent(JSON.stringify({ product_type_id, customer_order_no: cmb_customer_order, customer_id: cmb_customer_id, product_material_id, product_material_name, product_material_code }));
    //     const url = `/salesorder/stocktransfer?data=${data}`;
    //     window.open(url, '_blank');
    // };



    function editCreelDetails(warpingDetails, product_code, product_cones, product_material_name) {
        // debugger
        let requisedMaterials = materialRequisitionDetails;
        let prodCounts = prodCountOptions;

        warpingDetails = warpingDetails.filter(data =>
            data['product_material_code'] !== product_code && data['no_of_cones'] !== product_cones
        );

        requisedMaterials = requisedMaterials.filter(details =>
            details['product_material_code'] !== product_code && details['no_of_cones'] !== product_cones
        );

        const updatedProdCounts = prodCounts.filter((count) => count.product_material_name !== product_material_name);
        setProductionCountOptons(updatedProdCounts);

        setMaterialRequisitionDetails(requisedMaterials);
        setWarpingdata(warpingDetails);
        FnAddMaterialCountWiseCreels(warpingDetails);
    }


    const renderWarpingDetails = useMemo(() => {
        // debugger
        const totalWarpingQuantity = warpingData?.reduce((sum, item) => {
            return sum + parseFloat(item.product_material_quantity || 0);
        }, 0);

        const totalNoOfCones = warpingData?.reduce((sum, item) => {
            return sum + parseFloat(item.no_of_cones || 0);
        }, 0);
        return (
            <Table id="warpingDetailsTblId" className="erp_table" bordered striped hover>
                <thead className="erp_table_head">
                    <tr>
                        <td className={`erp_table_th ${keyForViewUpdate !== 'Add' ? 'd-none' : 'display'} `} style={{ width: "70px" }}>Action</td>
                        <th className="erp_table_th" style={{ width: "100px" }}>Material Code</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Product Material Name</th>
                        {/* <th className="erp_table_th" style={{ width: "100px" }}>Stock Weight</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Stock Quantity</th> */}
                        <th className="erp_table_th" style={{ width: "100px" }}>Count</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Weight Per Cone</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>No Of Cones</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Warping Quantity(Kg)</th>
                    </tr>
                </thead>

                <tbody>
                    {warpingData.length > 0 ? (
                        warpingData.map((warping, indexOfItem) => (
                            <tr rowindex={indexOfItem}>
                                {<td className={`erp_table_td ${keyForViewUpdate !== 'Add' ? 'd-none' : 'display'} `}>
                                    <MdDelete className="erp-delete-btn" onClick={(e) => editCreelDetails(warpingData, warping.product_material_code, warping.no_of_cones, warping.product_material_name)} />
                                </td>}
                                <td className="erp_table_td" >
                                    {warping.product_material_code}
                                </td>
                                <td className="erp_table_td" >
                                    {warping.product_material_name}
                                </td>
                                {/* <td className="erp_table_td" id={`closing_balance_weight_${indexOfItem}`}>
                                    {warping.closing_balance_weight}
                                </td>

                                <td className="erp_table_td" id={`closing_balance_quantity_${indexOfItem}`}>
                                    {warping.closing_balance_quantity}
                                </td> */}

                                <td className="erp_table_td" >
                                    {warping.production_count}
                                </td>


                                <td className="erp_table_td" >
                                    {/* {warping.cone_per_wt} */}
                                    <input
                                        type="text" disabled
                                        style={{ marginRight: '3px' }} // Added marginRight for spacing
                                        className="erp_input_field_table_txt text-end mb-1 disable"
                                        id={`cone_per_wt_${indexOfItem}`}
                                        value={warping.cone_per_wt}
                                        onInput={(e) => calculateWarpingDetailTblRow(e, warping)}
                                        Headers='cone_per_wt'
                                    />
                                </td>

                                <td className="erp_table_td" >
                                    {/* {warping.no_of_cones} */}
                                    <input
                                        type="text" disabled
                                        style={{ width: '97px', marginRight: '3px' }} // Added marginRight for spacing
                                        className="erp_input_field_table_txt text-end mb-1 disable"
                                        id={`no_of_cones_${indexOfItem}`}
                                        value={warping.no_of_cones}
                                        onInput={(e) => calculateWarpingDetailTblRow(e, warping)}
                                        Headers='no_of_cones'
                                    />
                                </td>

                                <td className="erp_table_td">
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="text" disabled
                                            style={{ width: '97px', marginRight: '3px' }} // Added marginRight for spacing
                                            className="erp_input_field_table_txt text-end mb-1 disable"
                                            id={`product_material_quantity_${indexOfItem}`}
                                            value={warping.product_material_quantity}
                                            onInput={(e) => calculateWarpingDetailTblRow(e, warping)}
                                            Headers='product_material_quantity'
                                        />
                                        {/* <AiFillEye className="erp-view-btn mb-1" id={`viewStockDetailsId_${indexOfItem}`} onClick={e => FnGetStockDetails(warping.product_material_id)} /> */}
                                    </div>
                                </td>
                            </tr>
                        )
                        )

                    ) : (
                        <tr>
                            <td className="erp_table_td text-center" colSpan={7} >No Records Found..!</td>
                        </tr>
                    )}
                    <>{warpingData.length > 0 &&

                        <tr className={`erp_table_head ${warpingData.length > 0 ? 'display' : 'd-none'}`}>
                            <td className="erp_table_th text-center" colSpan={keyForViewUpdate !== 'Add' ? 4 : 5} >Totals</td>
                            <td className="erp_table_th text-center" >{validateNumberDateInput.current.decimalNumber(totalNoOfCones, 2)}</td>
                            <td className="erp_table_th text-center" >{validateNumberDateInput.current.decimalNumber(totalWarpingQuantity, 3)}</td>
                        </tr>
                    }

                    </>
                </tbody>
            </Table>

        );
    }, [warpingData, individualStockDetails, finalStockDetailsArr]);


    // Function For Add & Remove row for Creel Data
    const editCreelData = (operation, creelData, currentCreelDataRow, index) => {
        debugger;
        let creelDataEmptyArr = [];

        switch (operation) {
            case 'remove':
                creelDataEmptyArr = creelData
                    .filter((_, i) => i !== index) // Remove the item at the given index
                    .map((data, newIndex) => ({ ...data, creel_no: `Creel ${newIndex + 1}` })); // Reassign creel_no

                setCreelData(creelDataEmptyArr);
                break;

            case 'add':
                let newRow = {
                    ...currentCreelDataRow,
                    creel_no: `Creel ${creelData.length + 1}`,
                    no_of_cones: 0,
                    creel_length: 0,
                    no_of_beams: 0,
                    set_length: 0,
                    creel_weight: 0,
                    actual_net_wt: 0,
                    calculated_bottom: 0,
                    warping_production_order_creels_id: 0
                };
                creelDataEmptyArr = [...creelData, newRow];
                setCreelData(creelDataEmptyArr);
                break;

            default:
                break;
        }
        setCreeels(creelDataEmptyArr.length)
    };

    const renderTEnds = useMemo(() => {
        setBottomValue(creelData?.reduce((acc, dt) => acc + parseFloat(dt['creel_bottom']), 0))
        setTEnds(creelData?.reduce((acc, dt) => acc + dt['per_creel_ends'], 0));
        return 0;
    }, [creelData]);


    const renderCreelDetails = useMemo(() => {
        // debugger
        console.log("prodCountOptions -" + prodCountOptions);
        // Calculate totals for each column
        const totals = creelData?.reduce((acc, creelDetail) => {
            acc.conePerCreel += parseFloat(creelDetail.no_of_cones || 0);
            acc.creelEnds += parseFloat(creelDetail.per_creel_ends || 0);
            acc.creelBottom += parseFloat(creelDetail.creel_bottom || 0);
            acc.creelLength += parseFloat(creelDetail.creel_length || 0);
            acc.beamPerCreel += parseFloat(creelDetail.no_of_beams || 0);
            acc.creelWeight += parseFloat(creelDetail.creel_weight || 0);
            acc.setLength += parseFloat(creelDetail.set_length || 0);
            acc.actualNetWt += parseFloat(creelDetail.actual_net_wt || 0);
            acc.calculatedBottom += parseFloat(creelDetail.calculated_bottom || 0);
            return acc;
        }, {
            conePerCreel: 0,
            creelEnds: 0,
            creelBottom: 0,
            creelLength: 0,
            beamPerCreel: 0,
            creelWeight: 0,
            setLength: 0,
            actualNetWt: 0,
            calculatedBottom: 0
        });

        return (
            <Table id="creelDetailsTblId" className="erp_table" bordered striped hover>
                <thead className="erp_table_head">
                    <tr>
                        <td className={`erp_table_th ${keyForViewUpdate === 'Add' || keyForViewUpdate === 'update' ? 'display' : 'd-none'} `} style={{ width: "70px" }}>Action</td>
                        <th className="erp_table_th" style={{ width: "100px" }}>Creel No.</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Count</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Beam Ends</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Creel Bottom</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Cone/Creel</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Creel Length</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Beam/Creel</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Weight/Package</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Creel Weight</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Set Length</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Actual Net Wt.</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Calculated Bottom</th>
                    </tr>
                </thead>

                <tbody>
                    {creelData.length > 0 ? (
                        <>
                            {creelData.map((creelDetail, indexOfItem) => (
                                <tr key={indexOfItem} rowindex={indexOfItem}>
                                    {<td className={`erp_table_td ${keyForViewUpdate === 'Add' || keyForViewUpdate === 'update' ? 'display' : 'd-none'} `}>
                                        <IoAddCircleOutline className={`erp_trAdd_icon`} onClick={() => editCreelData('add', creelData, creelDetail, indexOfItem)} />
                                        <MdDelete className="erp-delete-btn" onClick={(e) => editCreelData('remove', creelData, creelDetail, indexOfItem)} />
                                    </td>}
                                    <td className="erp_table_td" >
                                        {creelDetail.creel_no}
                                    </td>
                                    <td className="erp_table_td">
                                        <select
                                            id={`product_material_id_${indexOfItem}`}
                                            style={{ width: "150px", backgroundColor: '#AFE1AF' }}
                                            className="erp_input_field_table_txt mb-1"
                                            value={creelDetail.product_material_id}
                                            onChange={(e) => FnCalculateCreelDetails(e, creelDetail, indexOfItem)}
                                            headers="product_material_id"
                                            disabled={prodCountOptions.length <= 1 || ['view', 'approve'].includes(keyForViewUpdate)}
                                        >
                                            {prodCountOptions?.map((prodCount, idx) => (
                                                <option
                                                    key={idx}
                                                    value={prodCount.product_material_id}
                                                    style={{ backgroundColor: 'white', width: "150px" }}
                                                    data-product-material-name={prodCount.product_material_name}
                                                    data-product-count={prodCount.actual_count}
                                                >
                                                    {prodCount.product_material_name}{` - (${parseInt(prodCount.actual_count)})`}
                                                </option>
                                            ))}
                                        </select>

                                    </td>

                                    <td className="erp_table_td">
                                        {keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' ? (
                                            <input
                                                type="text"
                                                style={{ width: '85px', backgroundColor: '#AFE1AF' }}
                                                className="erp_input_field_table_txt text-end mb-1"
                                                id={`per_creel_ends_${indexOfItem}`}
                                                value={creelDetail.per_creel_ends}
                                                onInput={(e) => FnCalculateCreelDetails(e, creelDetail, indexOfItem)}
                                                Headers="per_creel_ends"
                                            />
                                        ) : (
                                            <span className='text-end'>{creelDetail.per_creel_ends}</span>
                                        )}
                                    </td>


                                    <td className="erp_table_td">
                                        {keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' ? (
                                            <input
                                                type="text"
                                                style={{ width: '85px', backgroundColor: '#AFE1AF' }}
                                                className="erp_input_field_table_txt text-end mb-1"
                                                id={`creel_bottom_${indexOfItem}`}
                                                value={creelDetail.creel_bottom}
                                                onInput={(e) => FnCalculateCreelDetails(e, creelDetail, indexOfItem)}
                                                Headers="creel_bottom"
                                            />
                                        ) : (
                                            <span className='text-end'>{creelDetail.creel_bottom}</span>
                                        )}
                                    </td>

                                    <td className="erp_table_td" >
                                        {keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' ? (
                                            <input
                                                type="text"
                                                style={{ width: '85px', backgroundColor: '#AFE1AF' }}
                                                className="erp_input_field_table_txt text-end mb-1"
                                                id={`no_of_cones_${indexOfItem}`}
                                                value={creelDetail.no_of_cones}
                                                onInput={(e) => FnCalculateCreelDetails(e, creelDetail, indexOfItem)}
                                                Headers="no_of_cones"
                                            />
                                        ) : (
                                            <span className='text-end'>{creelDetail.no_of_cones}</span>
                                        )}
                                    </td>

                                    <td className="erp_table_td" >
                                        {keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' ? (
                                            <input
                                                type="text"
                                                style={{ width: '85px', backgroundColor: '#AFE1AF' }}
                                                className="erp_input_field_table_txt text-end mb-1"
                                                id={`creel_length_${indexOfItem}`}
                                                value={creelDetail.creel_length}
                                                onInput={(e) => FnCalculateCreelDetails(e, creelDetail, indexOfItem)}
                                                Headers="creel_length"
                                            />
                                        ) : (
                                            <span className='text-end'>{creelDetail.creel_length}</span>
                                        )}
                                    </td>

                                    <td className="erp_table_td" >
                                        {keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' ? (
                                            <input
                                                type="text"
                                                style={{ width: '85px', backgroundColor: '#AFE1AF' }}
                                                className="erp_input_field_table_txt text-end mb-1"
                                                id={`no_of_beams_${indexOfItem}`}
                                                value={creelDetail.no_of_beams}
                                                onInput={(e) => FnCalculateCreelDetails(e, creelDetail, indexOfItem)}
                                                Headers="no_of_beams"
                                            />
                                        ) : (
                                            <span className='text-end'>{creelDetail.no_of_beams}</span>
                                        )}
                                    </td>

                                    <td className="erp_table_td text-end" >
                                        {creelDetail.cone_per_wt}
                                    </td>

                                    <td className="erp_table_td" >
                                        {keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' ? (
                                            <input
                                                type="text"
                                                style={{ width: '85px', backgroundColor: '#AFE1AF' }}
                                                className="erp_input_field_table_txt text-end mb-1 disable"
                                                id={`creel_weight_${indexOfItem}`}
                                                value={creelDetail.creel_weight}
                                                onInput={(e) => FnCalculateCreelDetails(e, creelDetail, indexOfItem)}
                                                Headers='creel_weight'
                                            />
                                        ) : (
                                            <span className='text-end'>{creelDetail.creel_weight}</span>
                                        )}
                                    </td>

                                    <td className="erp_table_td" >
                                        {keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' ? (
                                            <input
                                                type="text"
                                                style={{ width: '85px', backgroundColor: '#AFE1AF' }}
                                                className="erp_input_field_table_txt text-end mb-1 disable"
                                                id={`set_length_${indexOfItem}`}
                                                value={creelDetail.set_length}
                                                onInput={(e) => FnCalculateCreelDetails(e, creelDetail, indexOfItem)}
                                                Headers='set_length'
                                            />
                                        ) : (
                                            <span className='text-end'>{creelDetail.set_length}</span>
                                        )}
                                    </td>

                                    <td className="erp_table_td text-end" >
                                        {creelDetail.actual_net_wt}
                                    </td>
                                    <td className="erp_table_td text-end" >
                                        {creelDetail.calculated_bottom}
                                    </td>
                                </tr>
                            ))}
                            {/* Total row */}
                            <tr>
                                <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={keyForViewUpdate === 'Add' || keyForViewUpdate === 'update' ? 3 : 2}>Totals</td>
                                <td className="erp_table_td cones text-end" style={{ fontWeight: 'bold' }}>{validateNumberDateInput.current.decimalNumber(totals.creelEnds, 2)}</td>
                                <td className="erp_table_td text-end" style={{ fontWeight: 'bold' }}>{validateNumberDateInput.current.decimalNumber(totals.creelBottom, 2)}</td>
                                <td className="erp_table_td cones text-end" style={{ fontWeight: 'bold' }}>{validateNumberDateInput.current.decimalNumber(totals.conePerCreel, 2)}</td>
                                <td className="erp_table_td text-end" style={{ fontWeight: 'bold' }}>{validateNumberDateInput.current.decimalNumber(totals.creelLength, 2)}</td>
                                <td className="erp_table_td text-end" style={{ fontWeight: 'bold' }}>{validateNumberDateInput.current.decimalNumber(totals.beamPerCreel, 2)}</td>
                                <td className="erp_table_td"></td>
                                <td className="erp_table_td creelweight text-end" style={{ fontWeight: 'bold' }}>{validateNumberDateInput.current.decimalNumber(totals.creelWeight, 2)}</td>
                                <td className="erp_table_td text-end" style={{ fontWeight: 'bold' }}>
                                    {validateNumberDateInput.current?.decimalNumber((creelData.reduce((acc, data) => acc + parseFloat(data['set_length']), 0)) / creelData.length, 4)}

                                </td>
                                <td className="erp_table_td text-end" style={{ fontWeight: 'bold' }}>{validateNumberDateInput.current.decimalNumber(totals.actualNetWt, 2)}</td>
                                <td className="erp_table_td text-end" style={{ fontWeight: 'bold' }}>{validateNumberDateInput.current.decimalNumber(totals.calculatedBottom, 2)}</td>
                            </tr>
                        </>
                    ) : (
                        <tr>
                            <td className="erp_table_td text-center" colSpan={10}>No Records Found..!</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        );
    }, [warpingData, creelData, prodCountOptions]);



    const FnCalculateCreelLength = async (rowdata, arrayIndex) => {
        let actualCount = rowdata['production_count'];

        // resetGlobalQuery();
        // globalQuery.columns = ['product_parameter_value'];
        // globalQuery.table = "sm_product_dynamic_parameters";
        // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        // globalQuery.conditions.push({ field: "product_id", operator: "=", value: findWarpingDetails.product_material_id });
        // globalQuery.conditions.push({ field: "product_parameter_name", operator: "IN", values: ['Constant', 'CONSTANT', 'constant'] });
        // let getApiCallSForMaterialConstant = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);

        rowdata['creel_length'] = validateNumberDateInput.current.decimalNumber(parseFloat(rowdata.cone_per_wt) * parseFloat(actualCount) * parseFloat(productionConstant), 2);
    }

    const FnCalculateCreelDetails = async (event, rowdata, arrayIndex) => {
        debugger
        try {
            let creelDetails = [...creelData];
            let warpingDetails = warpingData;
            let clickedColName = event.target.getAttribute('Headers');
            let eventId = document.getElementById(event.target.id);
            let enteredValue = event.target.value;

            if (txt_creels === '' || txt_creels === undefined) {
                $("#error_txt_creels").show();
                $("#txt_creels").focus();
                $("#error_txt_creels").text("Please fill this field...!");
                return false;
            }

            if (enteredValue) {
                eventId.parentElement.removeAttribute('data-tip');
            }

            rowdata['creel_length'] = validateNumberDateInput.current.decimalNumber(
                parseFloat(rowdata['cone_per_wt']) * parseFloat(rowdata['production_count']) * productionConstant
            );
            switch (clickedColName) {

                case 'product_material_id':
                    let count_Name = $('#product_material_id_' + arrayIndex + ' option:selected').data('product-material-name');
                    let findWarpingDetails = warpingDetails.find((detail) => {
                        return detail.production_count_name === count_Name;
                    });
                    if (findWarpingDetails) {
                        rowdata[clickedColName] = enteredValue
                        rowdata['no_of_cones'] = findWarpingDetails.no_of_cones;
                        rowdata['cone_per_wt'] = findWarpingDetails.cone_per_wt;
                        rowdata['production_count_name'] = count_Name;
                        rowdata['production_count'] = findWarpingDetails.production_count;
                        await FnCalculateCreelLength(rowdata, arrayIndex)
                    } else {
                        rowdata[clickedColName] = enteredValue
                        rowdata['no_of_cones'] = 0;
                        rowdata['creel_length'] = 0;
                        rowdata['cone_per_wt'] = 0;
                    }

                    break;
                case 'creel_bottom':
                    rowdata['creel_bottom'] = validateNumberDateInput.current.decimalNumber(enteredValue, 3);
                    await FnCalculateCreelLength(rowdata, arrayIndex)
                    FnCalculateCreelFormulas(rowdata, clickedColName);
                    break;

                case 'per_creel_ends':
                    rowdata[clickedColName] = validateNumberDateInput.current.decimalNumber(enteredValue, 3);
                    break;

                case 'no_of_beams':
                    rowdata[clickedColName] = enteredValue;
                    await FnCalculateCreelLength(rowdata, arrayIndex)
                    FnCalculateCreelFormulas(rowdata, clickedColName);
                    break;
                case 'creel_weight':
                    rowdata[clickedColName] = enteredValue;
                    FnCalculateCreelFormulas(rowdata, clickedColName);
                    break;
                case 'creel_length':
                    rowdata[clickedColName] = enteredValue;
                    FnCalculateCreelFormulas(rowdata, clickedColName);
                    break;
                case 'no_of_cones':
                    rowdata[clickedColName] = validateNumberDateInput.current.decimalNumber(enteredValue, 2);
                    await FnCalculateCreelLength(rowdata, arrayIndex)
                    FnCalculateCreelFormulas(rowdata, clickedColName);
                    break;
                default:
                    break;

            }
            $('.creelweight').removeAttr('data-tip');
            $('.cones').removeAttr('data-tip');

            FnCalculateSetLength(creelDetails) // calculate set length
            creelDetails[arrayIndex] = rowdata;
            setCreelData(creelDetails)
        } catch (error) {
            console.log('error: ', error);
        }
    }


    const FnCalculateCreelFormulas = (element, clickedColName) => {
        // debugger
        let bottom_value = creelData.length > 1 ? (element['creel_bottom']) : bottomValue;
        const safeDecimalNumber = (value) => {
            const number = validateNumberDateInput.current.decimalNumber(value);
            return (isNaN(number) || !isFinite(number)) ? 0 : number;
        };

        switch (clickedColName) {
            case 'creel_weight':
                element.calculated_bottom = safeDecimalNumber(element.creel_weight - element.actual_net_wt);
                break;
            case 'no_of_cones':
                element.creel_weight = safeDecimalNumber(element.cone_per_wt * element.no_of_cones);
                element.actual_net_wt = safeDecimalNumber(((element.creel_length / productionConstant) / element.production_count) * element.no_of_cones);
                element.calculated_bottom = safeDecimalNumber(element.creel_weight - element.actual_net_wt);
                break;
            case 'no_of_beams':
                element.set_length = safeDecimalNumber((element.creel_length - parseFloat(bottom_value)) / element.no_of_beams);
                break;
            case 'creel_bottom':
            case 'creel_length':
                element.set_length = safeDecimalNumber((element.creel_length - parseFloat(bottom_value)) / element.no_of_beams);
                element.actual_net_wt = safeDecimalNumber(((element.creel_length / productionConstant) / element.production_count) * element.no_of_cones);
                element.calculated_bottom = safeDecimalNumber(element.creel_weight - element.actual_net_wt);
                break;
            default:
                break;
        }
    };

    // const renderOtherStockDetailsTable = useMemo(() => {
    //     return (
    //         <Table
    //             id="stockDetailsTblId"
    //             className="erp_table erp_table_scroll"
    //             style={{ overflow: "auto", height: materialRequisitionAgainstOther.length <= 5 ? 'auto' : '225px' }}
    //             bordered
    //             striped
    //             hover
    //         >
    //             <thead className="erp_table_head">
    //                 <tr>
    //                     <th className="erp_table_th" style={{ width: "100px" }}>Action</th>
    //                     <th className="erp_table_th" style={{ width: "100px" }}>Material Name</th>
    //                     <th className="erp_table_th" style={{ width: "100px" }}>Material Code</th>
    //                     <th className="erp_table_th" style={{ width: "100px" }}>Count</th>
    //                     <th className="erp_table_th" style={{ width: "100px" }}>Customer Name</th>
    //                     <th className="erp_table_th" style={{ width: "100px" }}>Customer Order no.</th>
    //                     <th className="erp_table_th" style={{ width: "100px" }}>Supplier Name</th>
    //                     <th className="erp_table_th" style={{ width: "100px" }}>Lot No</th>
    //                     <th className="erp_table_th" style={{ width: "100px" }}>Remark</th>
    //                     <th className="erp_table_th" style={{ width: "100px" }}>Stock Wt.</th>
    //                     <th className="erp_table_th" style={{ width: "100px" }}>Stock Qty.</th>
    //                     <th className="erp_table_th" style={{ width: "100px" }}>Weight Per Cone</th>
    //                     <th className="erp_table_th" style={{ width: "100px" }}>Goods Receipt No</th>
    //                     <th className="erp_table_th" style={{ width: "100px" }}>Customer Receipt No</th>
    //                     <th className="erp_table_th" style={{ width: "100px" }}>Indent No</th>
    //                     <th className="erp_table_th" style={{ width: "100px" }}>Packing Name</th>
    //                     {/* <th className="erp_table_th" style={{ width: "100px" }}>Count Type</th> */}
    //                 </tr>
    //             </thead>

    //             <tbody>
    //                 {materialRequisitionAgainstOther.length > 0 ? (
    //                     materialRequisitionAgainstOther.map((stockDetail, indexOfItem) => (
    //                         <tr key={indexOfItem}>
    //                             <td className="erp_table_td"><FaMoneyBillTransfer size={20} color="#007BFF" onClick={() => FnTransferStock(stockDetail)} /></td>
    //                             <td className="erp_table_td">{stockDetail.product_material_name}</td>
    //                             <td className="erp_table_td">{stockDetail.product_material_code}</td>
    //                             <td className="erp_table_td">{stockDetail.production_count_name}</td>
    //                             <td className="erp_table_td">{stockDetail.customer_name}</td>
    //                             <td className="erp_table_td">{stockDetail.customer_order_no}</td>
    //                             <td className="erp_table_td">{stockDetail.supplier_name}</td>
    //                             <td className="erp_table_td">{stockDetail.batch_no} </td>
    //                             <td className="erp_table_td">{stockDetail.remark} </td>
    //                             <td className="erp_table_td">{stockDetail.closing_balance_weight}</td>
    //                             <td className="erp_table_td">{stockDetail.closing_balance_quantity}</td>
    //                             <td className="erp_table_td">{stockDetail.cone_per_wt}</td>

    //                             <td className="erp_table_td">{stockDetail.goods_receipt_no}</td>
    //                             <td className="erp_table_td">{stockDetail.customer_goods_receipt_no}</td>
    //                             <td className="erp_table_td">{stockDetail.indent_no}</td>
    //                             <td className="erp_table_td">{stockDetail.product_packing_name}</td>
    //                             {/* <td className="erp_table_td" id={`count_type_${indexOfItem}`}>
    //                                 {stockDetail.count_type}
    //                             </td> */}
    //                         </tr>
    //                     ))
    //                 ) : (
    //                     <tr>
    //                         <td className="erp_table_td text-center" colSpan={14}>No Records Found..!</td>
    //                     </tr>
    //                 )}
    //             </tbody>
    //         </Table>
    //     );
    // }, [materialRequisitionAgainstOther]);

    const FnCheckSetNoExists = async () => {
        try {
            // debugger
            if (keyForViewUpdate === 'approve' || keyForViewUpdate === 'update') { return false }
            setIsLoading(true);
            let set_no = $("#txt_set_no").val();
            const checkSetNoApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWarpingProductionOrder/FnCheckSetNoExists/${set_no}/${COMPANY_ID}`)
            const response = await checkSetNoApiCall.json(); // Directly get boolean        
            if (response === false) {
                $("#error_txt_set_no").hide();
                $("#save_btn").attr('disabled', false);
            } else {
                setErrMsg(`${set_no} - Set No Already Exists...!`)
                setShowErrorMsgModal(true)
                $("#save_btn").attr('disabled', true);
            }
            return response;
        } catch (error) {

        } finally {
            setIsLoading(false)
        }
    }




    // For multiselect sized yarn options 
    const Option = (props) => {
        // debugger
        return (
            <components.Option {...props}>
                <input
                    className="p-1"
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                    style={{ marginRight: "8px" }} // Adds space between checkbox & label
                />
                <label style={{ fontSize: "12px" }}>{props.label}</label>
            </components.Option>
        );
    };


    return (
        <DashboardLayout>
            <ComboBox ref={comboDataAPiCall} />
            <GenerateMaterialId ref={generateAutoNoAPiCall} />
            <GenerateTAutoNo ref={generateAutoNoAPiCalls} />

            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <FrmValidations ref={validate} />
            <ReactToPrint
                content={() => printRef.current}
            />

            {isLoading ?
                <div className="spinner-overlay"  >
                    <div className="spinner-container">
                        <CircularProgress color="primary" />
                        <span>Loading...</span>
                    </div>
                </div> :
                ''}

            <div className='card p-1'>
                <div className='card-header text-center py-0'>
                    <label className='erp-form-label-lg text-center'>Warping Production Plan {actionType}</label>
                </div>
                <input type="hidden" id="productionConstant" name="hiddenFieldName" value={productionConstant} />
                <form id='warpingOrderFormId'>
                    <div className="row ms-1 mt-3">
                        <div className="col-sm-4 erp_form_col_div ">

                            <div className="row">
                                <div className="col-sm-5">
                                    <Form.Label className="erp-form-label"> Plan Date <span className="required">*</span> </Form.Label>
                                </div>
                                <div className="col-sm-7">
                                    <Form.Control type="date" id="dt_warping_plan_date" className="erp_input_field" value={dt_warping_plan_date} onChange={e => { setWarpingPlanDate(e.target.value); }} disabled />
                                    <MDTypography variant="button" id="error_dt_warping_plan_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-5">
                                    <Form.Label className="erp-form-label"> Schedule Date <span className="required">*</span> </Form.Label>
                                </div>
                                <div className="col-sm-7">
                                    <Form.Control type="date" id="dt_schedule_date" className="erp_input_field" value={dt_schedule_date} onChange={e => { setScheduleDate(e.target.value); FnGenerateWarpingPlanId(e.target.value) }} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'update'} />
                                    <MDTypography variant="button" id="error_dt_schedule_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-5">
                                    <Form.Label className="erp-form-label">Warping Order No  </Form.Label>
                                </div>
                                <div className="col-sm-7">
                                    <Form.Control type="text" id='txt_warping_order_no' className="erp_input_field" value={txt_warping_order_no} disabled />
                                    <MDTypography variant="button" id="error_txt_warping_order_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>


                            <div className='row'>
                                <div className="col-sm-5 col-12">
                                    <Form.Label className="erp-form-label">Sort & Set No <span className="required">*</span> </Form.Label>
                                </div>
                                <div className="col-sm-7 col-12">
                                    <div className="row">
                                        <div className="col-12 col-md-6 pe-md-0">
                                            <Form.Control type="text" id='txt_sort_no' className="erp_input_field" value={txt_sort_no} disabled optional={parseInt(cmb_product_type_id) === 13 ? 'optional' : ''} />
                                            <MDTypography variant="button" id="error_txt_sort_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className="col-12 col-md-6 pe-md-0 pt-md-0 pt-3 ps-md-1">
                                            <Form.Control type="text" id="txt_set_no" className="erp_input_field" value={txt_set_no} onChange={(e) => setSetNo(e.target.value)} onBlur={() => FnCheckSetNoExists()} disabled={keyForViewUpdate === "update" || keyForViewUpdate === "approve"} />
                                            <MDTypography variant="button" id="error_txt_set_no" ref={error_txt_set_no} className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-5">
                                    <Form.Label className="erp-form-label">Product Type</Form.Label>
                                </div>
                                <div className="col-sm-7">
                                    <Select
                                        ref={productTypeComboRef}
                                        inputId="cmb_product_type_id"
                                        value={productTypeOptions.find(option => option.value === cmb_product_type_id) || null}
                                        options={productTypeOptions}
                                        isDisabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'update'}
                                        onChange={(selectedOption) => {
                                            setProduct_type_id(selectedOption.value)
                                            productTypeComboRef.current = selectedOption;
                                            FnComboBoxesOnChange('ProductType');
                                        }
                                        }
                                        placeholder="Search for Product Type..."
                                        className="form-search-custom"
                                        classNamePrefix="custom-select"

                                        styles={{
                                            option: (provided, state) => ({
                                                ...provided,
                                                fontSize: '12px'
                                            }),
                                            singleValue: (provided, state) => ({
                                                ...provided,
                                                fontSize: '12px'
                                            }),
                                            input: (provided, state) => ({
                                                ...provided,
                                                fontSize: '12px'
                                            })
                                        }}
                                    />

                                    <MDTypography variant="button" id="error_cmb_product_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>

                            <div className="row">
                                <div className='col-sm-5 col-12'>
                                    <Form.Label className="erp-form-label">Customer <span className="required">*</span> </Form.Label>
                                </div>
                                <div className='col-sm-6 col-10'>
                                    <Select
                                        ref={cmb_customer_id_ref}
                                        options={customerList}
                                        inputId="cmb_customer_id" // Provide the ID for the input box
                                        value={customerList.find(option => option.value === cmb_customer_id)}
                                        onChange={(selectedOpt) => {
                                            cmb_customer_id_ref.current = selectedOpt;
                                            FnComboBoxesOnChange("Customer");
                                        }}
                                        isDisabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'update'}
                                        placeholder="Search for a customer..."
                                        className="form-search-custom"
                                        classNamePrefix="custom-select" // Add custom prefix for class names
                                        // disabled={['view', 'approve'].includes(keyForViewUpdate)}
                                        styles={{
                                            option: (provided, state) => ({
                                                ...provided,
                                                fontSize: '12px' // Adjust the font size as per your requirement
                                            }),
                                            singleValue: (provided, state) => ({
                                                ...provided,
                                                fontSize: '12px' // Adjust the font size as per your requirement
                                            }),
                                            input: (provided, state) => ({
                                                ...provided,
                                                fontSize: '12px' // Adjust the font size as per your requirement
                                            })
                                        }}
                                    />

                                    <MDTypography variant="button" id="error_cmb_customer_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                                {
                                    keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'update' ?
                                        <div className='col-sm-1 col-2 refreshIconCol'>
                                            <Tooltip title="Refresh" placement="right">
                                                <MdRefresh style={{ color: 'black' }} onClick={e => { FnUpdateComboBoxData(); }} />
                                            </Tooltip>
                                        </div>
                                        : null
                                }

                            </div>

                            <div className='row'>
                                <div className="col-sm-5">
                                    <Form.Label className="erp-form-label">Customer Order <span className="required">*</span></Form.Label>
                                </div>
                                <div className="col-sm-7">
                                    {parseInt(cmb_product_type_id) === 13 ? <>
                                        <ReactSelect
                                            ref={sizedYarnCustomerOrderRef}
                                            options={customerOrderList}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            components={{ Option }}
                                            isDisabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'}
                                            onChange={(selected) => {
                                                debugger
                                                setCustomerOrder(selected ? selected.map(option => option.value) : []);
                                                const values = selected.map(option => option.value);
                                                sizedYarnCustomerOrderRef.current.selectedOpts = values;
                                            }}
                                            onBlur={() => handleReactCheckBoxesForSizedYarn('CustomerOrder')}
                                            value={customerOrderList.filter(option => cmb_customer_order.includes(option.value))}
                                            placeholder="Search for Customer Order Number..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '14px'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '11px'
                                                }),
                                                multiValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '11px'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                })
                                            }}
                                        />

                                    </> :

                                        <>
                                            {keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'update' ?
                                                <select id="cmb_customer_order" className="form-select form-select-sm" value={cmb_customer_order} onChange={e => { setCustomerOrder(e.target.value); FnComboBoxesOnChange("CustomerOrder"); validateFields(); }} >
                                                    <option value="" disabled>Select</option>
                                                    {customerOrderList?.map(customerOrder => (
                                                        <option value={customerOrder.customer_order_no} >{customerOrder.customer_order_no}</option>
                                                    ))}
                                                </select>
                                                : <Form.Label className="erp-form-label">{cmb_customer_order}</Form.Label>
                                            }
                                        </>
                                    }

                                    <MDTypography variant="button" id="error_cmb_customer_order" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-4 erp_form_col_div ">

                            <div className='row'>
                                <div className="col-sm-5 col-12">
                                    <Form.Label className="erp-form-label">SO No. & Date <span className="required">*</span> </Form.Label>
                                </div>
                                <div className="col-sm-7 col-12">
                                    <div className="row">
                                        <div className="col-12">
                                            <Form.Control
                                                as="textarea"
                                                id="txt_sales_order_no"
                                                className="erp_input_field"
                                                value={txt_sales_order_no}
                                                disabled
                                                optional="optional"
                                                rows={2}
                                            />


                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-sm-5">
                                    <Form.Label className="erp-form-label">Product<span className="required">*</span></Form.Label>
                                </div>
                                <div className="col-sm-7">

                                    {parseInt(cmb_product_type_id) === 13 ? <>
                                        <ReactSelect
                                            ref={sizedYarnMaterialRef}
                                            options={materialList}
                                            isMulti
                                            isDisabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            components={{ Option }}
                                            onChange={(selected) => {
                                                setProductMaterialId(selected.map(option => option.value))
                                                sizedYarnMaterialRef.current.selectedOpts = selected.map(option => option.value)
                                            }}
                                            onBlur={() => handleReactCheckBoxesForSizedYarn('materialList')}
                                            value={materialList.filter(option => cmb_product_material_id.includes(option.value))}
                                            placeholder="Search for Product..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '14px'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '11px' // Adjust this for smaller label text
                                                }),
                                                multiValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '11px' // Adjust this for smaller multi-selected labels
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                })
                                            }}
                                        />

                                    </> :

                                        <>
                                            {keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'update' ?
                                                <select id="cmb_product_material_id" className="form-select form-select-sm" value={cmb_product_material_id} onChange={e => { setProductMaterialId(e.target.value); FnComboBoxesOnChange("ProductMaterial"); validateFields(); }} >
                                                    <option value="" disabled>Select</option>
                                                    {materialList?.map(material => (
                                                        <option value={material.product_material_id} >{material.product_material_name}</option>
                                                    ))}
                                                </select> :
                                                <Form.Label className="erp-form-label">  {txt_product_material_name}</Form.Label>
                                            }
                                        </>
                                    }

                                    <MDTypography variant="button" id="error_cmb_product_material_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-sm-5">
                                    <Form.Label className="erp-form-label">Material Style {parseInt(cmb_product_type_id) === 9 ? null : <span className="required">*</span>}</Form.Label>
                                </div>
                                <div className="col-sm-7">
                                    {keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'update' ?
                                        <select id="cmb_product_material_style" className="form-select form-select-sm" value={cmb_product_material_style} onChange={e => { setProductMaterialStyle(e.target.value); FnComboBoxesOnChange("ProductMaterialStyle"); validateFields(); }} optional={parseInt(cmb_product_type_id) === 13 ? "optional" : ''}>
                                            <option value="" disabled>Select</option>
                                            {materialStyleList?.map(style => (
                                                <option value={style.material_style_abbrevation} >{`${style.material_style_value} [${style.material_style}]`}</option>
                                            ))}
                                        </select>
                                        : <Form.Label className="erp-form-label">   {cmb_product_material_style}</Form.Label>

                                    }
                                    <MDTypography variant="button" id="error_cmb_product_material_style" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-5">
                                    <Form.Label className="erp-form-label">Bottom Value </Form.Label>
                                </div>
                                <div className="col-sm-7">
                                    <Form.Control type="text" id='txt_bottom_value' className="erp_input_field" value={bottomValue} onChange={(event) => setBottomValue(event.target.value)} disabled={warpingData.length > 1} />
                                    <MDTypography variant="button" id="error_txt_bottom_value" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-5">
                                    <Form.Label className="erp-form-label">Total Ends <span className="required">*</span> </Form.Label>
                                </div>
                                <div className="col-sm-7">
                                    <Form.Control type="text" id='txt_t_ends' className="erp_input_field" value={txt_t_ends} disabled />
                                    <MDTypography variant="button" id="error_txt_t_ends" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-5">
                                    <Form.Label className="erp-form-label">Creels <span className="required">*</span> </Form.Label>
                                </div>
                                <div className="col">
                                    <Form.Control type="text" id='txt_creels' ref={creelsRef} className="erp_input_field" value={txt_creels} onChange={(e) => { setCreeels(e.target.value); validateFields() }} onBlur={(e) => FnCreelsOnChange(e.target.value)} disabled={warpingData.length > 1} />
                                    <MDTypography variant="button" id="error_txt_creels" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>

                        </div>


                        <div className="col-sm-4 erp_form_col_div ">
                            <div className="row">
                                <div className="col-sm-5">
                                    <Form.Label className="erp-form-label">Set Length </Form.Label>
                                </div>
                                <div className="col-sm-7">
                                    <Form.Control type="text" id='txt_set_length' className="erp_input_field" value={txt_set_length} disabled />
                                    <MDTypography variant="button" id="error_txt_set_length" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-5">
                                    <Form.Label className="erp-form-label">Order Mtr.</Form.Label>
                                </div>
                                <div className="col-sm-7">
                                    <Form.Control type="text" id='order_quantity' className="erp_input_field" value={order_quantity} onChange={(e) => setOrderQuantity(e.target.value)} disabled={parseInt(cmb_product_type_id) !== 13} />
                                    <MDTypography variant="button" id="error_order_quantity" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        {/* Error message content */}
                                    </MDTypography>
                                </div>
                            </div>

                            {parseInt(cmb_product_type_id) !== 13 && (
                                <div>
                                    <div className="row">
                                        <div className="col-sm-5">
                                            <Form.Label className="erp-form-label">Prev. Schedule Mtr.</Form.Label>
                                        </div>
                                        <div className="col-sm-7">
                                            <Form.Control type="text" id='txt_prev_schedule_quantity' className="erp_input_field" value={txt_prev_schedule_quantity} readOnly />
                                            <MDTypography variant="button" id="error_txt_prev_schedule_quantity" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                {/* Error message content */}
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-5">
                                            <Form.Label className="erp-form-label">Schedule Mtr. </Form.Label>
                                        </div>
                                        <div className="col-sm-7">
                                            <Form.Control
                                                type="text"
                                                id='txt_schedule_quantity'
                                                className="erp_input_field"
                                                value={txt_schedule_quantity}
                                                onChange={(event) => {
                                                    const value = event.target.value;
                                                    const numericValue = parseFloat(value);

                                                    // Check if the value is a valid number, has up to 2 decimal places, and is not greater than order_quantity
                                                    if ((validateNumberDateInput.current.decimalNumber(value, 2) && numericValue <= order_quantity - txt_prev_schedule_quantity) || value === '') {
                                                        setScheduleQty(value);
                                                    }
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_txt_schedule_quantity" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                </div>
                            )}






                            {
                                keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                    ?
                                    <>
                                        <div className='row'>
                                            <div className='col-sm-5'>
                                                <Form.Label className="erp-form-label">Apprv. By <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col-sm-7 col-12">
                                                <select id="cmb_approved_by_id" className="form-select form-select-sm" value={cmb_approved_by_id} onChange={e => { setApprovedById(e.target.value); validateFields(); }} >
                                                    <option value="" disabled>Select</option>
                                                    {approveByOpts?.map(employee => (
                                                        <option value={employee.field_id} >{employee.field_name}</option>
                                                    ))}
                                                </select>
                                                {/* <Select ref={cmb_approved_by_id_ref}
                                                    options={approveByOpts}
                                                    isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                    inputId="cmb_approved_by_id"
                                                    value={approveByOpts.length > 1 ? approveByOpts.find(option => option.value === cmb_approved_by_id) : null}
                                                    onChange={(selectedOpt) => {
                                                        setApprovedById(selectedOpt.value);
                                                        cmb_approved_by_id_ref.current = selectedOpt;
                                                    }}
                                                    placeholder="Select"
                                                    className="form-search-custom"
                                                    classNamePrefix="custom-select"
                                                    disabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                    styles={{
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            fontSize: '12px'
                                                        }),
                                                        singleValue: (provided, state) => ({
                                                            ...provided,
                                                            fontSize: '12px'
                                                        }),
                                                        input: (provided, state) => ({
                                                            ...provided,
                                                            fontSize: '12px'
                                                        })
                                                    }}
                                                /> */}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-5'>
                                                <Form.Label className="erp-form-label">Apprv. Date <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col-sm-7 col-12">
                                                <Form.Control type="date" id='dt_approved_date' className="erp_input_field" value={dt_approved_date} onChange={(e) => { setApproveDate(e.target.value); validateFields(); }} />
                                                <MDTypography variant="button" id="error_dt_approved_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                        </div>
                                    </>
                                    : ''
                            }
                            <div className='row'>
                                <div className='col-sm-5 col-12'>
                                    <Form.Label className="erp-form-label">Warping Status</Form.Label>
                                </div>
                                <div className='col-sm-7 col-12'>
                                    <select id="cmb_warping_order_status" className="form-select form-select-sm" value={cmb_warping_order_status} onChange={(e) => { setWarpingOrderStatus(e.target.value); }} disabled >
                                        <option value="P" lbl="Pending">Pending</option>
                                        <option value="A" lbl="Approved">Approved</option>
                                        <option value="C" lbl="Completed">Completed</option>
                                        <option value="X" lbl="Canceled">Canceled</option>
                                    </select>
                                    <MDTypography variant="button" id="error_cmb_warping_order_status" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
                <hr />
                <div className="col-sm-12 px-lg-2 d-block">
                    <div className="col-lg-12 col-12 erp_form_col_div">
                        <div className="card">
                            <div className="card-header py-0 main_heding mb-0">
                                <label className="erp-form-label-md-lg">Product Based Properties</label>
                            </div>
                            {/* Card's Body Starts*/}
                            <div className="card-body p-0">
                                {memoizedColumns.length > 0 && memoizedData.length > 0 ? (
                                    <>
                                        {/* <dl className="d-flex">
                                            <div className="d-flex">
                                                <dt className="erp-form-label-md mr-1">GLM:</dt>
                                                <dd className="erp-form-label-md me-3">{txt_glm}</dd>
                                            </div>
                                            <div className="d-flex">
                                                <dt className="erp-form-label-md ms-3">GSM:</dt>
                                                <dd className="erp-form-label-md">{txt_gsm}</dd>
                                            </div>
                                        </dl> */}



                                        <Table className="erp_table" bordered striped hover responsive>
                                            <thead className="erp_table_head">
                                                <tr>
                                                    {memoizedColumns.map((column, index) => (
                                                        <th className="erp_table_th" key={index}>{column}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr >
                                                    {memoizedColumns.map((column, colIndex) => (
                                                        <td key={colIndex} className="erp_table_td">
                                                            {memoizedData.map((row, rowIndex) => (
                                                                <div key={rowIndex}>{row[column]}</div>
                                                            ))}
                                                        </td>
                                                    ))}
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </>
                                ) : (
                                    <p className='erp-form-label text-center'>No data available</p>
                                )}

                            </div>
                        </div>
                    </div>

                </div>

                <hr />

                <div className="col-sm-12 px-lg-2 d-block">
                    <div className="col-lg-12 col-12 erp_form_col_div">
                        <div className="card">
                            {/* Material Requisition Details Header */}
                            <div className="card-header py-0 main_heading mb-0">
                                <label className="erp-form-label-md-lg">Material Requisition Details</label>
                            </div>
                            {/* Card Body */}
                            <div className="card-body p-0">
                                <div className="row d-flex">
                                    {/* <div className="col-lg-5 col-12">
                                        <div className="col-sm-9">
                                            <SearchItemFilter
                                                getSearchData={FnGetMaterialDetails}
                                                formIdForValidate=""
                                                requiredCols={requiredCols}
                                                godownId="2"
                                                isMainGodown={true}
                                                product_type_id={12}
                                            />
                                        </div>
                                    </div> */}
                                    <div className="col-lg-5 col-md-3 col-12">
                                        <MDButton
                                            type="button"
                                            className="erp-gb-button ms-2"
                                            onClick={AddMaterial}
                                            variant="button"
                                            fontWeight="regular"
                                        >
                                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'update' ? "Material List" : "Add Materials"}
                                        </MDButton>
                                    </div>
                                </div>
                                <div className="mt-1">{renderWarpingDetails}</div>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className="col-sm-12 px-lg-2 d-block">
                        <div className="col-lg-12 col-12 erp_form_col_div">
                            <div className="card">
                                {/* Creel Details Header */}
                                <div className="card-header py-0 main_heading mb-0">
                                    <label className="erp-form-label-md-lg">Creel Details</label>
                                </div>
                                {/* Card Body */}
                                <div className="card-body p-0">{renderCreelDetails}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card-footer pb-4 text-center">
                <MDButton type="button" className="erp-gb-button ms-2" onClick={() => { navigate(`/Masters/MWarpingProductionPlan/FrmWarpingProductionPlanListing`) }} variant="button"
                    fontWeight="regular">Back</MDButton>
                <MDButton type="submit" id="save_btn" onClick={(e) => validatingWarpingCreelDetails()} className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
                    fontWeight="regular">{actionLabel}</MDButton>
                <MDButton className={`erp-gb-button ms-2 ${keyForViewUpdate === 'Add' || keyForViewUpdate === 'update' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" onClick={() => printWarpingProdOrderPlan()}>Print &nbsp;<FiPrinter className="erp-download-icon-btn" />
                </MDButton>
                {/* <MDButton type="submit" onClick={() => printWarpingProdOrderPlan()} className={`erp-gb-button ms-2 ${keyForViewUpdate === 'Add' ? 'd-none' : 'display'}`} variant="button" */}
                {/* fontWeight="regular">Print <FiPrinter className="erp-download-icon-btn ms-1" /></MDButton> */}
                {/* <MDButton type="submit" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'approve' ? '' : 'd-none'}`} variant="button" fontWeight="regular">
                    Rejected
                </MDButton> */}
            </div >

            <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
            <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

            <Modal size="lg" className='erp_document_Form m-0' show={showWRFilterForm} onHide={FnCloseStockModal} backdrop="static" keyboard={false} centered>
                <Modal.Header>
                    <Modal.Title className='erp_modal_title'>Raw Material Management</Modal.Title>
                    <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={FnCloseStockModal}></button></span>
                </Modal.Header>
                <Modal.Body>
                    <React.Fragment>
                        {isLoading && (
                            <div className="spinner-overlay">
                                <div className="spinner-container">
                                    <CircularProgress color="primary" />
                                    <span>Loading...</span>
                                </div>
                            </div>
                        )}
                        <div className='row d-flex justify-content-between'>
                            <div className='col-sm-4'>
                                <label className="erp-form-label-md-lg mt-0">Material Against Order</label>
                            </div>

                            <div className="col-sm-5">
                                <div className={` ${keyForViewUpdate === 'Add' ? 'display' : 'd-none'}`}>
                                    {/* <div className="col-md-8 col-12"> */}
                                    <SearchItemFilter getSearchData={FnGetStockDetails} formIdForValidate="" requiredCols={requiredCols} godownId={'2'} isMainGodown={true} product_type_id={12} />
                                    {/* </div> */}
                                </div>

                            </div>

                            {/* <MDButton type="button" className="erp-gb-button ms-2" onClick={FnGetStockDetails} variant="button" fontWeight="regular">
                                Refresh
                            </MDButton> */}
                        </div>


                        {/* {renderStockDetailsTable}
                        {
                            keyForViewUpdate !== 'view' || keyForViewUpdate !== 'approve' ?
                                <>
                                    <hr />
                                    <label className="erp-form-label-md-lg">Material Against Other</label>
                                    {renderOtherStockDetailsTable}
                                </> : null
                        } */}
                        {/* Loader */}

                        {!isLoading && renderStockDetailsTable}

                        {/* {keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' && (
                            <>
                                <hr />
                                <label className="erp-form-label-md-lg">Material Against Other</label>
                                {renderOtherStockDetailsTable}
                            </>
                        )} */}
                    </React.Fragment>
                </Modal.Body>
                <Modal.Footer className='erp-modal-footer'>
                    <Button variant="success" className={`erp-gb-button ${keyForViewUpdate === 'view' || materialRequisitionDetails.length === 0 ? 'd-none' : 'display'}`} onClick={FnConsumeStock}>
                        ok
                    </Button>

                </Modal.Footer>
            </Modal>

            {/* Modal For Cones Distribution */}
            {openModalForConesConsumption()}

            {/* {
                showModal ?
                    <Modal show={showModal} size="lg">
                        <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={handleCloses}></button></span>

                        <Modal.Body>
                            <div ref={printRef}> 
                                 <WarpingOrderPlan warpingProdPlanOrderContent={warpingContentPDF} /> 
                            </div>
                        </Modal.Body>

                        <Modal.Footer className="d-flex justify-content-center">
                            <Button className="erp-gb-button" variant="button" fontWeight="regular" onClick={handleCloses}>
                                Back
                            </Button>

                            <MDButton className='erp-gb-button erp_MLeft_btn' variant="button" fontWeight="regular" onClick={() => printInvoice()}>Print &nbsp;<FiPrinter className="erp-download-icon-btn" />
                            </MDButton>
                        </Modal.Footer>
                    </Modal> : null
            } */}
            {
                showModal ?
                    <Modal show={showModal} size="lg">
                        <span>
                            <button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloses}></button>
                        </span>

                        <Modal.Body>
                            <div ref={firstPageRef}>
                                {/* <DirectWarpingPlan warpingProdPlanOrderContent={warpingContentPDF} /> */}
                                <WarpingOrderPlan warpingProdPlanOrderContent={warpingContentPDF} />
                            </div>
                            <div style={{ display: 'none' }}>
                                <div ref={secondPageRef}>
                                    {/* <WarpingOrderPlan warpingProdPlanOrderContent={warpingContentPDF} /> */}
                                    <DirectWarpingPlan warpingProdPlanOrderContent={warpingContentPDF} />
                                </div>
                            </div>
                        </Modal.Body>

                        <Modal.Footer className="d-flex justify-content-center">
                            <Button className="erp-gb-button" variant="button" fontWeight="regular" onClick={handleCloses}>
                                Back
                            </Button>

                            <Button className='erp-gb-button erp_MLeft_btn' variant="button" fontWeight="regular" onClick={handlePrintFirstPage} disabled={isPrinting} >
                                Print &nbsp;<FiPrinter className="erp-download-icon-btn" />
                            </Button>
                        </Modal.Footer>
                    </Modal> : null
            }

            {/* Confirmation Modal */}
            <Modal show={showPrintModal} onHide={handleClosePrintModal} backdrop="static" keyboard={false} centered>
                <span>
                    <button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleClosePrintModal}></button>
                </span>
                <Modal.Body className='text-center'>
                    <span className='erp_modal_success_icon'><RxCrossCircled /></span>
                    <h6>Do you want to print the Direct Warping Plan on the back side?</h6>
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <Button variant="success" className='btn erp-gb-button' onClick={handleCancelPrintSecondPage}>
                        Cancel
                    </Button>&nbsp;
                    <Button variant="danger" className='btn erp-gb-button' onClick={handleConfirmPrintSecondPage}>
                        Print
                    </Button>
                </Modal.Footer>
            </Modal>



        </DashboardLayout >
    )
}

export default FrmWarpingProdPlanEntry