import React, { useEffect, useRef, useMemo } from 'react'
import { useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { renderToString } from "react-dom/server";
import $ from 'jquery';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { CircularProgress } from "@material-ui/core";
import ComboBox from 'Features/ComboBox';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { FiPrinter } from "react-icons/fi";
import { Table, Form } from "react-bootstrap";
import SizingProduction from "FrmGeneric/Invoice/SizingProduction";
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';

// Import for the searchable combo box.
import Select from "react-select";


function FrmSizingProductionReport() {

  const configConstants = ConfigConstants();
  const { COMPANY_ID } = configConstants;

  const { state } = useLocation()
  //changes by tushar
  const { idList, keyForViewUpdate } = state || {};

  //Loader Hook
  const [isLoading, setIsLoading] = useState(false);
  // const [keyForViewUpdate, setKeyForViewUpdate] = useState('');
  // const [idList, setidList] = useState({});

  //Hooks for Table
  const [setNoOptions, setSetNoOptions] = useState([]);
  const [cmb_set_no, setSetNo] = useState();
  const [name, setName] = useState();
  const [yarn_party, setYarnParty] = useState();
  const [yarn_count, setYarnCount] = useState();
  const [lot_no, setLotNo] = useState();
  const [total_meter, setTotalMeter] = useState();
  const [production_date, setProductiondate] = useState();
  const [total_ends, setTotalEnds] = useState();
  const [total_beams, setTotalBeams] = useState();

  const [department_id, setDepartmentId] = useState();
  const [sub_department_id, setSubDepartmentId] = useState();

  const [sizingMasterData, setSizingMasterData] = useState({});
  const [sizingProductionData, setSizingProductionData] = useState([]);
  const [sizingProductionTotals, setSizingProductionTotals] = useState({});
  const [warpingBottomDetailsData, setWarpingBottomDetailsData] = useState([]);
  const [TotalBottomDetailsObj, setBottomDetailsTotalsObj] = useState({});
  const [multipleCountMasterData, SetMultipleCountMasterData] = useState([]);

  const [txt_mill_name, setMillName] = useState();
  const [txt_cone_used, setConeUsed] = useState();
  const [txt_issue_to_warping, setIssueToWarping] = useState();
  const [txt_bottom_yarn, setBottomYarn] = useState();
  const [txt_used_warping, setUsedWarping] = useState();
  const [txt_avg_count, setAvgCount] = useState();
  const [txt_botom_percent, setBottomPercent] = useState();
  const [txt_break_per_million, setBreakPerMillion] = useState();

  //useRef Hooks
  const comboDataAPiCall = useRef();
  const navigate = useNavigate();
  const validateNumberDateInput = useRef();
  const cmb_set_no_ref = useRef(null);

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    debugger
    let FnGetCombodata = async () => {
      setIsLoading(true);
      await FillCombos();
      if (keyForViewUpdate === 'print') {
        FnGetSizingProdData()
      }
      setIsLoading(false);
    };
    FnGetCombodata();
  }, [])

  const FillCombos = async () => {
    try {
      resetGlobalQuery();
      globalQuery.columns.push('DISTINCT set_no');
      globalQuery.table = "xt_weaving_production_sizing_master";
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.orderBy = ['set_no DESC'];
      let SetNoAPICall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
      const setNoList = [
        { value: "", label: "Select" },
        ...SetNoAPICall.map((setNo) => ({
          value: setNo.set_no,
          label: setNo.set_no,

        })),
      ];
      setSetNoOptions(setNoList);


      resetGlobalQuery();
      globalQuery.columns.push('department_id', 'parent_department_id');
      globalQuery.table = "cm_department";
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "department_name", operator: "=", value: "Sizing" });
      let departmentAPICall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
      setDepartmentId(departmentAPICall[0]['parent_department_id']);
      setSubDepartmentId(departmentAPICall[0]['department_id']);

    } catch (error) {

    }
  }

  const FnGetSizingProdData = async () => {
    try {
      debugger

      setIsLoading(true);
      let setNO = cmb_set_no_ref.current.value;
      setSetNo(setNO)
      let departmentId = $("#department_id").val();
      let subdepartmentId = $("#sub_department_id").val();

      let getSizingProdDataApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionSizingDetails/FnGetSizingProductionRecords/${setNO}/${COMPANY_ID}/${departmentId}/${subdepartmentId}`)
      const responce = await getSizingProdDataApiCall.json();
      if (Object.keys(responce).length > 0) {

        let masterData = responce.MasterData;
        let SizingProdData = responce.SizingProdData;
        let WarpingBottomData = responce.WarpingBottomData;
        SetMultipleCountMasterData(masterData);
        const sizingproductionDetailsTotals = SizingProdData.reduce((totals, data) => {
          ['sizing_length', 'gross_weight', 'tare_weight', 'net_weight'].forEach((key) => {
            totals[key] = (totals[key] || 0) + (data[key] || 0);
          });
          return totals; // Return the accumulated object
        }, {});
        setSizingProductionTotals(sizingproductionDetailsTotals);

        const warpingBottomDetailsTotals = WarpingBottomData.reduce((totals, data) => {
          ['no_of_package', 'gross_weight', 'tare_weight', 'net_weight'].forEach((key) => {
            totals[key] = (totals[key] || 0) + (data[key] || 0);
          });
          return totals; // Return the accumulated object
        }, {});
        setBottomDetailsTotalsObj(warpingBottomDetailsTotals);


        setTotalMeter(masterData[0]['set_length']);
        // setConeUsed(masterData['total_cone_used']);
        // setUsedWarping(validateNumberDateInput.current?.decimalNumber(parseFloat(masterData['total_issued_weight']) - parseFloat(warpingBottomDetailsTotals.net_weight), 4));

        //Setting Master Data
        let length = parseInt(SizingProdData.length);
        setName(masterData[0]['customer_name']);
        setYarnParty(masterData[0]['remark']);
        setYarnCount(masterData['product_rm_name']);

        setLotNo(masterData['issue_batch_no']);
        setProductiondate(formatDate(responce.SizingProdData[length - 1]['sizing_production_date']));

        setTotalEnds(responce.SizingProdData[0]['total_ends']);
        setTotalBeams(length);

        setMillName(masterData[0]['remark']);
        // setIssueToWarping(masterData['total_issued_weight']);

        setBottomYarn(masterData[0]['actual_bottom_kg']);
        // setAvgCount(masterData['actual_count']);

        //Updating Tables Data
        setSizingMasterData(masterData);
        setSizingProductionData(responce.SizingProdData);
        setWarpingBottomDetailsData(responce.WarpingBottomData);

        // let actualBottomKg = parseFloat(masterData['actual_bottom_kg']);
        // let totalIssuedWeight = parseFloat(masterData['total_issued_weight']);
        // let bottomPercent;
        // if (isNaN(actualBottomKg) || isNaN(totalIssuedWeight) || totalIssuedWeight === 0) {
        //   bottomPercent = null;
        // } else {
        //   bottomPercent = validateNumberDateInput.current?.decimalNumber((actualBottomKg * 100) / totalIssuedWeight, 4);
        // }
        // setBottomPercent(bottomPercent)

        if (masterData.length > 0) {
          let totalConeUsed = masterData.reduce((sum, item) => sum + parseFloat(item.total_cone_used || 0), 0);
          let totalIssuedWeight = masterData.reduce((sum, item) => sum + parseFloat(item.total_issued_weight || 0), 0);
          // let totalActualCount = masterData.reduce((sum, item) => sum + parseFloat(item.actual_count || 0), 0);
          let totalActualBottomKg = masterData.reduce((sum, item) => sum + parseFloat(item.actual_bottom_kg || 0), 0);
        
          let usedWarping = validateNumberDateInput.current?.decimalNumber(
            totalIssuedWeight - parseFloat(warpingBottomDetailsTotals.net_weight || 0), 4
          );
        
          let bottomPercent = (isNaN(totalActualBottomKg) || isNaN(totalIssuedWeight) || totalIssuedWeight === 0) 
            ? null 
            : validateNumberDateInput.current?.decimalNumber((totalActualBottomKg * 100) / totalIssuedWeight, 4);
        
          // Setting the summed values
          setConeUsed(totalConeUsed);
          setUsedWarping(usedWarping);
          let AvgCount=((masterData[0]['set_length']/0.10607)*116*totalConeUsed)/(1852*usedWarping*1000);
          setAvgCount(validateNumberDateInput.current?.decimalNumber(AvgCount, 4));
          setIssueToWarping(validateNumberDateInput.current?.decimalNumber(totalIssuedWeight, 4));
          setBottomPercent(bottomPercent);
          setBreakPerMillion(validateNumberDateInput.current?.decimalNumber(masterData[0].avg_breaks_per_million, 4))
        }
        

      }

    } catch (error) {

    } finally {
      setIsLoading(false);
    }
  }

  const SizingrenderProductionDetails = useMemo(() => {
    return <Table id='sizingProductionTbl' className={`erp_table`} responsive bordered striped>
      <thead className="erp_table_head">
        <tr>
          <th className="erp_table_th" >SR NO.</th>
          <th className="erp_table_th" >Sizing Production Date</th>
          <th className="erp_table_th" >BEAM NO.</th>
          <th className="erp_table_th" >BEAM LENGTH</th>
          <th className="erp_table_th" >GROSS WEIGHT</th>
          <th className="erp_table_th" >TARE WEIGHT</th>
          <th className="erp_table_th" >NET WEIGHT</th>
          <th className="erp_table_th" >RS</th>
          {/* <th className="erp_table_th" >BEAM TYPE</th> */}
        </tr>
      </thead>
      <tbody>
        {sizingProductionData?.map((item, index) =>
          <tr>
            <td className='erp_table_td'>{index + 1}</td>
            <td className='erp_table_td'>{formatDate(item.sizing_production_date)}</td>
            <td className={`erp_table_td`}>{item.sizing_beam_name}</td>
            <td className='erp_table_td text-end'>{item.sizing_length}</td>
            <td className='erp_table_td text-end'>{item.gross_weight}</td>
            <td className='erp_table_td text-end'>{item.tare_weight}</td>
            <td className='erp_table_td text-end'>{item.net_weight}</td>
            <td className='erp_table_td text-end'>{item.sizing_rs}</td>
            {/* <td className='erp_table_td'>{item.sizing_beam_name}</td> */}
          </tr>
        )}
        <tr className="sticky-column">
          <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={3}>Totals</td>
          <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>
            {validateNumberDateInput.current?.decimalNumber(sizingProductionTotals.sizing_length, 4)}
          </td>
          <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>{validateNumberDateInput.current?.decimalNumber(sizingProductionTotals.gross_weight, 4)}</td>
          <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>{validateNumberDateInput.current?.decimalNumber(sizingProductionTotals.tare_weight, 4)}</td>
          <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>{validateNumberDateInput.current?.decimalNumber(sizingProductionTotals.net_weight, 4)}</td>
          <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={2}></td>

        </tr>
      </tbody>
    </Table>
  }, [sizingProductionData]);




  const renderBottomDetailsTable = useMemo(() => {
    return <Table className="erp_table" id="bottomDetailTblID" responsive bordered striped>
      <thead className="erp_table_head">
        <tr>
          {/* <td className={`erp_table_th`}>Sr. No.</td> */}
          <td className="erp_table_th" >Count</td>
          <td className="erp_table_th" >Package</td>
          <td className="erp_table_th" >GR. WT.</td>
          <td className="erp_table_th" >TR.WT</td>
          <td className="erp_table_th" >NT.WT</td>
        </tr>
      </thead>
      <tbody>

        {
          warpingBottomDetailsData?.map((details, indexOfItem) => (
            <tr rowindex={indexOfItem}>
              {/* <td className={`erp_table_th`}>{indexOfItem + 1}</td> */}
              <td key={indexOfItem} className="erp_table_td">{details.product_rm_name}</td>
              <td key={indexOfItem} className="erp_table_td text-end">{validateNumberDateInput.current?.decimalNumber(details.no_of_package, 4)}</td>
              <td key={indexOfItem} className="erp_table_td text-end">{validateNumberDateInput.current?.decimalNumber(details.gross_weight, 4)}</td>
              <td key={indexOfItem} className="erp_table_td text-end">{validateNumberDateInput.current?.decimalNumber(details.tare_weight, 4)}</td>
              <td key={indexOfItem} className="erp_table_td text-end">{validateNumberDateInput.current?.decimalNumber(details.net_weight, 4)}</td>
            </tr>
          ))}

        <tr className="sticky-column">
          <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>Total</td>
          <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>{validateNumberDateInput.current?.decimalNumber(TotalBottomDetailsObj.no_of_package, 4)}</td>
          <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>{validateNumberDateInput.current?.decimalNumber(TotalBottomDetailsObj.gross_weight, 4)}</td>
          <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>{validateNumberDateInput.current?.decimalNumber(TotalBottomDetailsObj.tare_weight, 4)}</td>
          <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>{validateNumberDateInput.current?.decimalNumber(TotalBottomDetailsObj.net_weight, 4)}</td>
        </tr>
      </tbody>
    </Table>
  }, [warpingBottomDetailsData]);

  const renderMultipleCountDetailsTbl = useMemo(() => {
    return <Table className="erp_table" id="bottomDetailTblID" responsive bordered striped>
      <thead className="erp_table_head">
        <tr>
          {/* <td className={`erp_table_th`}>Sr. No.</td> */}
          <td className="erp_table_th" >Count</td>
          <td className="erp_table_th" >Yarn Party</td>
          <td className="erp_table_th" >Lot No</td>
          <td className="erp_table_th" >Issued cones</td>
          <td className="erp_table_th" > Issued Weight</td>
        </tr>
      </thead>
      <tbody>

        {
          multipleCountMasterData?.map((details, indexOfItem) => (
            <tr rowindex={indexOfItem}>
              {/* <td className={`erp_table_th`}>{indexOfItem + 1}</td> */}
              <td key={indexOfItem} className="erp_table_td">{details.product_rm_name}</td>
              <td key={indexOfItem} className="erp_table_td">{details.remark}</td>
              <td key={indexOfItem} className="erp_table_td">{details.issue_batch_no }</td>
              {/* <td key={indexOfItem} className="erp_table_td text-end">{details.remark}</td> */}
              <td key={indexOfItem} className="erp_table_td text-end">{details.total_cone_used}</td>
              <td key={indexOfItem} className="erp_table_td text-end">{details.total_issued_weight}</td>
            </tr>
          ))}

      </tbody>
    </Table>
  }, [multipleCountMasterData]);


  //JsonObject for Print Functionality
  // Invoice Printing 
  const sizingProductionObject = {
    company: {
      company: '',
      company_branch: '',
      company_cell_no: '',
      company_emailId: '',
      company_website: '',
      company_gst_no: '',
      company_branch_no: '',
      company_address: '',
      company_pincode: '',
    },


    sizingMasterData: {},
    sizingProdDetailsData: [],
    warpingProductionBottomDetails: [],
    sizingProductionTotalObj: {},
    warpingBottomTotalsObj: {},

  };

  const printInvoice = (openPrintModal) => {

    sizingProductionObject.sizingProdDetailsData = sizingProductionData;
    sizingProductionObject.warpingProductionBottomDetails = warpingBottomDetailsData;
    sizingProductionObject.sizingProductionTotalObj = sizingProductionTotals;
    sizingProductionObject.warpingBottomTotalsObj = TotalBottomDetailsObj;
    
    sizingProductionObject.multipleCountMasterData = multipleCountMasterData;

    let set_no = $("#cmb_set_no option:selected").text();




    // Company Deatils
    sizingProductionObject.company.company = sizingProductionData[0].company_name
    sizingProductionObject.company.company_branch = sizingProductionData[0].company_branch_name
    sizingProductionObject.company.company_cell_no = sizingProductionData[0].company_cell_no
    sizingProductionObject.company.company_emailId = sizingProductionData[0].company_EmailId
    sizingProductionObject.company.company_website = sizingProductionData[0].company_website
    sizingProductionObject.company.company_gst_no = sizingProductionData[0].company_gst_no
    sizingProductionObject.company.company_pan_no = sizingProductionData[0].company_pan_no
    sizingProductionObject.company.company_address = sizingProductionData[0].company_address
    sizingProductionObject.company.company_pincode = sizingProductionData[0].company_pincode

    //Master Data
    sizingProductionObject.sizingMasterData.name = name;
    sizingProductionObject.sizingMasterData.yarn_party = yarn_party;
    sizingProductionObject.sizingMasterData.yarn_count = yarn_count;
    sizingProductionObject.sizingMasterData.total_meter = total_meter;
    sizingProductionObject.sizingMasterData.lot_no = lot_no;
    sizingProductionObject.sizingMasterData.production_date = formatDate(production_date);
    sizingProductionObject.sizingMasterData.total_ends = total_ends;
    sizingProductionObject.sizingMasterData.set_no = cmb_set_no_ref.current.value;
    sizingProductionObject.sizingMasterData.total_beams = total_beams;

    sizingProductionObject.sizingMasterData.mill_name = txt_mill_name;
    sizingProductionObject.sizingMasterData.cone_used = txt_cone_used;
    sizingProductionObject.sizingMasterData.issue_to_warping = txt_issue_to_warping;
    sizingProductionObject.sizingMasterData.bottom_yarn = txt_bottom_yarn;
    sizingProductionObject.sizingMasterData.used_warping = txt_used_warping;
    sizingProductionObject.sizingMasterData.avg_count = txt_avg_count;
    sizingProductionObject.sizingMasterData.botom_percent = txt_botom_percent;
    sizingProductionObject.sizingMasterData.txt_break_per_million = txt_break_per_million;


    debugger

    if (openPrintModal) {
      navigate('/Invoice', { state: { invoiceData: sizingProductionObject, navigationLink: '/Transactions/TSizingProduction/FrmTSizingProductionReport', invoiceType: 'SP', title: 'Sized Yarn Reconcilation Report', keyForViewUpdate: 'print', idList: { 'setNo': set_no } } });

    } else {
      return renderToString(<SizingProduction invoiceData={sizingProductionObject} />);
    }
  }




  return (
    <>
      <ComboBox ref={comboDataAPiCall} />
      <ValidateNumberDateInput ref={validateNumberDateInput} />

      <DashboardLayout>


        {isLoading ?
          <div className="spinner-overlay"  >
            <div className="spinner-container">
              <CircularProgress color="primary" />
              <span>Loading...</span>
            </div>
          </div> :
          ''}

        <div className='card p-1'>
          <div className='card-header text-center py-0'>
            <label className='erp-form-label-lg text-center'>Sizing Reconciliation Report</label>
          </div>

          <div className='row p-1'>
            {/* 1 st Column */}
            <div className='col-sm-4 erp_form_col_div'>

              <div className='row'>
                <div className='col-sm-5'>
                  <Form.Label className="erp-form-label"><b>Set No</b><span className="required">*</span></Form.Label>
                </div>
                <div className="col-sm-6">

                  <Select
                    ref={cmb_set_no_ref}
                    options={setNoOptions}
                    isDisabled={["view", "update", "approve"].includes(
                      keyForViewUpdate
                    )}
                    inputId="cmb_set_no"
                    value={
                      setNoOptions.length > 0
                        ? setNoOptions.find(
                          (option) => option.value === cmb_set_no
                        )
                        : null
                    }
                    onChange={(selectedOpt) => {
                      cmb_set_no_ref.current = selectedOpt;
                      FnGetSizingProdData();
                    }}
                    placeholder="Search Set No..."
                    className="form-search-custom"
                    classNamePrefix="custom-select"
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        fontSize: "12px",
                      }),
                      singleValue: (provided, state) => ({
                        ...provided,
                        fontSize: "12px",
                      }),
                      input: (provided, state) => ({
                        ...provided,
                        fontSize: "12px",
                      }),
                    }}
                  />
                  {/* <select id="cmb_set_no" className="form-select form-select-sm" value={cmb_set_no} onChange={(e) => { FnGetSizingProdData(e.target.value); }}>
                    <option >Select</option>
                    {setNoOptions.length !== 0 ? (
                      <>
                        {setNoOptions?.map(production => (
                          <option value={production.set_no} customer_order_no={production.customer_order_no}>{production.set_no}</option>
                        ))}
                      </>
                    ) : null}
                  </select> */}
                  <MDTypography variant="button" id="error_cmb_set_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div>

            </div>
          </div>

          <div className='col-sm-4 erp_form_col_div'>
            <input type='text' className='d-none' id="department_id" value={department_id} />
            <input type='text' className='d-none' id="sub_department_id" value={sub_department_id} />
          </div>

          <hr />

          <div className='row'>
            <div className='col-sm-4 erp_form_col_div'>
              <div className='row'>
                <div className="col-sm-5">
                  <Form.Label className="erp-form-label"><b>Name :</b></Form.Label>
                </div>
                <div className="col-sm-6">
                  <Form.Label className="erp-form-label">{name}</Form.Label>
                </div>
              </div>
              <div className='row'>
                <div className="col-sm-5">
                  <Form.Label className="erp-form-label"><b>Date :</b></Form.Label>
                </div>
                <div className="col-sm-6">
                  <Form.Label className="erp-form-label">{production_date}</Form.Label>
                </div>
              </div>

              {/* <div className='row'>
                <div className="col-sm-5">
                  <Form.Label className="erp-form-label"><b>Yarn Party :</b> </Form.Label>
                </div>
                <div className="col-sm-6">
                  <Form.Label className="erp-form-label">{yarn_party}</Form.Label>
                </div>
              </div>

              <div className='row'>
                <div className="col-sm-5">
                  <Form.Label className="erp-form-label"><b>Count :</b></Form.Label>
                </div>
                <div className="col-sm-6">
                  <Form.Label className="erp-form-label">{yarn_count}</Form.Label>
                </div>
              </div> */}
            </div>

            <div className='col-sm-4 erp_form_col_div'>
              <div className='row'>
                <div className="col-sm-4">
                  <Form.Label className="erp-form-label"><b>Total Meter :</b></Form.Label>
                </div>
                <div className="col-sm-7">
                  <Form.Label className="erp-form-label">{total_meter}</Form.Label>

                </div>
              </div>
              <div className='row'>
                <div className="col-sm-4">
                  <Form.Label className="erp-form-label"><b>Total Beams :</b></Form.Label>
                </div>
                <div className="col-sm-7">
                  <Form.Label className="erp-form-label">{total_beams}</Form.Label>
                </div>
              </div>
              {/* <div className='row'>
                <div className="col-sm-4">
                  <Form.Label className="erp-form-label"><b>Lot No. :</b></Form.Label>
                </div>
                <div className="col-sm-7">
                  <Form.Label className="erp-form-label">{lot_no}</Form.Label>
                </div>
              </div> */}

           

            </div>

            <div className='col-sm-4 erp_form_col_div'>

              <div className='row'>
                <div className="col-sm-5">
                  <Form.Label className="erp-form-label"><b>Total Ends :</b></Form.Label>
                </div>
                <div className="col-sm-6">
                  <Form.Label className="erp-form-label">{total_ends}</Form.Label>
                </div>
              </div>


             

            </div>
          </div>

          <hr />
          <div className='row'><label className="erp-form-label-md-lg text-center">Sizing Set Details</label>
            <div className='row'>
              {SizingrenderProductionDetails}
            </div>
          </div>

          <div className='row pt-1'>
            <div className='col-sm-3 erp_form_col_div'>
              {renderBottomDetailsTable}
            </div>
            <div className='col-sm-5 erp_form_col_div'>
              {renderMultipleCountDetailsTbl}
            </div>

            <div className='col-sm-4 erp_form_col_div  ps-2'>
              <div className='row'>
                <div className="col-sm-6">
                  <Form.Label className="erp-form-label">Mill Name :{txt_mill_name}</Form.Label>
                </div>
                {/* <div className="col-sm-4">
                  <Form.Label className="erp-form-label">{txt_mill_name}</Form.Label>
                </div> */}
              {/* </div> */}

              {/* <div className='row'> */}
                <div className="col-sm-6">
                  <Form.Label className="erp-form-label">Total Cone Used. :{txt_cone_used}</Form.Label>
                </div>
                {/* <div className="col-sm-4">
                  <Form.Label className="erp-form-label">{txt_cone_used}</Form.Label>
                </div> */}
              </div>

              <div className='row'>
                <div className="col-sm-6">
                  <Form.Label className="erp-form-label">Issue To Warping :{txt_issue_to_warping}</Form.Label>
                </div>
                {/* <div className="col-sm-4">
                  <Form.Label className="erp-form-label">{txt_issue_to_warping} </Form.Label>
                </div>
              </div> */}

            {/* </div> */}

            {/* <div className='col-sm-1 erp_form_col_div'></div> */}

            {/* <div className='col-sm-1 erp_form_col_div'></div> */}
            {/* <div className='col-sm-3 erp_form_col_div'> */}

              {/* <div className='row'> */}
                <div className="col-sm-6">
                  <Form.Label className="erp-form-label">Bottom Yarn(Kg) :{txt_bottom_yarn}</Form.Label>
                </div>
                {/* <div className="col-sm-4">
                  <Form.Label className="erp-form-label">{txt_bottom_yarn}</Form.Label>
                </div> */}
              </div>

              <div className='row'>
                <div className="col-sm-6">
                  <Form.Label className="erp-form-label">Used Warping :{txt_used_warping}</Form.Label>
                </div>
                {/* <div className="col-sm-4">
                  <Form.Label className="erp-form-label">{txt_used_warping}</Form.Label>
                </div>
              </div> */}

              {/* <div className='row'> */}
                <div className="col-sm-6">
                  <Form.Label className="erp-form-label">Avg. Count :{txt_avg_count}</Form.Label>
                </div>
                {/* <div className="col-sm-4">
                  <Form.Label className="erp-form-label">{txt_avg_count}</Form.Label>
                </div> */}
              </div>

              <div className='row'>
                <div className="col-sm-6">
                  <Form.Label className="erp-form-label">Bottom(%) :{txt_botom_percent}</Form.Label>
                </div>
                {/* <div className="col-sm-4">
                  <Form.Label className="erp-form-label">{txt_botom_percent}</Form.Label>
                </div> */}
                <div className="col-sm-6">
                  <Form.Label className="erp-form-label">BREAKS/MILLION	 :{txt_break_per_million}</Form.Label>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="text-center mb-5">
          <MDButton className={`erp-gb-button ms-1`} id="printBtnId" variant="button" fontWeight="regular" onClick={() => printInvoice(true)}>Print <FiPrinter className="erp-download-icon-btn" /></MDButton> &nbsp;
          <MDButton type="button" className="erp-gb-button ms-1" onClick={() => { const path = '/Masters'; navigate(path); }} variant="button" fontWeight="regular">Back</MDButton>
        </div >
      </DashboardLayout>
    </>
  )
}

export default FrmSizingProductionReport;
