import React from 'react';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'
import ConfigConstants from "assets/Constants/config-constant";
import { isArray } from 'jquery';


const SizingProduction = React.forwardRef(({ invoiceContent }, ref) => {
    debugger

    const itemData = invoiceContent.company;
    const sizingProdDetailsData = invoiceContent.sizingProdDetailsData;
    const warpingBottomData = invoiceContent.warpingProductionBottomDetails;
    const sizingProductionTotals = invoiceContent.sizingProductionTotalObj;
    const warpingBottomTotalsObj = invoiceContent.warpingBottomTotalsObj;
    const multipleCountMasterData = invoiceContent.multipleCountMasterData;

    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split("-");
        return `${day}-${month}-${year}`;
    }


    const invoiceHeader = (
        <>
            <div className='bordered border-bottom-0 px-0'>
                {/* Company Data */}
                <div className='row mb-0 p-2'>
                    <div className="col-sm-3 text-center text-sm-start">
                        <img src={DakshabhiLogo} alt="master card" width="170" height="auto" mt={1} />
                    </div>
                    <div className="col-sm-9 transformed">
                        <div className='erp-invoice-print-label-lg text-center'>
                            {itemData.company}
                        </div>
                        <div className="row">
                            <div className="text-center">
                                <div className='erp-invoice-print-label text-center'>
                                    <div className='erp-invoice-print-label-md-lg'>
                                        <span className='erp-invoice-print-label-md-lg'>{itemData.company_address} ({itemData.company_branch}) {itemData.state_name}-{itemData.company_pincode} </span>
                                    </div>

                                    <div className='erp-invoice-print-label-md-lg'>
                                        <span className='erp-invoice-print-label-md-lg'>WebSite: <a href={itemData.company_website}>{itemData.company_website}</a> || Email: {itemData.company_emailId} ||  Cell:{itemData.company_cell_no} </span>
                                    </div>

                                    <div className='erp-invoice-print-label-md-lg'>
                                        <span className='erp-invoice-print-label-md-lg'>GSTN No: {itemData.company_gst_no} || PAN No:{itemData.company_pan_no} </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col'>
                            <dl className="erp-invoice-print-label-md-lg text-center" style={{ fontSize: "20px" }}>
                                <dt>Sized Yarn Reconcilation Report</dt>
                            </dl>
                        </div>

                    </div>
                </div>
            </div>

            <div className='bordered border-bottom-0 px-0'>
                <div className="row p-0 m-0">
                    {/* Customer Details */}

                    <div className="col-sm-5">
                        <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Set No. :</span>{invoiceContent.sizingMasterData.set_no}</dt>
                        {/* <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Lot No. : </span>{invoiceContent.sizingMasterData.lot_no}</dt> */}
                        <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Total Beam :</span>{invoiceContent.sizingMasterData.total_beams}</dt>
                    </div>

                    {/* <div className="col-sm-1"></div> */}

                    <dl className="col-sm-4">
                        <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Name : </span>{invoiceContent.sizingMasterData.name}</dt>
                        {/* <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Yarn Party : </span>{invoiceContent.sizingMasterData.yarn_party}</dt> */}
                        <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Total Meter : </span>{invoiceContent.sizingMasterData.total_meter}</dt>
                    </dl>

                    {/* <div className="col-sm-1"></div> */}

                    <dl className="col-sm-3">
                        <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Date :</span>{formatDate(invoiceContent.sizingMasterData.production_date)}</dt>
                        {/* <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Count : </span>{invoiceContent.sizingMasterData.yarn_count}</dt> */}
                        <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Total Ends :</span>{invoiceContent.sizingMasterData.total_ends}</dt>
                    </dl>

                    <label className='pt-1 pb-1' style={{ fontSize: "15px", fontWeight: 'bold' }}>Sizing Set Details</label>
                </div>
            </div>
        </>
    )

    return (
        <>
            {/* <div className="container"> */}

            <div className="row">
                <div className="col-12">
                    <div className="container-invoice">
                        <div id="content">
                            <div className="invoice p-0">

                                {/* <!--BILL HEADER--> */}
                                <div className="row" style={{ padding: '0px 15px 0px' }}>
                                    {invoiceHeader}
                                </div>
                                {/* <!--PARCEL DETAILS--> */}
                                <div className="row" style={{ padding: '0px 15px 15px' }}>
                                    <div className="col-12" style={{ border: "1px solid black" }}>
                                        <div className="row">
                                            <div className="col-12 px-0" >
                                                <div className="row p-0">
                                                    <div className="table-responsive" style={{ overflowX: "hidden", margin: "-1px 0px -0.5px 0px" }}>
                                                        <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='invoiceTable' >
                                                            {sizingProdDetailsData.length !== 0 && (
                                                                <thead>
                                                                    <tr>
                                                                        <th className="erp_invoice_table_th text-center col-1">Sr No.</th>
                                                                        <th className="erp_invoice_table_th text-center col-2" >Sizing Prod. Date</th>
                                                                        <th className="erp_invoice_table_th text-center col-2" >Beam No.</th>
                                                                        <th className="erp_invoice_table_th text-center col-1" >Beam Length</th>
                                                                        <th className="erp_invoice_table_th text-center col-1" >Gross Wt.</th>
                                                                        <th className="erp_invoice_table_th text-center col-1" >Tare Wt.</th>
                                                                        <th className="erp_invoice_table_th text-center col-1" >Net Wt.</th>
                                                                        <th className="erp_invoice_table_th text-center col-1" >RS</th>
                                                                        {/* <th className="erp_invoice_table_th text-center col-2">Beam Type</th> */}
                                                                    </tr>
                                                                </thead>
                                                            )}
                                                            <tbody id="detailstableId">
                                                                {sizingProdDetailsData.map((item, index) => (
                                                                    <tr key={index} className={`tblBodyTrHt-${index}`}>
                                                                        <td className="erp_invoice_table_td text-center">{index + 1}</td>
                                                                        <td className="erp_invoice_table_td">{formatDate(item.sizing_production_date)}</td>
                                                                        <td className="erp_invoice_table_td">{item.sizing_beam_name}</td>
                                                                        <td className="erp_invoice_table_td text-end">{item.sizing_length}</td>
                                                                        <td className="erp_invoice_table_td text-end">{item.gross_weight}</td>
                                                                        <td className="erp_invoice_table_td text-end">{item.tare_weight}</td>
                                                                        <td className="erp_invoice_table_td text-end">{item.net_weight}</td>
                                                                        <td className="erp_invoice_table_td">{item.sizing_rs}</td>
                                                                        {/* <td className="erp_invoice_table_td text-end">{item.sizing_beam_name}</td> */}
                                                                    </tr>
                                                                ))}
                                                                <tr>
                                                                    <td className="erp_invoice_table_td text-center" style={{ fontWeight: 'bold' }} colSpan={3}>Totals</td>
                                                                    <td className="erp_invoice_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>{sizingProductionTotals.sizing_length}</td>
                                                                    <td className="erp_invoice_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>{sizingProductionTotals.gross_weight}</td>
                                                                    <td className="erp_invoice_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>{sizingProductionTotals.tare_weight}</td>
                                                                    <td className="erp_invoice_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>{sizingProductionTotals.net_weight}</td>
                                                                    <td className="erp_invoice_table_td" colSpan={2}></td>
                                                                </tr>

                                                            </tbody>

                                                        </table>
                                                    </div>
                                                    <div className='row border-dark' style={{ marginTop: "10px" }}>
                                                        <div className="col-6" style={{ margin: "-0.5px -0.5px -1px -1px" }}>
                                                            <div className="table-responsive">
                                                                <table className="table table-bordered border border-dark m-0 border-end-0" id='invoiceTable' >
                                                                    {warpingBottomData.length !== 0 && (
                                                                        <thead>
                                                                            <tr>
                                                                                <th className="erp_invoice_table_th text-center col-1">Sr No.</th>
                                                                                <th className="erp_invoice_table_th text-center col-1">Count</th>
                                                                                <th className="erp_invoice_table_th text-center col-1" >No. Of Package</th>
                                                                                <th className="erp_invoice_table_th text-center col-1" >Gross Wt.</th>
                                                                                <th className="erp_invoice_table_th text-center col-1" >Tare Wt.</th>
                                                                                <th className="erp_invoice_table_th text-center col-1" >Net Wt.</th>
                                                                            </tr>
                                                                        </thead>
                                                                    )}
                                                                    <tbody id="bottomdetailstableId">
                                                                        {warpingBottomData.map((item, index) => (
                                                                            <tr key={index} className={`tblBodyTrHt-${index}`}>
                                                                                <td className="erp_invoice_table_td">{index + 1}</td>
                                                                                <td className="erp_invoice_table_td">{item.product_rm_name}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.no_of_package}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.gross_weight}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.tare_weight}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.net_weight}</td>
                                                                            </tr>
                                                                        ))}
                                                                        <tr>
                                                                            <td className="erp_invoice_table_td text-center" style={{ fontWeight: 'bold' }} ></td>
                                                                            <td className="erp_invoice_table_td text-center" style={{ fontWeight: 'bold' }} >Totals</td>
                                                                            <td className="erp_invoice_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>{warpingBottomTotalsObj.no_of_package}</td>
                                                                            <td className="erp_invoice_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>{warpingBottomTotalsObj.gross_weight}</td>
                                                                            <td className="erp_invoice_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>{warpingBottomTotalsObj.tare_weight}</td>
                                                                            <td className="erp_invoice_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>{warpingBottomTotalsObj.net_weight}</td>
                                                                        </tr>
                                                                    </tbody>

                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className="col-6" style={{ margin: "-0.5px -0.5px -1px -1px" }}>
                                                            <div className="table-responsive">
                                                                <table className="table table-bordered border border-dark m-0 border-end-0" id='invoiceTable' >
                                                                    {multipleCountMasterData.length !== 0 && (
                                                                        <thead>
                                                                            <tr>
                                                                                <th className="erp_invoice_table_th text-center col-1">Count</th>
                                                                                <th className="erp_invoice_table_th text-center col-1" >Yarn Party</th>
                                                                                <th className="erp_invoice_table_th text-center col-1" >Lot No</th>
                                                                                <th className="erp_invoice_table_th text-center col-1" >Issued cones</th>
                                                                                <th className="erp_invoice_table_th text-center col-1" >Issued Weight</th>
                                                                            </tr>
                                                                        </thead>
                                                                    )}
                                                                    <tbody id="bottomdetailstableId">
                                                                        {multipleCountMasterData.map((item, index) => (
                                                                            <tr key={index} className={`tblBodyTrHt-${index}`}>
                                                                                <td className="erp_invoice_table_td">{item.product_rm_name}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.remark}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.issue_batch_no}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.total_cone_used}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.total_issued_weight}</td>
                                                                            </tr>
                                                                        ))}

                                                                    </tbody>

                                                                </table>
                                                            </div>
                                                            <div className='row border-dark '>

                                                                <div className="col-6 ps-4">
                                                                    <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Mill Name :</span>{invoiceContent.sizingMasterData.mill_name}</dt>
                                                                    <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Total Cone Used :</span>{invoiceContent.sizingMasterData.cone_used}</dt>
                                                                    <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Issued To Warping :</span>{invoiceContent.sizingMasterData.issue_to_warping}</dt>
                                                                    <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Bottom(%) :</span>{invoiceContent.sizingMasterData.botom_percent}</dt>

                                                                </div>

                                                                {/* <div className="col-1"></div> */}

                                                                <div className="col-6">
                                                                    <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Bottom Yarn(Kg) :</span>{invoiceContent.sizingMasterData.bottom_yarn}</dt>
                                                                    <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Used Warping :</span>{invoiceContent.sizingMasterData.used_warping}</dt>
                                                                    <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Avg Count :</span>{invoiceContent.sizingMasterData.avg_count}</dt>
                                                                    <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>BREAKS/MILLION :</span>{invoiceContent.sizingMasterData.txt_break_per_million}</dt>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className='row border-dark '> */}

                                                        {/* <div className="col-3 ps-4 pt-4">
                                                            <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Mill Name :</span>{invoiceContent.sizingMasterData.mill_name}</dt>
                                                            <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Total Cone Used :</span>{invoiceContent.sizingMasterData.cone_used}</dt>
                                                            <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Issued To Warping :</span>{invoiceContent.sizingMasterData.issue_to_warping}</dt>
                                                        </div>


                                                        <div className="col-3 pt-4">
                                                            <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Bottom Yarn(Kg) :</span>{invoiceContent.sizingMasterData.bottom_yarn}</dt>
                                                            <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Used Warping :</span>{invoiceContent.sizingMasterData.used_warping}</dt>
                                                            <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Avg Count :</span>{invoiceContent.sizingMasterData.avg_count}</dt>
                                                            <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Bottom(%) :</span>{invoiceContent.sizingMasterData.botom_percent}</dt>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div >


        </>
    );
})

export default SizingProduction;
